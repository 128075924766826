
export default class WebsocketSquamaRequest {
    type: string;
    token: any;
    filter: any;
    number: number;
    liste: any;
    from: string;
    answer_method: any;
    constructor(
        type = "companies_desc",
        token = "",
        filter = "",
        number = 0,
        liste = [],
        from = "token",
        answer_method = ""
    ) {
        this.type = type;
        this.token = token;
        this.filter = filter;
        this.number = number;
        this.liste = liste;
        this.from = from;
        this.answer_method = answer_method;
    }
}

export interface WebsocketEmitRequest {
    channel: string;
    request: WebsocketSquamaRequest;
}
