import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import { MapContainer, LayersControl, TileLayer, Marker, Popup } from 'react-leaflet';
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/styles.css";
import L, { LatLngBounds, LatLngExpression, Map as MapProp } from "leaflet";
//import "leaflet/dist/leaflet.css";
import Station from "../models/station"
import { is_station_flhauteur, is_station_flow, is_station_pap, is_station_papang, station_get_gps_lat_long, station_type_nicely_displayed } from '../helpers/station-helper';
import MyMarkerThermeauGreenLogo from '../img/marker-thermeau-green.png';
import MyMarkerThermeauOrangeLogo from '../img/marker-thermeau-orange.png';
import MyMarkerThermeauRedLogo from '../img/marker-thermeau-red.png';
import MyMarkerThermeauGreyLogo from '../img/marker-thermeau-grey.png';
import MyMarkerFlowGreenLogo from '../img/marker-flow-green.png';
import MyMarkerFlowOrangeLogo from '../img/marker-flow-orange.png';
import MyMarkerFlowRedLogo from '../img/marker-flow-red.png';
import MyMarkerFlowGreyLogo from '../img/marker-flow-grey.png';
import MyMarkerPapeyeGreenLogo from '../img/marker-papeye-green.png';
import MyMarkerPapeyeOrangeLogo from '../img/marker-papeye-orange.png';
import MyMarkerPapeyeRedLogo from '../img/marker-papeye-red.png';
import MyMarkerPapeyeGreyLogo from '../img/marker-papeye-grey.png';
import MyMarkerVigeelGreenLogo from '../img/marker-vigeel-green.png';
import MyMarkerVigeelOrangeLogo from '../img/marker-vigeel-orange.png';
import MyMarkerVigeelRedLogo from '../img/marker-vigeel-red.png';
import MyMarkerVigeelGreyLogo from '../img/marker-vigeel-grey.png';
import MyMarkerFlhauteurGreenLogo from '../img/marker-flhauteur-green.png';
import MyMarkerFlhauteurOrangeLogo from '../img/marker-flhauteur-orange.png';
import MyMarkerFlhauteurRedLogo from '../img/marker-flhauteur-red.png';
import MyMarkerFlhauteurGreyLogo from '../img/marker-flhauteur-grey.png';
import MyMarkerShadowLogo from '../img/marker-shadow.png';
import { Link } from 'react-router-dom';
import { format_date } from '../helpers/format-date';
//import MarkerClusterGroup from 'react-leaflet-markercluster';

type Props = {
  stations?: Station[],
  zoom?: number,
  auto_zoom?: boolean,
  allow_zoom?: boolean,
  display_marker_color?: boolean,
  allow_fullscreen?: boolean,
  setSelectedStation?: Dispatch<SetStateAction<Station | null>>,
  showPopup?: boolean
};
const MyMap: FunctionComponent<Props> = ({ zoom = 6, auto_zoom = false, allow_fullscreen = true, display_marker_color = true, stations, allow_zoom = true, showPopup, setSelectedStation }) => {
  const displayName = "MyMap:";
  const enableDebug = false;
  const enableMoreDebug = false;
  const the_default_center: LatLngExpression = [47, 2.5];
  const [mapRef, setMapRef] = useState<MapProp | null>(null);

  useEffect(() => {
    if (!mapRef) {
      (enableDebug) && console.log(displayName, "No mapRef");
      return;
    }
    if (auto_zoom) {
      (enableDebug) && console.log(displayName, "auto zoom mode");
      let bounds = new LatLngBounds([]);
      stations?.forEach(one_station => {
        if (one_station.transmitters) {
          const returned = station_get_gps_lat_long(one_station);
          if (returned) {
            (enableMoreDebug) && console.log(displayName, "will add in bounds :", returned);
            bounds.extend(returned);
          }
        }
      });
      (enableMoreDebug) && console.log(displayName, "Bounds:", mapRef?.getBounds());
      if (bounds.isValid()) {
        mapRef?.flyToBounds(bounds, { duration: 2 });
      }
    } else {
      let default_center: LatLngExpression | null = the_default_center;
      (enableDebug) && console.log(displayName, "Fixed zoom mode to ", zoom);
      if ((zoom) && (mapRef) && (stations) && stations.length === 1) {
        //Browse all and find the first gps one
        stations.some(one_station => {
          if (one_station.transmitters) {
            default_center = station_get_gps_lat_long(one_station);
            if (default_center) {
              return true;
            }
            return false;
          }
          return false;
        });
        if (default_center) {
          mapRef.setView(default_center, zoom);
        }
      }
    }
    if (!allow_zoom) {
      mapRef?.setMaxZoom(zoom);
    }
  }, [mapRef, auto_zoom, zoom, stations, allow_zoom]);

  function descCustomIcon(icon_URL: string) {
    return new L.Icon({
      //iconUrl: baseIconUrl+color+'.png',
      iconUrl: icon_URL,
      shadowUrl: MyMarkerShadowLogo,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });
  }

  function get_icon_station(station: Station) {
    const station_status = station.general_status;
    if (is_station_pap(station)) {
      if (!display_marker_color) {
        return descCustomIcon(MyMarkerPapeyeGreyLogo);
      } else if (station.least_days_since_last_trame_received >= 1) {
        return descCustomIcon(MyMarkerPapeyeGreyLogo);
      } else if (station_status === "red") {
        return descCustomIcon(MyMarkerPapeyeRedLogo);
      } else if (station_status === "orange") {
        return descCustomIcon(MyMarkerPapeyeOrangeLogo);
      } else if (station_status === "green") {
        return descCustomIcon(MyMarkerPapeyeGreenLogo);
      }
    } else if (is_station_papang(station)) {
      if (!display_marker_color) {
        return descCustomIcon(MyMarkerVigeelGreyLogo);
      } else if (station.least_days_since_last_trame_received > 1) {
        return descCustomIcon(MyMarkerVigeelGreyLogo);
      } else if (station_status === "red") {
        return descCustomIcon(MyMarkerVigeelRedLogo);
      } else if (station_status === "orange") {
        return descCustomIcon(MyMarkerVigeelOrangeLogo);
      } else if (station_status === "green") {
        return descCustomIcon(MyMarkerVigeelGreenLogo);
      }
    } else if (is_station_flow(station)) {
      if (!display_marker_color) {
        return descCustomIcon(MyMarkerFlowGreyLogo);
      } else if (station.least_days_since_last_trame_received > 1) {
        return descCustomIcon(MyMarkerFlowGreyLogo);
      } else if (station_status === "red") {
        return descCustomIcon(MyMarkerFlowRedLogo);
      } else if (station_status === "orange") {
        return descCustomIcon(MyMarkerFlowOrangeLogo);
      } else if (station_status === "green") {
        return descCustomIcon(MyMarkerFlowGreenLogo);
      }
    } else if (is_station_flhauteur(station)) {
      if (!display_marker_color) {
        return descCustomIcon(MyMarkerFlhauteurGreyLogo);
      } else if (station.least_days_since_last_trame_received > 1) {
        return descCustomIcon(MyMarkerFlhauteurGreyLogo);
      } else if (station_status === "red") {
        return descCustomIcon(MyMarkerFlhauteurRedLogo);
      } else if (station_status === "orange") {
        return descCustomIcon(MyMarkerFlhauteurOrangeLogo);
      } else if (station_status === "green") {
        return descCustomIcon(MyMarkerFlhauteurGreenLogo);
      }
    } else {
      if (!display_marker_color) {
        return descCustomIcon(MyMarkerThermeauGreyLogo);
      } else if (station.least_days_since_last_trame_received > 1) {
        return descCustomIcon(MyMarkerThermeauGreyLogo);
      } else if (station_status === "red") {
        return descCustomIcon(MyMarkerThermeauRedLogo);
      } else if (station_status === "orange") {
        return descCustomIcon(MyMarkerThermeauOrangeLogo);
      } else if (station_status === "green") {
        return descCustomIcon(MyMarkerThermeauGreenLogo);
      }
    }
    return descCustomIcon(MyMarkerThermeauGreenLogo);
  }

  function display_one_station(station: Station) {

    const coordinates: LatLngExpression | null = station_get_gps_lat_long(station);
    if (coordinates === null) {
      return;
    }
    return (
      <Marker
        key={"station-" + station.id}
        position={L.latLng(coordinates)}
        icon={get_icon_station(station)}
        eventHandlers={{
          click: (e) => { (enableDebug) && console.log("Marker clicked:", e); (setSelectedStation) && setSelectedStation(station); }
        }}
      >
        {(showPopup !== undefined) && (!showPopup) ? (undefined) : (
          <Popup key={"station-" + station.id + "-popup"}>
            <Link to={'/stations/' + station.id + '/dash'}>Station:{station.name}</Link><br />
            <Link to={'/companies/' + station.company_id}>Client:{station.company_name}</Link><br />
            Type:{station_type_nicely_displayed(station)}<br />
            Installation:{format_date(station.installation_date)}<br />
          </Popup>
        )}
      </Marker>
    )
  }

  return (
    <MapContainer className="map-container" center={the_default_center} zoom={zoom} ref={setMapRef}>
      <LayersControl position="topright">
        <LayersControl.BaseLayer key="standard-noirblanc" name="Noir&Blanc" checked>
          <TileLayer
            attribution='<a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer key="standard-layer" name="Standard">
          <TileLayer
            attribution='<a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        {/*
        <LayersControl.BaseLayer key="standard-geoportail" name="GeoPortail">
          <TileLayer
            attribution='<a href="http://osm.org/copyright">Geoportail France</a> contributors'
            url="https://wxs.ign.fr/decouverte/geoportail/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&TILEMATRIXSET=PM&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&STYLE=normal&FORMAT=image/png&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}"
            minZoom={2}
            maxZoom={19}
          />
        </LayersControl.BaseLayer>
        */}
        <LayersControl.BaseLayer key="standard-riverboat" name="RiverBoat">
          {/* eslint-disable-next-line */}
          <TileLayer
            attribution='<a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="http://{s}.tile.openstreetmap.fr/openriverboatmap/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer key="standard-opentopo" name="OpenTopo">
          {/* eslint-disable-next-line */}
          <TileLayer
            attribution='<a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="http://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        {/* <LayersControl.Overlay checked name="Stations"> */}
        {/* <MarkerClusterGroup> */}
        {stations?.map(one => display_one_station(one))}
        {/* </MarkerClusterGroup> */}
        {/* </LayersControl.Overlay> */}
        {/* <LayersControl.Overlay checked name="Ajout Manuel">
          <Marker position={theCenter} icon={greenIcon}>
            <Popup>
              A pretty Manual add. <br /> Easily customizable.
            </Popup>
          </Marker>
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name="Ajout inspecteur">
          <Marker position={theCenter} icon={greenIcon}>
            <Popup>
              A pretty Auto add. <br /> Easily customizable.
            </Popup>
          </Marker>
        </LayersControl.Overlay> */}
      </LayersControl>
      {(allow_fullscreen) && (<FullscreenControl />)}
      {/*(allow_fullscreen)&&(<FullscreenControl forceSeparateButton={true} position="bottomleft"/>)*/}
    </MapContainer>
  );
}

export default MyMap;
