import { Item, updateItemFromNameWithValueAndSetter } from "./items-helper";
import { debugFunctionIdValue } from "./tools";

export const handleDateOnChange = (displayName:string, enableDebug: boolean, items: Array<Item>, e: React.ChangeEvent<HTMLInputElement>, theSetter: any) => {
  (enableDebug)&&debugFunctionIdValue(displayName, "handleDateOnChange", e.target.id, e.target.value);
  updateItemFromNameWithValueAndSetter(items, e.target.id, e.target.value, theSetter);
}

export const handleInputChange = (displayName:string, enableDebug: boolean, items: Array<Item>, e: React.ChangeEvent<HTMLInputElement>, theSetter: any, theValueType: string = ""): void => {
  (enableDebug)&&debugFunctionIdValue(displayName, "handleInputChange", e.target.id, e.target.value);
  updateItemFromNameWithValueAndSetter(items, e.target.id, e.target.value, theSetter, theValueType);
}

export const handleGenericSelectChange = (displayName:string, enableDebug: boolean, items: Array<Item>, e: React.ChangeEvent<HTMLSelectElement>, theSetter: any): void => {
  (enableDebug)&&debugFunctionIdValue(displayName, "handleGenericSelectChange", e.target.id, e.target.value);
  updateItemFromNameWithValueAndSetter(items, e.target.id, e.target.value, theSetter);
}

export function handleResetValue(displayName:string, enableDebug: boolean, items: Array<Item>, id: string, theSetter: any) {
  (enableDebug)&&debugFunctionIdValue(displayName, "handleResetValue", id, "");
  updateItemFromNameWithValueAndSetter(items, id, "", theSetter);
}

export const handleGenericChange = (e: React.ChangeEvent<any>, items: Array<Item>, theSetter: any, enableDebug: boolean|undefined=undefined,displayName:string|undefined=undefined): void => {
  (enableDebug)&&debugFunctionIdValue((displayName)?(displayName):"", "handleGenericChange", e.target.id, e.target.value);
  updateItemFromNameWithValueAndSetter(items, e.target.id, e.target.value, theSetter);
}

export const handleGenericCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>,items: Array<Item>, setItems:any, enableDebug:boolean|undefined=undefined, displayName:string|undefined=undefined): void => {
  (enableDebug) && debugFunctionIdValue((displayName)?(displayName):"", "handleGenericCheckboxChange", e.target.id, e.target.value);
  updateItemFromNameWithValueAndSetter(items, e.target.id, (e.target.checked) ? 1 : 0, setItems);
}
