import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { add_quarter_to_date, format_date } from "../../helpers/format-date";
import Transmitter from "../../models/transmitter";
import {
  fw_nicely_displayed,
  transmitter_get_battery_strategy_explanation,
  transmitter_get_number_of_quarter_before_next_trame,
  transmitter_hw_str,
} from "../../helpers/station-helper";
import TextTable from "../TextTable";
import { Link } from "react-router-dom";
import { io } from "socket.io-client";
import { authHeader } from "../../helpers/auth-headers";
import SiteService, { getSocketIOParams } from "../../services/site-service";

const ModalShowTransmetteursTextTable: FunctionComponent = () => {
  const displayName = "ModalShowTransmetteursTextTable:";
  const enableDebug = false;
  const [transmitters, setTransmitters] = useState<Transmitter[] | null>([]);
  const [showModal, setshowModal] = useState(false);
  const [retrieveDatas, setRetrieveDatas] = useState(true);

  useEffect(() => {
    if (enableDebug) {
      console.log(displayName, "useEffect");
      console.log(displayName, "useEffect showModal:", showModal);
      console.log(displayName, "useEffect retrieveDatas:", retrieveDatas);
    }
    if (!showModal) {
      return;
    }
    const socket = io(SiteService.getWebsocketUrl(), getSocketIOParams());
    socket.on("logged_out", (logged_out_desc_str: string) => {
      enableDebug &&
        console.log(displayName, "logged_out:", logged_out_desc_str);
    });
    socket.on("answer", (answer: any) => {
      enableDebug && console.log(displayName, "Answer:", answer);
      if (answer.type === "transmitter_id") {
        const the_request: any = {
          type: "transmitters_desc",
          number: answer.number,
          liste: answer.liste,
          from: "transmitter_id",
          filter: [
            "station_name",
            "station_id",
            "company_name",
            "company_id",
            "charging_stats_month",
            "charging_stats_6months",
            "last_day_sent",
            "last_day_samples",
          ],
          token: authHeader(),
        };
        socket.emit("request", the_request);
      }
    });
    socket.on("transmitters_desc", (the_transmitters_desc_str: string) => {
      const the_transmitters = JSON.parse(the_transmitters_desc_str);
      enableDebug &&
        console.log(displayName, "Transmitters:", the_transmitters);
      setTransmitters(the_transmitters);
    });

    if (showModal || retrieveDatas) {
      console.log(displayName, "Get transmitters data");
      setTransmitters([]);
      const the_request: any = {
        type: "transmitter_id",
        number: 0,
        liste: [0],
        filter: ["order_by_last_trame_received"],
        from: "station_id",
        token: authHeader(),
      };
      socket.emit("request", the_request);
      setRetrieveDatas(false);
    }

    return function cleanup() {
      enableDebug && console.log("displayName, useEffect.cleanup");
      socket.disconnect();
    };
  }, [showModal, retrieveDatas]);

  const handleCloseModal = () => setshowModal(false);
  const handleShowModal = () => {
    setshowModal(true);
  };
  const handleReloadModal = () => {
    setRetrieveDatas(true);
  };
  function mac_cutter(the_input: string) {
    //return the_input;
    if (the_input && the_input.includes("copy")) {
      return the_input.split("copy")[0] + "copy";
    }
    return the_input;
  }

  function the_modal() {
    const TextValueColumns = [
      {
        header: "Transmetteur",
        footer: (props: any) => props.column.id,
        columns: [
          {
            header: "ID",
            accessorKey: "id",
            maxSize: 50,
            /*className: 'td-test',*/
            /*
          footer: (info:any) => {
            // Only calculate total visits if rows change
            const total = useMemo(
              () =>
                info.rows.reduce((sum:any, row:any) => 1 + sum, 0),
              [info.rows]
            );

            return <span>Total: {total} transmitters</span>;
          },
          */
          },
          {
            header: "MAC",
            accessorKey: "mac",
            accessorFn: (row: any) => mac_cutter(row.mac),
          },
          {
            header: "Serial",
            accessorKey: "serial",
            maxSize: 50,
          },
          {
            header: "FW",
            accessorFn: (row: any) => fw_nicely_displayed(row.fw_version),
            maxSize: 50,
          },
          {
            header: "HW",
            accessorKey: "hw_version",
            accessorFn: (row: any) => transmitter_hw_str(row),
          },
          {
            header: "Last Trame",
            accessorKey: "last_trame_received",
            minSize: 145,
            cell: (info: any) => format_date(info.getValue()),
            /*sortType: 'datetime',*/ //automatically detected
            /*
          cell: (props: { value: Date }) => {
            //const tidyDate = format_date(props.getValue());
            //return <span>{tidyDate}</span>;
            return <>{format_date(props.value)}</>;
          },
          */
            //sortingFn: compareLastReceivedTrame
          },
          {
            header: "Next Trame",
            accessorKey: "next_trame_received",
            minSize: 145,
            accessorFn: (row: any) =>
              new Date(
                add_quarter_to_date(
                  row.last_trame_received,
                  transmitter_get_number_of_quarter_before_next_trame(row)
                )
              ),
            cell: (info: any) => (
              <div
                className={
                  new Date(info.getValue()) > new Date()
                    ? "modal-show-transmitter-table-element-ok"
                    : "modal-show-transmitter-table-element-ko"
                }
              >
                {format_date(info.getValue())}
              </div>
            ),

            /*sortType: 'datetime',*/ //automatically detected
            /*
          cell: (infos:any) => {
            //format_date(add_quarter_to_date(infos.row.original.last_trame_received,infos.row.original.current_send_each_n_quarter))
            return <span className={(new Date(format_date(add_quarter_to_date(infos.row.original.last_trame_received,infos.row.original.current_send_each_n_quarter)))>new Date())?"modal-show-transmitter-table-element-ok":"modal-show-transmitter-table-element-ko"}>{format_date(add_quarter_to_date(infos.row.original.last_trame_received,infos.row.original.current_send_each_n_quarter))}</span>;
          },
          */
            //sortingFn: compareNextTrame
          },
          {
            header: "Type",
            accessorKey: "type",
            maxSize: 50,
          },
          {
            header: "Con. Size",
            accessorKey: "connector",
            maxSize: 50,
          },
          {
            header: "KO %",
            maxSize: 50,
            accessorFn: (row: any) =>
              row.monitored && row.message_data_total && row.message_data_ko
                ? Math.ceil(
                    (row.message_data_ko * 100.0) / row.message_data_total
                  )
                : "",
          },
          {
            header: "Configuration",
            maxSize: 50,
            columns: [
              {
                header: "Samples a day",
                accessorFn: (row: any) =>
                  row.new_sample_each_n_quarter5
                    ? 96 / row.new_sample_each_n_quarter
                    : 96 / row.current_sample_each_n_quarter,
                maxSize: 50,
              },
              {
                header: "Send a day",
                accessorFn: (row: any) =>
                  row.new_send_each_n_quarter
                    ? 96 / row.new_send_each_n_quarter
                    : 96 / row.current_send_each_n_quarter,
                maxSize: 50,
              },
              {
                header: "Resend",
                maxSize: 50,
                accessorKey: "resend_parameters",
              },
            ],
          },
          {
            header: "Computed last 24h",
            maxSize: 50,
            columns: [
              {
                header: "Sampled",
                maxSize: 50,
                accessorKey: "last_day_samples",
              },
              {
                header: "Sent",
                maxSize: 50,
                accessorKey: "last_day_sent",
              },
            ],
          },
          {
            header: "Batterie",
            maxSize: 50,
            columns: [
              {
                header: "%",
                accessorKey: "current_battery_value",
                maxSize: 50,
                cell: (info: any) => (
                  <div
                    className={`${
                      info.getValue() > 75
                        ? "status-ok"
                        : info.getValue() > 60
                        ? " status-yl"
                        : info.getValue() > 30
                        ? "status-wn"
                        : "status-ko"
                    }`}
                  >
                    {" "}
                    {info.getValue() + "%"}
                  </div>
                ),
              },
              {
                header: "Strategy",
                accessorKey: "battery_strategy",
                maxSize: 150,
                accessorFn: (row: any) =>
                  transmitter_get_battery_strategy_explanation(row),
              },
            ],
          },
          {
            header: "Charging [9H;17H]",
            maxSize: 50,
            columns: [
              {
                header: "1month",
                maxSize: 50,
                accessorKey: "battery_charging_avg_stat_month",
                cell: (info: any) => (
                  <div
                    className={`${
                      info.getValue() > 4000
                        ? "status-ok"
                        : info.getValue() > 3500
                        ? " status-yl"
                        : info.getValue() > 3000
                        ? "status-wn"
                        : "status-ko"
                    }`}
                  >
                    {" "}
                    {info.getValue()}
                  </div>
                ),
              },
              /*
          {
            header: "Discharging % month",
            maxSize:50,
            accessorKey: "battery_discharging_stat_month",
          },
          {
            header: "Stat % month",
            maxSize:50,
            accessorKey: "battery_stat",
            accessorFn: (row:any) =>(row.battery_charging_avg_stat_month + row.battery_discharging_stat_month),
          },*/
              {
                header: "6months",
                maxSize: 50,
                accessorKey: "battery_charging_avg_stat_6months",
                cell: (info: any) => (
                  <div
                    className={`${
                      info.getValue() > 4000
                        ? "status-ok"
                        : info.getValue() > 3500
                        ? " status-yl"
                        : info.getValue() > 3000
                        ? "status-wn"
                        : "status-ko"
                    }`}
                  >
                    {" "}
                    {info.getValue()}
                  </div>
                ),
              },
              /*
          {
            header: "Discharging % 6months",
            maxSize:50,
            accessorKey: "battery_discharging_stat_6months",
          },
          {
            header: "Stat % 6months",
            maxSize:50,
            accessorKey: "battery_stat_6months",
            accessorFn: (row:any) =>(row.battery_charging_avg_stat_6months + row.battery_discharging_stat_6months),
          },*/
            ],
          },
          {
            header: "Station",
            footer: (props: any) => props.column.id,
            columns: [
              {
                header: "ID",
                accessorKey: "station_id",
                maxSize: 30,
                cell: (info: any) => (
                  <Link to={"/stations/" + info.getValue() + "/setup"}>
                    {info.getValue()}
                  </Link>
                ),
              },
              {
                header: "Name",
                accessorKey: "station_name",
              },
            ],
          },
          {
            header: "Company",
            footer: (props: any) => props.column.id,
            columns: [
              {
                header: "ID",
                accessorKey: "company_id",
                maxSize: 30,
                cell: (info: any) => (
                  <Link to={"/companies/" + info.getValue()}>
                    {info.getValue()}
                  </Link>
                ),
              },
              {
                header: "Name",
                accessorKey: "company_name",
              },
            ],
          },
        ],
      },
    ];
    return (
      <div className="modal-main">
        <div className="modal-container">
          {/* Start Header */}
          <div className="modal-header">Arbre des transmetteurs</div>
          {/* End Header */}
          {/* Start Body */}
          <div className="modal-body m-w-500">
            <div className="row">
              <div className="col align-self-center">
                Now(UTC):{format_date(new Date())}
              </div>
              <div className="col align-self-center">
                Now:{format_date(new Date())}
              </div>
            </div>
            {transmitters?.length && (
              <TextTable
                orderBy="next_trame_received"
                orderByDesc={true}
                allowPagination={true}
                numberOfElementDisplayed={30}
                searchedText={["id", "mac"]}
                allValues={transmitters}
                valuesColumns={TextValueColumns}
              />
            )}
          </div>
          {/* End Body */}
          {/* Start Footer */}
          <div className="modal-footer">
            <button
              type="button"
              className="squama-btn-navbar squama-btn-gestion-modal"
              onClick={handleReloadModal}
            >
              Reload
            </button>
            <button
              type="button"
              className="squama-btn-navbar squama-btn-gestion-modal"
              onClick={handleCloseModal}
            >
              Fermer
            </button>
          </div>
          {/* End Footer */}
        </div>
      </div>
    );
  }
  return (
    <Fragment>
      {showModal && the_modal()}
      <button
        type="button"
        className="squama-btn-navbar squama-btn-gestion"
        onClick={handleShowModal}
      >
        Visualiser les transmetteurs
      </button>
    </Fragment>
  );
};

export default ModalShowTransmetteursTextTable;
