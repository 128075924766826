//If not using nginx proxy :
//const DEV_BASENAME = "http://192.168.1.101"
//const DEV_WEBSOCKET_SERVER_URL = DEV_BASENAME + ":3002/"
//const DEV_API_SERVER_URL = DEV_BASENAME +":3002/api/"
//const DEV_AUTHENTICATION_SERVER_URL = DEV_BASENAME + ":3001/auth/"
//const DEV_DEVICEAPI_SERVER_URL = DEV_BASENAME + ":3003/deviceapi/"

//If using nginx proxy :
const DEV_WEBSOCKET_SERVER_URL = "/"
const DEV_API_SERVER_URL = "/api/"
const DEV_AUTHENTICATION_SERVER_URL = "/auth/"
const DEV_DEVICEAPI_SERVER_URL = "/deviceapi/"

const PRODUCTION_WEBSOCKET_SERVER_URL = "/"
const PRODUCTION_API_SERVER_URL = "/api/"
const PRODUCTION_AUTHENTICATION_SERVER_URL = "/auth/"
const PRODUCTION_DEVICEAPI_SERVER_URL = "/deviceapi/"

export default class SiteService {
  static getWebsocketUrl(): string {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      return DEV_WEBSOCKET_SERVER_URL;
    } else {
      return PRODUCTION_WEBSOCKET_SERVER_URL;
    }
  }
  static getApiUrl(): string {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      return DEV_API_SERVER_URL;
    } else {
      return PRODUCTION_API_SERVER_URL;
    }
  }
  static getAuthenticationUrl(): string {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      return DEV_AUTHENTICATION_SERVER_URL;
    } else {
      return PRODUCTION_AUTHENTICATION_SERVER_URL;
    }
  }
  static getDeviceapiUrl(): string {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      return DEV_DEVICEAPI_SERVER_URL;
    } else {
      return PRODUCTION_DEVICEAPI_SERVER_URL;
    }
  }
}

export function getSocketIOParams(): any {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return {
      transports: ["websocket"],
      cors: {
        origin: "http://localhost:3000/",
      },
      extraHeaders: {
        "my-custom-header": "1234" // WARN: this will be ignored in a browser
      }
    }
  }
  return {
    transports: ["websocket"],
    cors: {
      origin: "http://localhost:3000/",
    },
    extraHeaders: {
      "my-custom-header": "1234" // WARN: this will be ignored in a browser
    }
  }
}
