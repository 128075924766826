import { Fragment, FunctionComponent, useState } from "react";
import { useForm } from "react-hook-form";

import Station from "../../models/station";
import Company from "../../models/company";

import StationService from "../../services/station-service";
import CompanyService from "../../services/company-service";
import Transmitter from "../../models/transmitter";

type ModalItems = {
  companyIdSelected: number;
  stationIdSelected: number;
  transmitterToAdd: string;
  transmitterToDelete: string;
};

const ModalCreateModifyTransmitteur: FunctionComponent = () => {
  const displayName = "ModalCreateModifyTransmitteur:";
  const enableDebug = false;
  const enableMoreDebug = false;

  const { register, handleSubmit } = useForm<ModalItems>();

  const [stations, setStations] = useState<Station[] | null>(null);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [associatedTransmitters, setAssociatedTransmitters] = useState<
    Transmitter[]
  >([]);
  const [notAssociatedTransmitters, setNotAssociatedTransmitters] = useState<
    Transmitter[]
  >([]);
  const [
    isNoNotAssociatedTransmitterSelected,
    setIsNoNotAssociatedTransmitterSelected,
  ] = useState<boolean>(true);
  const [
    isNoAssociatedTransmitterSelected,
    setIsNoAssociatedTransmitterSelected,
  ] = useState<boolean>(true);

  const [showModal, setshowModal] = useState(false);
  const handleCloseModal = () => {
    setshowModal(false);
    initDatas();
  };

  const handleShowModal = () => {
    CompanyService.getCompanies().then((companies) => {
      setCompanies(companies);
    });
    initDatas();
    setshowModal(true);
  };

  function initDatas() {
    setIsNoNotAssociatedTransmitterSelected(true);
    setIsNoAssociatedTransmitterSelected(true);
    setCompanies([]);
    setStations([]);
    setNotAssociatedTransmitters([]);
    setAssociatedTransmitters([]);
  }

  function the_modal() {
    const onHookFormSubmit = (data: ModalItems) => {
      if (enableMoreDebug) console.log(displayName, "data", data);
      if (
        Number(data.companyIdSelected) === 0 ||
        Number(data.stationIdSelected) === 0 ||
        (Number(data.transmitterToAdd) === 0 &&
          Number(data.transmitterToDelete) === 0)
      ) {
        if (enableMoreDebug) console.log(displayName, "Nothing todo");
        handleCloseModal();
        return;
      }
      if (data.transmitterToAdd && Number(data.transmitterToAdd) > 0) {
        if (enableDebug)
          console.log(
            displayName,
            "Will Add transmitterId:" +
              data.transmitterToAdd +
              " to stationId:" +
              data.stationIdSelected
          );
        StationService.associateTransmitterToStation(
          Number(data.transmitterToAdd),
          Number(data.stationIdSelected)
        );
      } else if (
        data.transmitterToDelete &&
        Number(data.transmitterToDelete) > 0
      ) {
        if (enableDebug)
          console.log(
            displayName,
            "Will Delete transmitterId:" +
              data.transmitterToDelete +
              " to stationId:" +
              data.stationIdSelected
          );
        StationService.deassociateTransmitterToStation(
          Number(data.transmitterToDelete),
          Number(data.stationIdSelected)
        );
      }
      handleCloseModal();
    };

    const handleSelectChangeCompany = (event: React.ChangeEvent<any>) => {
      //if(enableDebug) console.log(displayName,event.target.value)
      if (Number(event.target.value) > 0) {
        setStations(null);
        setAssociatedTransmitters([]);
        setNotAssociatedTransmitters([]);
        setIsNoNotAssociatedTransmitterSelected(true);
        setIsNoAssociatedTransmitterSelected(true);
        //CompanyService.getCompany(event.target.value).then(company => {
        //  if(company){
        //    if(enableDebug) console.log(displayName,company)
        //    if(enableDebug) console.log(displayName,"assigned name as :"+company.name)
        //  }
        //})
        StationService.getStationsForACompany(
          event.target.value,
          '["no_companies_desc", "no_stations_desc", "transmitters", "no_transmitters_desc"]'
        ).then((stations) => {
          if (enableDebug) console.log(displayName, "stations:", stations);
          setStations(stations);
        });
      } else {
        initDatas();
      }
    };

    const handleSelectChangeStation = (event: React.ChangeEvent<any>) => {
      setAssociatedTransmitters([]);
      setNotAssociatedTransmitters([]);
      setIsNoAssociatedTransmitterSelected(true);
      setIsNoNotAssociatedTransmitterSelected(true);
      if (Number(event.target.value) > 0) {
        StationService.getAssociatedTransmittersForAStation(
          Number(event.target.value)
        ).then((transmitters) => {
          setAssociatedTransmitters(transmitters);
        });
        StationService.getNotAssociatedTransmittersForAStation(
          Number(event.target.value)
        ).then((transmitters) => {
          setNotAssociatedTransmitters(transmitters);
        });
      }
    };
    const handleSelectChangeNotAssociateTransmitter = (
      event: React.ChangeEvent<any>
    ) => {
      //Add a not associated transmitter to the station
      if (Number(event.target.value) > 0) {
        setIsNoNotAssociatedTransmitterSelected(false);
      } else {
        setIsNoNotAssociatedTransmitterSelected(true);
      }
    };
    const handleSelectChangeAssociateTransmitter = (
      event: React.ChangeEvent<any>
    ) => {
      //Delete an associated transmitter to the station
      if (Number(event.target.value) > 0) {
        setIsNoAssociatedTransmitterSelected(false);
      } else {
        setIsNoAssociatedTransmitterSelected(true);
      }
    };

    return (
      <div className="modal-main">
        <div className="modal-container">
          {/* Start Header */}
          <div className="modal-header">
            Associer/Désassocier un transmetteur
          </div>
          {/* End Header */}
          {/* Start Body */}
          <div className="modal-body m-w-500">
            <div className="flex-nowrap-justify-around">
              <select
                className="squama-select squama-item-navbar"
                {...register("companyIdSelected", { required: true })}
                onChange={handleSelectChangeCompany}
              >
                <option value="0">SélectionnerUneSociété</option>
                {companies &&
                  companies.map((company) => {
                    return (
                      <option value={company.id} key={company.id}>
                        {company.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            {stations?.length ? (
              <Fragment>
                <hr className="modal-separator" />
                <div className="flex-nowrap-justify-around">
                  <select
                    className="squama-select squama-item-navbar"
                    {...register("stationIdSelected", { required: true })}
                    onChange={handleSelectChangeStation}
                  >
                    <option value="0">Sélectionner Une Station</option>
                    {stations &&
                      stations.map((station) => {
                        return (
                          <option value={station.id} key={station.id}>
                            {station.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </Fragment>
            ) : undefined}
            {notAssociatedTransmitters?.length ||
            associatedTransmitters?.length ? (
              <hr className="modal-separator" />
            ) : undefined}
            {notAssociatedTransmitters?.length ? (
              <div className="row m-0">
                <div className="col align-self-center">Associer:</div>
                {
                  <div className="col align-self-center">
                    <select
                      className="squama-select squama-item-navbar"
                      disabled={!isNoAssociatedTransmitterSelected}
                      {...register("transmitterToAdd", { required: true })}
                      onChange={handleSelectChangeNotAssociateTransmitter}
                    >
                      <option value="0">AssocierUnTransmitteur</option>
                      {notAssociatedTransmitters?.map((t) => {
                        return (
                          <option value={t.id} key={t.id}>
                            {t.mac}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                }
              </div>
            ) : undefined}
            {associatedTransmitters?.length ? (
              <div className="row m-0">
                <div className="col align-self-center">Désassocier:</div>
                {
                  <div className="col align-self-center">
                    <select
                      className="squama-select squama-item-navbar"
                      disabled={!isNoNotAssociatedTransmitterSelected}
                      {...register("transmitterToDelete", { required: true })}
                      onChange={handleSelectChangeAssociateTransmitter}
                    >
                      <option value="0">DésassocierUnTransmitteur</option>
                      {associatedTransmitters?.map((t) => {
                        return (
                          <option value={t.id} key={t.id}>
                            {t.mac}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                }
              </div>
            ) : undefined}
          </div>
          {/* End Body */}
          {/* Start Footer */}
          <div className="modal-footer">
            <button
              type="button"
              className="squama-btn-navbar squama-btn-gestion-modal"
              onClick={handleCloseModal}
            >
              Annuler
            </button>
            <button
              type="submit"
              className="squama-btn-navbar squama-btn-gestion squama-btn-gestion-modal"
              onClick={handleSubmit(onHookFormSubmit)}
            >
              Enregistrer
            </button>
          </div>
          {/* End Footer */}
        </div>
      </div>
    );
  }
  return (
    <Fragment>
      {showModal && the_modal()}
      <button
        type="button"
        className="squama-btn-navbar squama-btn-gestion"
        onClick={handleShowModal}
      >
        Édition Transmetteur
      </button>
    </Fragment>
  );
};

export default ModalCreateModifyTransmitteur;
