import { FunctionComponent} from 'react';
import SquamaNavbar from '../components/squama-navbar';
import ParametersInfosCompte from '../components/parameters-infos-compte'
import AuthenticationService from '../services/authentication-service';
import ReactTooltip from "react-tooltip";

const SquamaParameters: FunctionComponent = () => {
  const isDemo = AuthenticationService.getUserIsDemo()
  const current_user_is_restricted = AuthenticationService.getUserIsRestrictedInAuthenticationServer();
  return (
    <div className="in-page-flex">
      {/* barre*/}
      <SquamaNavbar thepath={window.location.pathname} />
      <div><hr></hr></div>
      <div className="main-center-div flex-station-cards the-background">
        <ParametersInfosCompte key="infoscompte" cannotModifyPassword={current_user_is_restricted || isDemo}/>
      </div>
      <ReactTooltip type="light" />
    </div>
  );
}
  
export default SquamaParameters;