import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { convert_date_to_UTC, format_date } from "../../helpers/format-date";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faCircleUp,
  faCircleDown,
} from "@fortawesome/free-solid-svg-icons";
import Company from "../../models/company";
import Station from "../../models/station";
import { authHeader } from "../../helpers/auth-headers";
import { moveDownInArray, moveUpInArray } from "../../helpers/items-helper";

type Props = {
  company: Company;
  stations: Station[];
  setWebsocketEmit: any;
};

const ModalCompanySettings: FunctionComponent<Props> = ({
  company,
  stations,
  setWebsocketEmit,
}) => {
  const displayName = "ModalCompanySettings:";
  const enableDebug = false;

  const [showModal, setshowModal] = useState(false);
  const [applyToAllUsers, setApplyToAllUsers] = useState(false);
  const [toDisplay, setToDisplay] = useState<string>("order");
  const [stationsOrdered, setStationsOrdered] = useState<Station[]>(stations);

  useEffect(() => {
    stations && stations.length > 0 && setStationsOrdered(stations);
  }, [stations]);

  useEffect(() => {
    enableDebug && console.log(displayName, "showModal:", showModal);
    showModal && enableDebug && console.log(displayName, "stations:", stations);
  }, [showModal]);

  function handleSubmitScreenOrder() {
    enableDebug && console.log(displayName, "Saving stations order");
    let stationsOrderedId: number[] = [];
    stationsOrdered.forEach((one) => stationsOrderedId.push(one.id));
    enableDebug &&
      console.log(displayName, "stationsOrderedId:", stationsOrderedId);
    enableDebug &&
      console.log(displayName, "applyToAllUsers:", applyToAllUsers);

    const the_command = {
      command: "save_stations_order",
      the_order: "[" + stationsOrderedId.toString() + "]",
      company_id: stations[0].company_id,
      set_to_all_user: (applyToAllUsers)?1:0,
    };
    const the_request: any = {
      type: "commands",
      number: 1,
      liste: [JSON.stringify(the_command)],
      from: "token",
      token: authHeader(),
    };
    setWebsocketEmit({ channel: "request", request: the_request });
    setshowModal(false);
  }

  function screen_order() {
    return (
      <Fragment>
        <h4>
          <strong>Ordonner les stations pour {company.name}</strong>
        </h4>
        {stationsOrdered?.map((station, index) => {
          return (
            <div className="oblon-border flex-nowrap-justify-between">
              <div>{station.name}</div>
              <div className="flex-nowrap-justify-between">
                {index ? (
                  <FontAwesomeIcon
                    className="px-1"
                    icon={faCircleUp}
                    size="lg"
                    onClick={() => setStationsOrdered(moveUpInArray(stationsOrdered, index))}
                  />
                ) : undefined}
                {index !== stations.length - 1 ? (
                  <FontAwesomeIcon
                    className="px-1"
                    icon={faCircleDown}
                    size="lg"
                    onClick={() => setStationsOrdered(moveDownInArray(stationsOrdered, index))}
                  />
                ) : undefined}
              </div>
            </div>
          );
        })}
        <div className="oblon-border flex-nowrap-justify-around border-3">
          <div>Appliquer à tous les utilsateurs de {company.name}</div>
          <input
            id="apply_to_all_users"
            type="checkbox"
            checked={applyToAllUsers}
            onChange={() => setApplyToAllUsers(!applyToAllUsers)}
          />
        </div>
        <div className="flex-nowrap-justify-around py-3">
          <button
            type="button"
            className="squama-btn-navbar squama-btn-gestion-modal"
            onClick={() => setshowModal(false)}
          >
            Annuler
          </button>
          <button
            type="submit"
            className="squama-btn-navbar squama-btn-gestion squama-btn-gestion-modal"
            onClick={() => handleSubmitScreenOrder()}
          >
            Enregistrer
          </button>
        </div>
      </Fragment>
    );
  }

  function screen_synchronization() {
    return (
      <div className="row">
        <h2>
          <strong>Synchroniser les stations</strong>
        </h2>
        <div className="col align-self-center">
          Now(UTC):{format_date(convert_date_to_UTC(new Date()))}
        </div>
        <div className="col align-self-center">
          Now:{format_date(new Date())}
        </div>
      </div>
    );
  }

  function the_modal() {
    return (
      <div className="modal-main">
        <div className="modal-container">
          {/* Start Header */}
          <div className="modal-header flex-nowrap-justify-between px-2">
            <div />
            <div>Paramètres de {company.name}</div>
            <button
              type="button"
              className="btn-close"
              style={{width:"0.75em", height:"0.75em"}}/* Modify the size */
              aria-label="Close"
              onClick={() => setshowModal(false)}
            />
          </div>
          {/* End Header */}
          {/* Start Body */}
          <div className="modal-body">
            {toDisplay === "synchronization" && screen_synchronization()}
            {toDisplay === "order" && screen_order()}
          </div>
          {/* End Body */}
          {/* Start Footer */}
          {/* <div className="modal-footer">
            <button
              type="button"
              className={
                "btn-station-card " +
                isBtnSelected(toDisplay, "synchronization")
              }
              onClick={() => setToDisplay("synchronization")}
            >
              <FontAwesomeIcon icon={faArrows} size="1x" />
              &nbsp;&nbsp;Synchro
            </button>
            <button
              type="button"
              className={
                "btn-station-card " + isBtnSelected(toDisplay, "order")
              }
              onClick={() => setToDisplay("order")}
            >
              <FontAwesomeIcon icon={faArrows} size="1x" />
              &nbsp;&nbsp;order
            </button>
          </div> */}
          {/* End Footer */}
        </div>
      </div>
    );
  }
  return (
    <Fragment>
      {showModal && the_modal()}
      <FontAwesomeIcon
        icon={faCog}
        size="1x"
        onClick={() => setshowModal(true)}
      />
    </Fragment>
  );
};

export default ModalCompanySettings;
