import { Fragment, FunctionComponent, useState } from "react";
import { useForm } from "react-hook-form";

import Station from "../../models/station";
import Company from "../../models/company";

import StationService from "../../services/station-service";
import CompanyService from "../../services/company-service";

type ModalItems = {
  companyIdSelected: number;
  stationIdSelected: number;
  stationName: string;
  stationAddress: string;
  stationPosition: string;
  stationType: string;
};

const ModalCreateModifyStation: FunctionComponent = () => {
  const displayName = "ModalCreateModifyStation:";
  const enableDebug = false;
  const enableMoreDebug = false;

  const { register, handleSubmit } = useForm<ModalItems>();

  const [companies, setCompanies] = useState<Company[] | null>(null);
  const [stations, setStations] = useState<Station[]>([]);
  const [theStation, setTheStation] = useState<Station>();
  const [isNoStationSelected, setIsNoStationSelected] = useState<boolean>(true);
  const [stationsRetrieved, setStationsRetrieved] = useState<boolean>(false);

  const [stationName, setStationName] = useState<string>();
  const [stationType, setStationType] = useState<string>();

  const [showModal, setshowModal] = useState(false);
  const handleCloseModal = () => {
    setshowModal(false);
  };

  function initStationDatas() {
    setIsNoStationSelected(true);
    setStationName("");
    setStationType("thermeau");
    setStationsRetrieved(false);
  }

  const handleShowModal = () => {
    CompanyService.getCompanies().then((companies) => {
      setCompanies(companies);
    });
    initStationDatas();
    setshowModal(true);
  };

  function the_modal() {
    const onHookFormSubmit = (data: ModalItems) => {
      //if(enableMoreDebug) console.log(displayName, "data", data);
      if (Number(data.companyIdSelected) === 0) {
        if (enableDebug) console.log(displayName, "No company selected yet");
        return;
      }
      let same_station_name = stations.find(
        (station) => station.name === data.stationName
      );
      if (
        same_station_name &&
        Number(same_station_name.id) !== Number(data.stationIdSelected)
      ) {
        window.confirm("Ce nom de station existe déjà !");
        if (enableDebug)
          console.log(displayName, "This station already exists cannot modify");
        if (enableDebug)
          console.log(
            displayName,
            "same_station_name.id:" + same_station_name.id
          );
        if (enableDebug)
          console.log(
            displayName,
            "data.stationIdSelected:" + data.stationIdSelected
          );
        return;
      }
      if (Number(data.stationIdSelected) === 0) {
        if (enableDebug)
          console.log(displayName, "Ask to create a new station with data:");
        if (enableDebug) console.log(displayName, data);
        if (enableDebug)
          console.log(displayName, "New station:" + data.stationName);
        let newStation = new Station(
          data.companyIdSelected,
          data.stationName,
          data.stationType
        );
        if (enableDebug) console.log(displayName, newStation);
        //We set station.id to company.id, it will be used in API to guess the company_station link
        StationService.addStation(newStation).then(() => handleCloseModal());
      } else {
        if (theStation) {
          theStation.name = data.stationName;
          theStation.type = data.stationType;
          if (enableDebug) console.log(displayName, "Update station with:");
          if (enableDebug) console.log(displayName, theStation);
          //theStation.address = data.stationAddress;
          StationService.updateStation(theStation).then(() =>
            handleCloseModal()
          );
        }
      }
    };

    const onHookFormDelete = () => {
      //if(enableDebug) console.log(displayName, "data", data);
      if (
        window.confirm("Etes vous sur de vouloir supprimer cette station ?")
      ) {
        if (theStation) {
          if (enableDebug)
            console.log(
              displayName,
              "Going to delete station id:" +
                theStation.id +
                " named:" +
                theStation.name
            );
          StationService.deleteStation(theStation.id).then(() =>
            handleCloseModal()
          );
        }
      }
    };

    const handleSelectChangeCompany = (event: React.ChangeEvent<any>) => {
      if (enableMoreDebug)
        console.log(displayName, "event.target.value:", event.target.value);
      if (Number(event.target.value) > 0) {
        CompanyService.getCompany(event.target.value).then((company) => {
          setStations([]);
          if (company) {
            if (enableDebug) console.log(displayName, company);
            if (enableDebug)
              console.log(displayName, "assigned name as :" + company.name);
            initStationDatas();
          }
        });
        StationService.getStationsForACompany(event.target.value).then(
          (stations) => {
            if (enableMoreDebug) {
              console.log(displayName, "stations:", stations);
              console.log(displayName, "stations.length:", stations.length);
            }
            setStations(stations);
            setStationsRetrieved(true);
          }
        );
      } else {
        initStationDatas();
      }
    };

    const handleSelectChangeStation = (event: React.ChangeEvent<any>) => {
      //if(enableDebug) console.log(displayName, event.target.value)
      if (Number(event.target.value) > 0) {
        if (enableDebug)
          console.log(displayName, "setIsNoStationSelected(false)");
        let pTheStation = stations.find(
          (station) => station.id === Number(event.target.value)
        );
        if (pTheStation) {
          setIsNoStationSelected(false);
          setTheStation(pTheStation);
          setStationName(pTheStation.name);
          setStationType(pTheStation.type);
        }
      } else {
        if (enableDebug)
          console.log(displayName, "setIsNoStationSelected(true)");
        initStationDatas();
      }
    };

    return (
      <div className="modal-main">
        <div className="modal-container">
          {/* Start Header */}
          <div className="modal-header">Créer/Modifier une station</div>
          {/* End Header */}
          {/* Start Body */}
          <div className="modal-body m-w-500">
            <div className="flex-nowrap-justify-around">
              <div className="col align-self-center">Nom de la société :</div>
              <div className="col align-self-center">
                <select
                  className="squama-select"
                  {...register("companyIdSelected", { required: true })}
                  onChange={handleSelectChangeCompany}
                >
                  <option value="0">Sélectionner</option>
                  {companies &&
                    companies.map((company) => {
                      return (
                        <option value={company.id} key={company.id}>
                          {company.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            {stationsRetrieved ? (
              <Fragment>
                <hr className="modal-separator" />
                {/* Select new/existing station */}
                <div className="row m-0">
                  <div className="col"></div>
                  <div className="col align-self-center">
                    <select
                      className="squama-select"
                      {...register("stationIdSelected", { required: true })}
                      onChange={handleSelectChangeStation}
                    >
                      <option value="0">NouvelleStation</option>
                      {stations &&
                        stations.length &&
                        stations.map((station) => {
                          return (
                            <option value={station.id} key={station.id}>
                              {station.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="col align-self-center"></div>
                  {isNoStationSelected ? undefined : (
                    <button
                      type="button"
                      className="squama-btn-navbar squama-btn-gestion-delete squama-btn-gestion-modal"
                      onClick={onHookFormDelete}
                    >
                      Supprimer
                    </button>
                  )}
                  <div className="col"></div>
                </div>
                <hr className="modal-separator" />
                <div className="row m-0">
                  <div className="col-5 align-self-center">Nom :</div>
                  <div className="col-7 align-self-center">
                    <input
                      id="stationName"
                      type="text"
                      className="stay-in-div"
                      value={stationName}
                      {...register("stationName", { required: true })}
                      onChange={(e) => {
                        setStationName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-5 align-self-center">
                    Type de station :
                  </div>
                  <div className="col-7">
                    <div className="flex">
                      <input
                        type="radio"
                        id="thermeau"
                        value="thermeau"
                        checked={stationType === "thermeau"}
                        {...register("stationType", { required: true })}
                        onChange={(e) => {
                          setStationType("thermeau");
                        }}
                      />
                      THERM'EAU
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        id="flow"
                        value="flow"
                        checked={stationType === "flow"}
                        {...register("stationType", { required: true })}
                        onChange={(e) => {
                          setStationType("flow");
                        }}
                      />
                      F'LOW
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        id="flhauteur"
                        value="flhauteur"
                        checked={stationType === "flhauteur"}
                        {...register("stationType", { required: true })}
                        onChange={(e) => {
                          setStationType("flhauteur");
                        }}
                      />
                      FL'HAUTEUR
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        id="pap"
                        value="pap"
                        checked={stationType === "pap"}
                        {...register("stationType", { required: true })}
                        onChange={(e) => {
                          setStationType("pap");
                        }}
                      />
                      PAP'EYE
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        id="papang"
                        value="papang"
                        checked={stationType === "papang"}
                        {...register("stationType", { required: true })}
                        onChange={(e) => {
                          setStationType("papang");
                        }}
                      />
                      VIG'EEL
                    </div>
                  </div>
                </div>
                {!isNoStationSelected ? (
                  <Fragment>
                    <hr className="modal-separator" />
                    <div className="row m-0">
                      <div className="col-5 align-self-center squama-font">
                        Transmetteurs :
                      </div>
                      <div className="col-7 align-self-center">
                        <ul>
                          {theStation !== undefined &&
                            theStation !== null &&
                            theStation.transmitters !== undefined &&
                            theStation.transmitters.map((transmitter) => {
                              return (
                                <li
                                  value={transmitter.id}
                                  key={"key-transmitter-" + transmitter.id}
                                  className="text-left"
                                >
                                  {transmitter.mac}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </Fragment>
                ) : undefined}
                {!isNoStationSelected ? (
                  <Fragment>
                    <hr className="modal-separator" />
                    <div className="row m-0">
                      <div className="col-5 align-self-center squama-font">
                        Contacts :
                      </div>
                      <div className="col-7 align-self-center">
                        <ul>
                          {theStation !== undefined &&
                            theStation !== null &&
                            theStation.contacts !== undefined &&
                            theStation.contacts.map((contact) => {
                              return (
                                <li
                                  value={contact.user_id}
                                  key={"key-contact-" + contact.user_id}
                                  className="text-left"
                                >
                                  {"user:" + contact.email}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </Fragment>
                ) : undefined}
              </Fragment>
            ) : undefined}
          </div>
          {/* End Body */}
          {/* Start Footer */}
          <div className="modal-footer">
            <button
              type="button"
              className="squama-btn-navbar squama-btn-gestion-modal"
              onClick={handleCloseModal}
            >
              Annuler
            </button>
            <button
              type="submit"
              className="squama-btn-navbar squama-btn-gestion squama-btn-gestion-modal"
              onClick={handleSubmit(onHookFormSubmit)}
            >
              Enregistrer
            </button>
          </div>
          {/* End Footer */}
        </div>
      </div>
    );
  }
  return (
    <Fragment>
      {showModal && the_modal()}
      <button
        type="button"
        className="squama-btn-navbar squama-btn-gestion"
        onClick={handleShowModal}
      >
        Édition Station
      </button>
    </Fragment>
  );
};

export default ModalCreateModifyStation;
