import React, { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SquamaNavbar from "../components/squama-navbar";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import { useForm } from "react-hook-form";
import { fillContactMessage } from "../helpers/send-mail";
import EmailService from "../services/email-service";

type ModalItems = {
  contactName: string;
  companyName: string;
  companyAddress: string;
  companyPhone: string;
  companyEmail: string;
  object: string;
  captchaInput: string;
  message: string;
};

const SquamaContact: FunctionComponent = () => {
  const { register, handleSubmit } = useForm<ModalItems>();
  const [throwError, setThrowError] = useState<string>("");
  const history = useNavigate();
  useEffect(() => {
    loadCaptchaEnginge(8);
  }, []);

  let isDisabled = false;

  function check_valid_email(email: string) {
    let list = [
      "yopmail",
      "jetable",
      "link2mail",
      "haltospam",
      "meltmail",
      "anonymbox",
      "10minutemail",
      "mailtemporaire",
      "trashmail",
      "dodgit",
      "filzmail",
      "trash",
      "spam",
    ];
    let returned = list.filter((one) => email.includes(one));
    console.log(returned);
    if (returned.length > 0) {
      return false;
    }
    return true;
  }

  const handleTheSubmit = (data: ModalItems) => {
    setThrowError("");
    console.log("data", data);
    if (check_valid_email(data.companyEmail)) {
      if (validateCaptcha(data.captchaInput) === true) {
        console.log("Captcha correct !!");
        let theSubject: string = "[squama.fr]:PageWeb Contact :" + data.object;
        if (window.location.pathname === "/contactconnected") {
          theSubject = "[squama.fr]:PageWeb Contact Connected :" + data.object;
        }
        EmailService.sendEmailAPI(
          theSubject,
          fillContactMessage(
            data.contactName,
            data.companyName,
            data.companyAddress,
            data.companyPhone,
            data.companyEmail,
            data.object,
            data.message
          ),
          data.companyEmail
        ).then(() => {
          window.alert("Message envoyé !");
        });
        if (window.location.pathname === "/contactconnected") {
          history("/dashboard");
        } else {
          history("/accueil");
        }
      } else {
        setThrowError("Mauvais Captcha");
      }
    } else {
      setThrowError("Email invalid !");
    }
  };

  return (
    <div className="in-page-flex">
      {/* barre*/}
      <SquamaNavbar thepath={window.location.pathname} />
      <div>
        <hr></hr>
      </div>
      <div className="main-center-div the-background">
        <div className="panel-contact-modal">
          <div className="panel-contact-heading flex-center">
            Envoyer un message
          </div>
          <div className="panel-contact-main">
            <div className="panel-contact-body">
              {throwError ? (
                <div className="panel-contact-one-line error-font">
                  {throwError}
                </div>
              ) : undefined}

              <div className="form-floating">
                <select
                  className="form-select"
                  id="floatingSelect"
                  aria-label="Floating label select example"
                  {...register("object", { required: true })}
                >
                  <option value="Demande informations">
                    Demande d'informations
                  </option>
                  <option value="Demande devis">Demande de devis</option>
                  <option value="Demande technique">Demande technique</option>
                  <option value="Demande commande">Commande</option>
                  <option value="Autre demande">Autre</option>
                </select>
                <label>Votre demande:</label>
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  id="contactName"
                  className="form-control"
                  placeholder="contactName"
                  {...register("contactName", { required: true })}
                />
                <label className="">Prénom NOM*</label>
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  id="companyName"
                  className="form-control"
                  placeholder="companyName"
                  {...register("companyName", { required: true })}
                />
                <label className="">Société*</label>
              </div>

              <div className="form-floating">
                <input
                  type="text"
                  id="companyAddress"
                  className="form-control"
                  placeholder="companyAddress"
                  {...register("companyAddress", { required: true })}
                />
                <label className="">Adresse</label>
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  id="companyPhone"
                  className="form-control"
                  placeholder="companyPhone"
                  {...register("companyPhone", {
                    required: true,
                    minLength: 10,
                    maxLength: 10,
                  })}
                />
                <label className="">Téléphone*</label>
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  id="companyEmail"
                  className="form-control"
                  placeholder="companyEmail"
                  {...register("companyEmail", {
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                />
                <label className="">Email*</label>
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  id="captchaInput"
                  className="form-control"
                  placeholder="captchaInput"
                  {...register("captchaInput", {
                    required: true,
                    minLength: 5,
                    maxLength: 10000,
                  })}
                />
                <label className="">Recopier les caractères ci-dessous*:</label>
              </div>
              <div className="flex items-center">
                <LoadCanvasTemplate />
              </div>
            </div>
            <div className="panel-contact-body flex-grow-1">
              <div className="form-floating flex-grow-1">
                <textarea
                  className="form-control panel-contact-message h-100"
                  placeholder="Message ICI"
                  id="floatingTextarea"
                  disabled={isDisabled}
                  {...register("message", { required: true })}
                ></textarea>
                <label>Message*</label>
              </div>
            </div>
          </div>
          <div className="panel-contact-footer">
            <button
              type="button"
              className="squama-btn-navbar squama-btn-gestion-modal"
              disabled={isDisabled}
              onClick={handleSubmit(handleTheSubmit)}
            >
              Envoyer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SquamaContact;
