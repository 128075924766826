import { FunctionComponent, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SquamaNavbar from '../components/squama-navbar';

import ModalCreateModifyClient from '../components/modals/modal-create-modify-client';
import ModalCreateModifyCompany from '../components/modals/modal-create-modify-company';
import ModalCreateModifyUser from '../components/modals/modal-create-modify-user';
import ModalCreateModifyStation from '../components/modals/modal-create-modify-station';
import ModalCreateModifyTransmitteur from '../components/modals/modal-create-modify-transmitteur';
import ModalShowTransmetteursTextTable from '../components/modals/modal-show-transmetteurs';
import ModalShowSensorsTextTable from '../components/modals/modal-show-sensors';
import ModalShowSensorTestsTextTable from '../components/modals/modal-show-sensor-tests';
import ModalShowDatatosendTextTable from '../components/modals/modal-show-datatosend';
import ModalShowLoginsTextTable from '../components/modals/modal-show-logins';
import { return_logo_from_type } from '../helpers/station-helper';
import AuthenticationService from '../services/authentication-service';
import { WebsocketEmitRequest } from '../models/websocket';
import { authHeader } from '../helpers/auth-headers';
import { useWebSocket } from '../services/websocket-service';

const SquamaGestion: FunctionComponent = () => {
  const displayName = "SquamaGestion:";
  const enableDebug = false;
  const enableDebugWebsocket = false;
  const { socket } = useWebSocket();
  const [macToFind, setMacToFind] = useState<string>("");
  const [websocketEmit, setWebsocketEmit] = useState<WebsocketEmitRequest | undefined>(undefined);

  const history = useNavigate();
  const url = window.location.href;
  let suffix_url = "production"
  if(url.includes("alpha")){
    suffix_url="alpha";
  }else if(url.includes("beta")){
    suffix_url="beta";
  }

  useEffect(() => {
    enableDebugWebsocket&&console.log(displayName,"useEffect got transmitter station id");
    if (!socket) return;
    socket.on("transmitters_desc", (the_transmitters_desc_str: string) => {
      const the_transmitters = JSON.parse(the_transmitters_desc_str);
      (enableDebugWebsocket)&&console.log(displayName, "Transmitters:", the_transmitters);
      if(the_transmitters.length>0 && the_transmitters[0].station_id){
        (enableDebug)&&console.log(displayName, "Go to:", the_transmitters[0].station_id);
        history("/stations/"+the_transmitters[0].station_id.toString());
      }
    });
    if (websocketEmit) {
      (enableDebugWebsocket)&&console.log(displayName, "----------> websocket emit :", websocketEmit);
      socket.emit(websocketEmit.channel, websocketEmit.request);
      setMacToFind("");
    }
  }, [socket, websocketEmit]);

  if(enableDebug){
    console.log(displayName,"url:",url)
    console.log(displayName,"suffix_url:",suffix_url)
  }
  if (AuthenticationService.getUserID()===undefined) {
    history('/accueil');
  }

  function goto_station_with_transmitter_mac(mac : string){
    enableDebug && console.log(displayName,"mac:",mac);
    const the_request: any = {
      type: "transmitters_desc",
      number: 1,
      liste: [mac],
      from: "transmitter_mac",
      filter: ["no_transmitters_desc","station_id"],
      token: authHeader()
    }
    setWebsocketEmit({ channel: "request", request: the_request });
  }

  return (
    <div className="in-page-flex">
      {/* barre*/}
      <SquamaNavbar thepath={window.location.pathname} />
      <div><hr></hr></div>
      <div className="main-gestion-div-flex-1 the-background">
        <div className="main-gestion-div">
          <Link to='/map'>
            <button type="button" className="squama-btn-navbar squama-btn-gestion">
              Page Nanogis(Carte + Liste)
            </button>
          </Link>
        </div>
        <div className="main-gestion-div">
          <div className="flex-center panel-station-installer-line-height">
            <div className='white-font'>Aller à la station associé à la MAC:&nbsp;&nbsp;</div>
            <input id={"transmitter-mac"} type="text" className="panel-station-installer-value panel-station-installer-line-height" value={macToFind} onChange={e => setMacToFind(e.target.value)}/>
            <div data-tip="Aller à la station associée" onClick={()=>goto_station_with_transmitter_mac(macToFind)}>{return_logo_from_type("transmitter", "logo-sensor-2-3-size-png")}</div>
          </div>
        </div>
        <div className="main-gestion-div">
          <ModalShowDatatosendTextTable />
          <ModalShowLoginsTextTable />
        </div>
        <div className="main-gestion-div">
          <ModalShowTransmetteursTextTable />
          <ModalShowSensorsTextTable />
          <ModalShowSensorTestsTextTable />
        </div>
        <div className="main-gestion-div">
          {return_logo_from_type("loader","loader-logo")}
        </div>
        <div className="main-gestion-div">
          <ModalCreateModifyClient />
          <ModalCreateModifyCompany />
          <ModalCreateModifyStation />
          <ModalCreateModifyUser />
          <ModalCreateModifyTransmitteur />
        </div>
        <div className="main-gestion-div">
          <div className="squama-item-navbar"><Link to={'https://logs-'+suffix_url+'.squama.fr'} target="_blank"><button type="button" className="squama-btn-navbar">Logs</button></Link></div>
          <div className="squama-item-navbar"><Link to={'https://broker-'+suffix_url+'.squama.fr'} target="_blank"><button type="button" className="squama-btn-navbar">Broker</button></Link></div>
          <div className="squama-item-navbar"><Link to={'https://flower-'+suffix_url+'.squama.fr'} target="_blank"><button type="button" className="squama-btn-navbar">Flower</button></Link></div>
          <div className="squama-item-navbar"><Link to={'https://bd-'+suffix_url+'.squama.fr/?pgsql=postgres%3A2345&username=aurelien_mathieu&db=api&ns=public'} target="_blank"><button type="button" className="squama-btn-navbar">DB</button></Link></div>
        </div>
      </div>
    </div>
  );
}

export default SquamaGestion;