import { FunctionComponent, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { io } from "socket.io-client";

import SquamaNavbar from '../components/squama-navbar';
import LoaderLogo from "../img/loader-661x661.gif";

import Station from '../models/station';
import Company from '../models/company';
import { authHeader } from '../helpers/auth-headers';
import SiteService, { getSocketIOParams } from '../services/site-service';
import { return_logo_from_type, station_get_min_temperature, station_get_max_temperature, station_get_mean_temperature, station_get_current_temperature, station_get_general_status, station_get_temperature_status, station_get_waterlevel_status } from '../helpers/station-helper';
import TextTable from '../components/TextTable';
import ReactTooltip from 'react-tooltip';
import { compare_value_for_ordering, numberForcedOneDecimal } from '../helpers/tools';

const SquamaCompanyListBoard: FunctionComponent = () => {
  const displayName = "SquamaCompanyListBoard:";
  const enableDebug = false;
  const enableCycleDebug = false;
  const enableDebugUseEffect = false;
  const enableMoreDebug = false;
  const params = useParams<{ id: string }>();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [stations, setStations] = useState<Station[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<number>(0);

  const history = useNavigate();

  useEffect(() => {
    (enableDebugUseEffect) && console.log(displayName, "useEffect:", "WEBSOCKET");
    /* BEGINNING websocket connection */
    const socket = io(SiteService.getWebsocketUrl(), getSocketIOParams());
    /* END websocket connection */
    /* BEGINNING listenner setup */
    socket.on("answer", (answer: any) => {
      if (answer.type === "station_id") {
        enableDebug&&console.log(displayName, "Answer for all station_id:", answer);
        //station_id answer list the station_id that the user is allowed to access for the company previously requested
        //we will ask station_desc by station_id
        const the_request: any = {
          type: "stations_desc",
          number: answer.number,
          liste: answer.liste,
          from: "station_id",
          token: authHeader()
        }
        socket.emit("request", the_request);
      }
    });
    socket.on("companies_desc", (the_companies_desc_str: string) => {
      const the_companies = JSON.parse(the_companies_desc_str);
      if (enableMoreDebug) console.log(displayName, "Companies:", the_companies);
      setCompanies(the_companies);
      if (!params.id) {
        history("/companies/" + the_companies[0].id.toString());
      }
    });
    socket.on("stations_desc", (the_stations_desc_str: string) => {
      const the_stations = JSON.parse(the_stations_desc_str);
      enableDebug&&console.log(displayName, "Stations length:", the_stations.length);
      if (enableMoreDebug) console.log(displayName, "Station:", the_stations);
      setStations(the_stations);
    });
    socket.on("logged_out", (logged_out_desc_str: string) => {
      console.log(displayName, "logged_out:", logged_out_desc_str);
      setStations([]);
    });
    /* END listenner setup */
    /* START websocket requests */
    enableDebug&&console.log(displayName, "Request all companies_desc");
    const the_request: any = {
      type: "companies_desc",
      number: 0,
      liste: [],
      from: "token",
      token: authHeader()
    }
    socket.emit("request", the_request);
    /* END websocket requests */
    if (params.id) {
      enableDebug&&console.log(displayName, "Display only stations of the company");
      setSelectedCompany(Number(+params.id));
      setStations([]);
      const the_request: any = {
        type: "station_id",
        number: 0,
        from: "company_id",
        liste: [+params.id],
        filter: [],
        token: authHeader()
      }
      socket.emit("request", the_request);
    }
    /* START websocket cleanup handler */
    return function cleanup() {
      enableDebug&&console.log(displayName, "useEffect.cleanup")
      socket.disconnect();
    };
    /* END websocket cleanup handler */
  }, [params.id]);

  function is_btn_selected(currentShowedCompanyId: number) {
    if (currentShowedCompanyId === selectedCompany) {
      return "btn-selected"
    }
    return ""
  }

  function disable_select_company() {
    if (selectedCompany === 0) {
      return true;
    }
    return false;
  }

  if (enableCycleDebug) {
    console.log(displayName, "---- CYCLE ----");
    console.log(displayName, "stations:", stations);
  }

  function display_station_current_temperature(station: Station): string {
    const value = station_get_current_temperature(station);
    if (value) {
      return String(numberForcedOneDecimal(value)) + "°C";
    }
    return "Inconnue";
  }

  function display_station_min_max_temperature(station: Station):string{
    const valueMin = station_get_min_temperature(station);
    const valueMax = station_get_max_temperature(station);
    if (valueMin && valueMax) {
      return String(numberForcedOneDecimal(valueMin)) + "°C - " + String(numberForcedOneDecimal(valueMax)) + "°C";
    }
    return "Inconnue";
  }

  function display_station_mean_temperature(station: Station): string {
    const value = station_get_mean_temperature(station);
    if (value) {
      return String(numberForcedOneDecimal(value)) + "°C";
    }
    return "Inconnue";
  }

  function compare_station(stationARow: any, stationBRow: any, _columnId:any):number{
    enableDebug&&console.log("Comparing :",_columnId);
    const stateOrder = ['green', 'orange', 'red', 'grey']
    if(_columnId === "t_mean"){
      const valueA = station_get_mean_temperature(stationARow.original);
      const valueB = station_get_mean_temperature(stationBRow.original);
      return compare_value_for_ordering(valueA,valueB);
    }else if(_columnId === "t_min_max"){
      const valueA = station_get_min_temperature(stationARow.original);
      const valueB = station_get_min_temperature(stationBRow.original);
      return compare_value_for_ordering(valueA,valueB);
    }else if(_columnId === "t_current"){
      const valueA = station_get_current_temperature(stationARow.original);
      const valueB = station_get_current_temperature(stationBRow.original);
      return compare_value_for_ordering(valueA,valueB);
    }else if (_columnId === "state"){
      return (
        stateOrder.indexOf(station_get_general_status(stationARow.original))
        -
        stateOrder.indexOf(station_get_general_status(stationBRow.original))
      );
    }else if (_columnId === "t_state"){
      return (
        stateOrder.indexOf(station_get_temperature_status(stationARow.original))
        -
        stateOrder.indexOf(station_get_temperature_status(stationBRow.original))
      );
    }else if (_columnId === "wl_state"){
      return (
        stateOrder.indexOf(station_get_waterlevel_status(stationARow.original))
        -
        stateOrder.indexOf(station_get_waterlevel_status(stationBRow.original))
      );
    }else if (_columnId === "name"){
      if(stationARow.original?.name && stationBRow.original?.name){
        const stringA:string = stationARow.original?.name;
        const stringB:string = stationBRow.original?.name;
        return (stringA.localeCompare(stringB))
      }
    }
    return 0;
  }

  function get_station_id_name_dict(station: Station) {
    if (station) {
      return { id: station.id, name: station.name };
    }
    return { id: 0, name: "" };
  }
  const light_column_size = 120;
  const TextValueColumns =
    [
      {
        id:"type",
        header: "Type",
        accessorKey: "type",
        cell: (info: any) => (<div className="flex items-center" data-tip={info.getValue()}>{return_logo_from_type(info.getValue(), "logo-sensor-half-size-png")}</div>),
        size: 80,
        //size: 200, //starting column size
        //minSize: 50, //enforced during column resizing
        //maxSize: 500, //enforced during column resizing
      },
      {
        id:"state",
        header: "Etat de la station",
        accessorFn: station_get_general_status,
        cell: (info: any) => <div className={info.getValue() + "led led-sensor-2x"}></div>,
        size: light_column_size,
        sortingFn: compare_station,
      },
      {
        id:"name",
        header: "Nom de la station",
        accessorFn: get_station_id_name_dict,
        cell: (info: any) => <Link className=" flex text-left" to={'/stations/' + info.getValue().id + '/setup'}>{info.getValue().name}</Link>,
        minSize: 100,
        maxSize: 500,
        sortingFn: compare_station,
      },
      {
        id:"t_current",
        header: "T°C mesurée instantanée",
        accessorFn: display_station_current_temperature,
        size: light_column_size,
        sortingFn: compare_station,
      },
      {
        id:"t_min_max",
        header: "T°C min-max journalière",
        accessorFn: display_station_min_max_temperature,
        size: light_column_size,
        sortingFn: compare_station,
      },
      {
        id:"t_mean",
        header: "T°C moyenne journalière",
        accessorFn: display_station_mean_temperature,
        size: light_column_size,
        sortingFn: compare_station,
      },
      {
        id:"t_state",
        header: "État capteur température",
        accessorFn: station_get_temperature_status,
        cell: (info: any) => <div className={info.getValue() + "led led-sensor-2x"}></div>,
        size: light_column_size,
      },
      {
        id:"wl_state",
        header: "État capteur eau",
        accessorFn: station_get_waterlevel_status,
        cell: (info: any) => <div className={info.getValue() + "led led-sensor-2x"}></div>,
        size: 95,
      }
    ]

  return (
    <div className="in-page-flex">
      {/* barre*/}
      <SquamaNavbar thepath={window.location.pathname} />
      {(companies && (companies?.length > 1)) ? (<div><hr></hr></div>) : (null)}
      <div className="row-navbar">
        {(companies) ? (undefined) : (<div><img className="loader-logo" src={LoaderLogo} alt="" /></div>)}
        {companies && (companies?.length > 1) && companies.map(company => (
          <div key={"div-" + company.id} className="squama-item-navbar squama-item-navbar-companies">
            <Link to={'/companies/' + company.id + '/list'}>
              <button type="button" key={"button-" + company.id} className={"squama-btn-navbar-companies " + is_btn_selected(company.id)} disabled={disable_select_company()}>
                {company.name}
              </button>
            </Link>
          </div>
        ))}
      </div>
      <div><hr></hr></div>
      <div className="main-center-div the-background">
        <div className='flex-station-cards-column'>
          <div className="panel-station-default">
            <div className="panel-station-heading panel-station-thermeau-heading">
              <div className='station_title_bar station_title_text items-center'>
                AFFICHAGE LISTE
              </div>
            </div>
            {
              (stations?.length>0) && (
                <TextTable
                  orderBy='state'
                  displayFooter={false}
                  orderByDesc={true}
                  allowPagination={true}
                  numberOfElementDisplayed={1000}
                  searchedText={["id", "mac"]}
                  allValues={stations}
                  valuesColumns={TextValueColumns}
                  classNameHeader="company-list-table-text-thead"
                  classNameTH="company-list-table-text-td-th"
                  classNameTD="company-list-table-text-td-th"
                />
              )
            }
            <div className="panel-station-footer"></div>
          </div>
        </div>
      </div>
      <ReactTooltip type="light" />
    </div>
  );
}
export default SquamaCompanyListBoard;
