import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Routes, Route, Navigate } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import PageNotFound from './pages/page-not-found';
import Login from './pages/login';
import ForgotLogin from './pages/forgotlogin';
import Logout from './pages/logout';
import SquamaCompanyDashboard from './pages/squama-company-dashboard';
import SquamaCompanyMapBoard from './pages/squama-company-mapboard';
import SquamaSingleStation from './pages/squama-single-station';
import SquamaParameters from './pages/squama-parameters';
import SquamaContact from './pages/squama-contact';
import SquamaRandomLink from './pages/squama-random-link';
import SquamaGestion from './pages/squama-gestion';
import SquamaMap from './pages/squama-map';
//import SquamaTest from './pages/squama-test';
import SquamaAccueil from './pages/squama-accueil';
import SquamaSolution from './pages/squama-solution';
import SquamaAPropos from './pages/squama-a-propos';
import SquamaFooter from './components/squama-footer';
import SquamaLegalMentionsConfidentialPolitics from './pages/squama-legal-mentions-confidential-politics';
import AuthenticationService from './services/authentication-service';
import ReactGA from 'react-ga4';

import './App.css';
import SquamaCompanyListBoard from './pages/squama-company-list';
import SquamaReroute from './pages/squama-reroute';
ReactGA.initialize('G-7PWHJPE4FP');

function App() {
  const displayName = "App:";
  const enableDebug = false;
  const enableCycleDebug = false;
  const enableDebugUseEffect = false;
  const enableMoreDebug = false;
  const queryParameters = new URLSearchParams(window.location.search)
  const [processToken, setProcessToken] = useState<boolean>(false);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  let location_pathname = useLocation()?.pathname;

  useEffect(() => {
    if(enableDebugUseEffect) console.log(displayName,"Use effect Google start");
    let gaUserId = AuthenticationService.getUserID();
    let gaUserEmail = AuthenticationService.getUserEmail();
    if(gaUserId!==0){
      ReactGA.set({ userId: gaUserId.toString() });
    }
    if(gaUserEmail!==""){
      ReactGA.set({ userEmail: gaUserEmail });
    }
    ReactGA.send({hitType: "pageview", page: location_pathname});
    if(enableDebugUseEffect) console.log(displayName,"Use effect Google end");
  }, [location_pathname]);

  useEffect(() => {
    if(enableDebugUseEffect) console.log(displayName,"Use effect Credentials start");
    if(enableMoreDebug) console.log(displayName,"###################### Initial location:",location_pathname);
    const token = queryParameters.get("token")
    if(enableMoreDebug){
      console.log(displayName, "token:", token);
    }
    if (!authenticated && !processToken && token){
      setProcessToken(true);
      if(enableMoreDebug) console.log(displayName, "We got token");
      AuthenticationService.getAuthenticatedWithToken(token).then(
        returned=>{
          if(enableMoreDebug) console.log(displayName, "App getAuthenticatedWithToken then returned:",returned);
          if(returned){
            if(enableDebug) console.log(displayName, "We are authenticate thanks to token");
            window.location.reload();
          }
        }
      );
    }
    if(enableDebugUseEffect) console.log(displayName,"Use effect Credentials end");
  }, [processToken, queryParameters]);

  function RequireAuth({ children }: { children: JSX.Element }) {
    const isAuthenticated = AuthenticationService.getAuthenticated();
    setAuthenticated(isAuthenticated);
    let location = useLocation();
    if (!isAuthenticated) {
      if(enableDebug) console.log(displayName,"Will route to login page with initial location saved!")
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return children;
  }
  (enableCycleDebug)&&console.log(displayName,"---- CYCLE ----");
  return (
    <div className="container-fluid page-container squama-font">
      <CookieConsent
        location="bottom"
        buttonText="J'ai noté!"
        cookieName="myAwesomeSquamaCookie"
        style={{ background: "#467380" }}
        buttonStyle={{ background: "#a6c5ce", color: "#4e503b", fontSize: "15px" }}
        expires={150}
      >
        Ce site utilise des cookies pour améliorer l'expérience utilisateur.
      </CookieConsent>
      {/* Main logo */}
        <div className="main-logo">
          <Link to="/">
            <img className="main-logo-img" src={process.env.PUBLIC_URL + "/squama.png"} alt="" />
          </Link>
        </div>
      {/* gestion des routes */}
      <div className="content-wrap">
        <Routes>
          <Route path="/" element={<SquamaAccueil />} />
          <Route path="/accueil" element={<SquamaAccueil />} />
          <Route path="/solutions" element={<SquamaSolution />} />
          <Route path="/apropos" element={<SquamaAPropos />} />
          <Route path="/legalmentions" element={<SquamaLegalMentionsConfidentialPolitics />} />
          <Route path="/confidentialpolitics" element={<SquamaLegalMentionsConfidentialPolitics />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotlogin" element={<ForgotLogin />} />
          <Route path="/contact" element={<SquamaContact />} />
          <Route path="/randomlink/:randomLink" element={<SquamaRandomLink />} />
          <Route path="/unsubscribeme/:randomLink" element={<SquamaRandomLink />} />
            <Route path="/dashboard" element={<RequireAuth><SquamaCompanyDashboard /></RequireAuth>} />
            <Route path="/companies/:id" element={<RequireAuth><SquamaCompanyDashboard /></RequireAuth>} />
            <Route path="/companies/:id/list" element={<RequireAuth><SquamaCompanyListBoard /></RequireAuth>} />
            <Route path="/companies/:id/map" element={<RequireAuth><SquamaCompanyMapBoard /></RequireAuth>} />
            <Route path="/companies/:id/:finalpath" element={<RequireAuth><SquamaCompanyDashboard /></RequireAuth>} />
            <Route path="/stations/:id" element={<RequireAuth><SquamaSingleStation /></RequireAuth>} />
            <Route path="/stations/:id/:finalpath" element={<RequireAuth><SquamaSingleStation /></RequireAuth>} />
            <Route path="/logout" element={<RequireAuth><Logout /></RequireAuth>} />
            <Route path="/gestion" element={<RequireAuth><SquamaGestion /></RequireAuth>} />
            <Route path="/parameters" element={<RequireAuth><SquamaParameters /></RequireAuth>} />
            <Route path="/contactconnected" element={<RequireAuth><SquamaContact /></RequireAuth>} />
            <Route path="/map" element={<RequireAuth><SquamaMap /></RequireAuth>} />
            <Route path="/transmitter/:mac" element={<RequireAuth><SquamaReroute /></RequireAuth>} />
            <Route path="/transmitter/:mac/:finalpath" element={<RequireAuth><SquamaReroute /></RequireAuth>} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
      <SquamaFooter />
    </div>
  );
}

export default App;
