import React, { FunctionComponent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { authHeader } from "../helpers/auth-headers";
import {
  Item,
  getItemFromName,
  updateItemFromNameWithValueAndSetter,
} from "../helpers/items-helper";
import { return_logo_from_type } from "../helpers/station-helper";
import { debugFunctionIdValue } from "../helpers/tools";
import AuthenticationService from "../services/authentication-service";
import Client from "../models/client";
import Company from "../models/company";
import Station from "../models/station";
import Transmitter from "../models/transmitter";
import WebsocketSquamaRequest, {
  WebsocketEmitRequest,
} from "../models/websocket";
import { useWebSocket } from "../services/websocket-service";
import { handleDateOnChange } from "../helpers/handler-helper";

type Props = {
  station: Station;
};

const StationCardGestion: FunctionComponent<Props> = ({ station }) => {
  const displayName = "StationCardGestion:";
  const enableDebug = false;
  const enableMoreDebug = false;
  const enableDebugWebsocket = false;
  const enableDebugItems = true;
  const { socket } = useWebSocket();
  const history = useNavigate();
  const [readyToValidate, setReadyToValidate] = useState<boolean>(false);
  const [selectedClientId, setSelectedClientId] = useState<number>(0);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [freeTransmitters, setFreeTransmitters] = useState<Transmitter[]>([]);
  const [websocketEmit, setWebsocketEmit] = useState<
    WebsocketEmitRequest | undefined
  >(undefined);
  const [items, setItems] = useState<Item[]>([]);

  const current_user_is_god = AuthenticationService.getUserIsGod();

  useEffect(() => {
    enableDebug && console.log(displayName, "useEffect start");
    if (!socket) return;
    socket.on("logged_out", (logged_out_desc_str: string) => {
      enableDebug &&
        console.log(displayName, "logged_out:", logged_out_desc_str);
    });
    socket.on("answer", (answer: any) => {
      enableDebug && console.log(displayName, "Answer:", answer);
    });
    socket.on("clients_desc", (values_desc_str: string) => {
      if (enableDebugWebsocket)
        console.log(
          displayName,
          "<---------- websocket recv :clients_desc",
          values_desc_str
        );
      const the_clients = JSON.parse(values_desc_str);
      enableDebug &&
        console.log(displayName, "Clients length:", the_clients.length);
      if (enableDebug) console.log(displayName, "Clients:", the_clients);
      setClients(the_clients);
    });
    socket.on("companies_desc", (values_desc_str: string) => {
      if (enableDebugWebsocket)
        console.log(
          displayName,
          "<---------- websocket recv :companies_desc",
          values_desc_str
        );
      const values = JSON.parse(values_desc_str);
      enableDebug && values && console.log(displayName, "Companies:", values);
      values && values.length > 0 && setCompanies(values);
    });
    socket.on("transmitters_desc", (values_desc_str: string) => {
      const values = JSON.parse(values_desc_str);
      enableDebug &&
        values &&
        console.log(displayName, "transmitters:", values);
      values && values.length > 0 && setFreeTransmitters(values);
    });
    socket.on("done", (values_desc_str: string) => {
      history(window.location.pathname);
      window.location.reload();
    });
    if (websocketEmit) {
      if (enableDebugWebsocket)
        console.log(displayName, "----------> websocket emit :", websocketEmit);
      socket.emit(websocketEmit.channel, websocketEmit.request);
    }
    enableDebug && console.log(displayName, "useEffect end");
    return function cleanup() {
      enableDebug && console.log(displayName, "useEffect.cleanup");
    };
  }, [socket, websocketEmit]);

  useEffect(() => {
    if (selectedClientId) {
      console.log(
        displayName,
        "First fetch new companies for client_id:",
        getItemFromName(items, "station_move_to_client-" + station.id).value
      );
      setCompanies([]);
      const the_request = new WebsocketSquamaRequest(
        "companies_desc",
        authHeader()
      );
      the_request.from = "client_id";
      the_request.number = 1;
      the_request.liste = [selectedClientId];
      setWebsocketEmit({ channel: "request", request: the_request });
    }
  }, [selectedClientId]);

  useEffect(() => {
    enableDebugItems && console.log(displayName, "items:", items);
    enableDebug &&
      console.log(
        displayName,
        "item(station_move_to_company-" + station.id + ").value:",
        getItemFromName(items, "station_move_to_company-" + station.id).value
      );
    enableDebug &&
      console.log(displayName, "station.company_id:", station.company_id);
    enableDebug && console.log(displayName, "one", clients.length);
    if (
      getItemFromName(items, "station_move_to_company-" + station.id).value !==
        "0" &&
      getItemFromName(items, "station_move_to_company-" + station.id).value !==
        station.company_id.toString()
    ) {
      setReadyToValidate(true);
    } else if (get_first_transmitter_to_move(station, items) !== undefined) {
      setReadyToValidate(true);
    } else {
      setReadyToValidate(false);
    }
  }, [items, enableDebug, enableDebugItems]);

  useEffect(() => {
    if (freeTransmitters) {
      const the_request = new WebsocketSquamaRequest(
        "clients_desc",
        authHeader()
      );
      setWebsocketEmit({ channel: "request", request: the_request });
    }
  }, [freeTransmitters]);

  useEffect(() => {
    const the_request = new WebsocketSquamaRequest(
      "transmitters_desc",
      authHeader()
    );
    the_request.from = "company_id";
    the_request.number = 1;
    the_request.liste = [6];
    the_request.filter = ["no_transmitters_desc", "mac", "id"];
    //const the_request: any = {
    //  type: "transmitters_desc",
    //  number: 1,
    //  liste: [6],
    //  filter: ["no_transmitters_desc", "mac", "id"],
    //  from: "company_id",
    //  token: authHeader()
    //}
    setWebsocketEmit({ channel: "request", request: the_request });
  }, []);

  useEffect(() => {
    enableDebug &&
      console.log(displayName, "freeTransmitters=>", freeTransmitters);
    let newItemArray: Array<Item> = [];
    newItemArray = updateItemFromNameWithValueAndSetter(
      newItemArray,
      "station_move_to_company-" + station.id,
      "0"
    );
    station.transmitters.forEach((one) => {
      newItemArray = updateItemFromNameWithValueAndSetter(
        newItemArray,
        "transmitter_to_move-" + one.mac,
        "0"
      );
    });
    setItems(newItemArray);
  }, [freeTransmitters]);

  function get_first_transmitter_to_move(
    station: Station,
    items: Item[]
  ): Transmitter | undefined {
    return station.transmitters.find(
      (one) =>
        getItemFromName(items, "transmitter_to_move-" + one.mac).value !== "0"
    );
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      getItemFromName(items, "station_move_to_company-" + station.id).value !==
      "0"
    ) {
      const the_command = {
        command: "move_from_station_id_to_company_id",
        from_station_id: station.id,
        to_company_id: getItemFromName(
          items,
          "station_move_to_company-" + station.id
        ).value,
      };
      const the_request: any = {
        type: "commands",
        number: 1,
        liste: [JSON.stringify(the_command)],
        from: "token",
        token: authHeader(),
      };
      setWebsocketEmit({ channel: "request", request: the_request });
    } else {
      const the_transmitter_to_move = get_first_transmitter_to_move(
        station,
        items
      );
      if (the_transmitter_to_move) {
        const the_command = {
          command: "switch_transmitter_mac",
          old_mac: the_transmitter_to_move.mac,
          new_mac: getItemFromName(
            items,
            "transmitter_to_move-" + the_transmitter_to_move.mac
          ).value,
        };
        const the_request: any = {
          type: "commands",
          number: 1,
          liste: [JSON.stringify(the_command)],
          from: "token",
          token: authHeader(),
        };
        setWebsocketEmit({ channel: "request", request: the_request });
      }
    }
  };

  const handleGenericSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    enableDebug &&
      debugFunctionIdValue(
        displayName,
        "handleGenericSelectChange",
        e.target.id,
        e.target.value
      );
    setItems(
      updateItemFromNameWithValueAndSetter(items, e.target.id, e.target.value)
    );
  };

  function gestion_display(station: Station) {
    return (
      <div className="panel-station-installer-column">
        <div
          key={"sensor-station-god-" + station.id}
          className="flex-col-center border-solid p-2 w-384px"
        >
          <div className="h2-font black-font text-center mb-2">
            Déplacer la station vers un autre groupement :
          </div>
          <div className="flex-nowrap-justify-evenly w-100">
            {clients.length > 0 ? (
              <select
                data-tip={"Déplacer vers ce client"}
                className="w-100"
                key={"move_to_client"}
                id={"station_move_to_client-" + station.id}
                value={selectedClientId}
                onChange={(e) => setSelectedClientId(Number(e.target.value))}
              >
                <option value="0">SelectionnerUnClient</option>
                {clients?.map((one) => {
                  return (
                    <option value={one.id} key={one.id}>
                      {one.nickname ? one.nickname : one.name}
                    </option>
                  );
                })}
              </select>
            ) : undefined}
            <select
              data-tip={"Déplacer vers ce groupement"}
              className="w-100"
              key={"move_to_company"}
              id={"station_move_to_company-" + station.id}
              value={
                getItemFromName(items, "station_move_to_company-" + station.id)
                  .value
              }
              onChange={(e) => handleGenericSelectChange(e)}
            >
              <option value="0">
                {companies.length > 0 ? "SelectionnerUnGroupement" : ""}
              </option>
              {companies?.map((one) => {
                return (
                  <option value={one.id} key={one.id}>
                    {one.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <hr></hr>
        <div
          key={"sensor-station-god-" + station.id}
          className="flex-col-center border-solid p-2 w-384px"
        >
          <div
            key={"transmitter-station-god-" + station.id}
            className="h2-font black-font text-center mb-2"
          >
            Remplacer un des transmetteurs de la station:
          </div>
          {station.transmitters?.map((one_t) => {
            return (
              <div
                key={"transmitter-station-god-" + one_t.id}
                className="flex-nowrap-justify-evenly"
              >
                <div className="w-160px flex-center">{one_t.mac}</div>
                <div className="w-30px flex-center">&#x21C4;</div>
                <div className="w-160px flex-center">
                  {freeTransmitters.length > 0 && (
                    <select
                      data-tip={"Remplacer transmetteur"}
                      className="w-100"
                      key={"replace_transmitter"}
                      id={"transmitter_to_move-" + one_t.mac}
                      value={
                        getItemFromName(
                          items,
                          "transmitter_to_move-" + one_t.mac
                        ).value
                      }
                      onChange={(e) => handleGenericSelectChange(e)}
                    >
                      <option value="0">SélectionnerUneMAC</option>
                      {freeTransmitters?.map((transmitter) => {
                        return (
                          <option value={transmitter.mac} key={transmitter.mac}>
                            {transmitter.mac}
                          </option>
                        );
                      })}
                    </select>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <hr></hr>
        <div
          key={"sensor-station-god-" + station.id}
          className="flex-col-center border-solid p-2 w-384px"
        >
          <div
            key={"transmitter-station-god-" + station.id}
            className="h2-font black-font text-center mb-2"
          >
            Supprimmer les données/capteurs entre deux dates:
          </div>
          <div className="container row">
            <div className="col-6 text-right">Entre le :</div>
            <input
              id={"remove_from_date-" + station.id}
              type="datetime-local"
              format-value="yyyy-MM-dd HH:mm"
              className="col-6"
              value={
                getItemFromName(items, "remove_from_date-" + station.id).value
              }
              onChange={(e: any) =>
                handleDateOnChange(displayName, enableDebug, items, e, setItems)
              }
            />
          </div>
          <div className="container row">
            <div className="col-6 text-right">Et le :</div>
            <input
              id={"remove_to_date-" + station.id}
              type="datetime-local"
              format-value="yyyy-MM-dd HH:mm"
              className="col-6"
              value={
                getItemFromName(items, "remove_to_date-" + station.id).value
              }
              onChange={(e: any) =>
                handleDateOnChange(displayName, enableDebug, items, e, setItems)
              }
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className="panel-setup-station-main">
        <div className="panel-installer-station-main-bottom pb-3">
          {current_user_is_god && gestion_display(station)}
        </div>
        {/* Do not disable following else even the admin can't validate when previously disabled */}
        <button
          type="submit"
          className="btn-station-card align-self-center"
          disabled={!readyToValidate}
        >
          {!readyToValidate
            ? return_logo_from_type("loader", "icon-sensor-30")
            : "Valider"}
        </button>
      </div>
      <ReactTooltip type="light" />
    </form>
  );
};
export default StationCardGestion;
