import { FunctionComponent, useEffect, useState } from "react";
import Station from "../models/station";

import StationCardChart from "./station-card-chart";
import StationCardDash from "./station-card-dash";
import StationCardSetup from "./station-card-setup";
import StationCardAlarm from "./station-card-alarm";
import StationCardDownload from "./station-card-download";
import StationCardInformation from "./station-card-information";
import StationCardInstaller from "./station-card-installer";
import StationCardDiagnostics from "./station-card-diagnostics";
import {
  return_logo_from_type,
  return_battery_logo_from_level,
  return_lqi_logo_from_transmitter,
  is_station_have_no_signal_from_capteur,
  is_station_have_no_signal_from_temperature,
  generate_map_link_for_station,
  station_get_gps_date,
} from "../helpers/station-helper";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faChartBar,
  faFileDownload,
  faBell,
  faUserSecret,
  faArrows,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import AuthenticationService from "../services/authentication-service";
import StationCardGestion from "./station-card-gestion";
import { isBtnSelected } from "../helpers/tools";

type Props = {
  station: Station;
  fullscreen: boolean;
  finalpath?: string;
  mapMode: boolean;
};

const StationCard: FunctionComponent<Props> = ({
  station,
  fullscreen,
  finalpath,
  mapMode,
}) => {
  const displayName = "StationCard:";
  const local_debug = false;
  const local_very_debug = false;

  const [toDisplay, setToDisplay] = useState<string>();
  const [color, setColor] = useState<string>();
  const history = useNavigate();
  const current_user_is_god = AuthenticationService.getUserIsGod();

  local_very_debug && console.log(displayName, "station:", station);

  useEffect(() => {
    local_debug &&
      console.log(displayName, "useEffect for station:", station?.name);
    !finalpath && fullscreen && setToDisplay("chart");
    finalpath && finalpath === "dash" && setToDisplay("");
    finalpath && finalpath === "chart" && setToDisplay("chart");
    finalpath && finalpath === "setup" && setToDisplay("setup");
    finalpath && finalpath === "alarm" && setToDisplay("alarm");
    finalpath && finalpath === "download" && setToDisplay("download");
    finalpath &&
      current_user_is_god &&
      (finalpath === "information" ||
        finalpath === "informations" ||
        finalpath === "info") &&
      setToDisplay("information");
    finalpath &&
      current_user_is_god &&
      (finalpath === "diagnostics" || finalpath === "diag") &&
      setToDisplay("diagnostics");
    finalpath &&
      current_user_is_god &&
      finalpath === "installation" &&
      setToDisplay("installation");
    finalpath &&
      current_user_is_god &&
      finalpath === "gestion" &&
      setToDisplay("gestion");
  }, [fullscreen, finalpath]);

  function display_station_title(station: Station) {
    return (
      <div className="station_title_bar">
        {/* Status logo */}
        <div
          className={
            station.least_days_since_last_trame_received > 1
              ? "greyled genericled-50-b"
              : station.general_status + "led genericled-50-b"
          }
        />
        {/* Station Name */}
        <div className="station_title_text">{station.name}</div>
        {/* No Capteurs logo */}
        {is_station_have_no_signal_from_capteur(station) && (
          <div
            className="station_title_one_element"
            key={"nocapteur-" + station.id}
            data-tip="Plus de capteur détecté"
          >
            {return_logo_from_type("no_emission_capteur", "station_title_one_logo_size")}
          </div>
        )}
        {/* No temperature logo */}
        {!is_station_have_no_signal_from_capteur(station) &&
          is_station_have_no_signal_from_temperature(station) && (
            <div
              className="station_title_one_element"
              key={"nocapteur-" + station.id}
              data-tip="Plus de capteur détecté"
            >
              {return_logo_from_type(
                "no_temperature_capteur",
                "station_title_one_logo_size"
              )}
            </div>
          )}
        {/* GPS logo */}
        {current_user_is_god && generate_map_link_for_station(station) && (
          <div
            className="station_title_one_element"
            key={"maps" + station.id}
            data-tip={
              "Dernière position GPS : " + station_get_gps_date(station)
            }
            onClick={(event) =>
              window.open(generate_map_link_for_station(station), "_blank")
            }
          >
            {return_logo_from_type("target", "station_title_one_logo_size")}
          </div>
        )}
        {/* WiFi logo */}
        {current_user_is_god &&
          station.transmitters?.map((one) => {
            return (
              (one.current_modem_type === 2)&&(
              <div className="station_title_one_element" key={"wifi" + one.id}>
                {return_logo_from_type("wifi", "station_title_one_logo_size")}
              </div>)
            );
          })}
        {/* 2G logo */}
        {current_user_is_god &&
          station.transmitters?.map((one) => {
            return (
              (one.type === "2G")&&(
              <div className="station_title_one_element" key={"2g-" + one.id}>
                {return_logo_from_type("2g", "station_title_one_logo_size_2g")}
              </div>)
            );
          })}
        {/* LQI logo */}
        {current_user_is_god &&
          station.transmitters?.map((one) => {
            return (
              <div
                className="station_title_one_element"
                key={"lqi" + one.id}
                data-tip={
                  one.lqi ? "Dernier message reçu : " + one.lqi_date : null
                }
              >
                {return_lqi_logo_from_transmitter(one, "station_title_one_logo_size")}
              </div>
            );
          })}
        {/* No Signal logo */}
        {station.least_days_since_last_trame_received > 0 && (
          <div className="station_title_one_element_size">
            <div>{return_logo_from_type("nosignal", "station_title_one_logo_size station_title_one_logo_move_left")}</div>
            <div className="station_title_nosignal_text vertical-text">
              {station.least_days_since_last_trame_received}j
            </div>
          </div>
        )}
        {/* Battery logo */}
        <div className="station_title_one_element_size">
          <div>
            {return_battery_logo_from_level(station.battery, "battery-logo")}
          </div>
          <div className="station_title_battery_text vertical-text">
            {station.battery}%
          </div>
        </div>
      </div>
    );
  }

  function display_correct_card(
    station: Station,
    the_fullscreen: boolean,
    toDisplay: String | undefined
  ) {
    if (toDisplay === "chart") {
      return (
        <StationCardChart
          key={station.id}
          station={station}
          fullscreen={the_fullscreen}
        />
      );
    } else if (toDisplay === "setup") {
      return (
        <StationCardSetup
          key={station.id}
          station={station}
          fullscreen={the_fullscreen}
        />
      );
    } else if (toDisplay === "alarm") {
      return (
        <StationCardAlarm
          key={station.id}
          station={station}
        />
      );
    } else if (toDisplay === "download") {
      return (
        <StationCardDownload
          key={station.id}
          station={station}
          fullscreen={the_fullscreen}
        />
      );
    } else if (toDisplay === "information") {
      return (
        <StationCardInformation
          key={station.id}
          station={station}
          fullscreen={the_fullscreen}
        />
      );
    } else if (toDisplay === "installation") {
      return (
        <StationCardInstaller
          key={station.id}
          station={station}
          fullscreen={the_fullscreen}
        />
      );
    } else if (toDisplay === "diagnostics") {
      return (
        <StationCardDiagnostics
          key={station.id}
          station={station}
          fullscreen={the_fullscreen}
        />
      );
    } else if (toDisplay === "gestion") {
      return (
        <StationCardGestion
          key={station.id}
          station={station}
        />
      );
    } else {
      return (
        <StationCardDash
          key={station.id}
          station={station}
          fullscreen={the_fullscreen}
        />
      );
    }
  }

  const showTab = (something: string) => {
    if (toDisplay !== something) {
      setToDisplay(something);
    } else {
      setToDisplay("");
    }
  };

  const showBorder = () => {
    setColor("red");
  };

  const hideBorder = () => {
    setColor("#607D8B");
  };

  const goToStation = (id: number) => {
    if (fullscreen) {
      history(`/dashboard`);
    } else {
      history(`/stations/${id}`);
    }
  };

  function panelStationDefaultClassName() {
    let returned: string = "panel-station-default";
    if (mapMode) {
      returned += " panel-station-default-mapmode";
    } else {
      returned += fullscreen
        ? " panel-station-default-fullscreen"
        : " panel-station-default-not-fullscreen";
    }
    returned +=
      toDisplay !== undefined &&
      toDisplay !== null &&
      toDisplay !== "dash" &&
      toDisplay !== ""
        ? " panel-station-default-setup-chart"
        : "";
    return returned;
  }

  return (
    <div
      className={panelStationDefaultClassName()}
      style={{ borderColor: color }}
    >
      <div
        className={
          "panel-station-heading panel-station-" + station.type + "-heading"
        }
        onClick={() => goToStation(station.id)}
        onMouseEnter={showBorder}
        onMouseLeave={hideBorder}
      >
        <div>{display_station_title(station)}</div>
      </div>

      <div className="panel-station-body panel-station-simple-row-justify-center">
        {display_correct_card(station, fullscreen, toDisplay)}
      </div>

      <div className="panel-station-footer">
        <button
          type="button"
          className={"btn-station-card " + isBtnSelected(toDisplay, "setup")}
          onClick={() => showTab("setup")}
        >
          <FontAwesomeIcon icon={faCog} size="1x" />
          &nbsp;&nbsp;Réglages
        </button>
        <button
          type="button"
          className={"btn-station-card " + isBtnSelected(toDisplay, "alarm")}
          onClick={() => showTab("alarm")}
        >
          <FontAwesomeIcon icon={faBell} size="1x" />
          &nbsp;&nbsp;Alertes
        </button>
        <button
          type="button"
          className={"btn-station-card " + isBtnSelected(toDisplay, "chart")}
          onClick={() => showTab("chart")}
        >
          <FontAwesomeIcon icon={faChartBar} size="1x" />
          &nbsp;&nbsp;Visualisation
        </button>
        <button
          type="button"
          className={"btn-station-card " + isBtnSelected(toDisplay, "download")}
          onClick={() => showTab("download")}
        >
          <FontAwesomeIcon icon={faFileDownload} size="1x" />
          &nbsp;&nbsp;Téléchargement
        </button>
      </div>
      {current_user_is_god && (
        <div className="panel-station-footer">
          <button
            type="button"
            className={
              "btn-station-card " + isBtnSelected(toDisplay, "installation")
            }
            onClick={() => showTab("installation")}
          >
            <FontAwesomeIcon icon={faUserSecret} size="1x" />
            &nbsp;&nbsp;Installation
          </button>
          <button
            type="button"
            className={
              "btn-station-card " + isBtnSelected(toDisplay, "diagnostics")
            }
            onClick={() => showTab("diagnostics")}
          >
            <FontAwesomeIcon icon={faUserSecret} size="1x" />
            &nbsp;&nbsp;Diagnostics
          </button>
          <button
            type="button"
            className={
              "btn-station-card " + isBtnSelected(toDisplay, "information")
            }
            onClick={() => showTab("information")}
          >
            <FontAwesomeIcon icon={faUserSecret} size="1x" />
            &nbsp;&nbsp;Informations
          </button>
          <button
            type="button"
            className={
              "btn-station-card " + isBtnSelected(toDisplay, "gestion")
            }
            onClick={() => showTab("gestion")}
          >
            <FontAwesomeIcon icon={faArrows} size="1x" />
            &nbsp;&nbsp;Gestion
          </button>
        </div>
      )}
      <ReactTooltip type="light" multiline={true} html={false} />
    </div>
  );
};

export default StationCard;
