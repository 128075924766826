import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { convert_date_to_UTC, format_date } from "../../helpers/format-date";
import {
  fw_nicely_displayed,
  generic_uuid_nicely_displayed,
  sensor_hw_nicely_displayed,
  sensor_result_string,
  transmitter_hw_number_to_str,
} from "../../helpers/station-helper";
import TextTable from "../TextTable";
import { authHeader } from "../../helpers/auth-headers";
import SensorTestValue from "../../models/sensor-test-value";
import { useWebSocket } from "../../services/websocket-service";

const ModalShowSensorTestTextTable: FunctionComponent = () => {
  const displayName = "ModalShowSensorTestTextTable:";
  const enableDebug = false;
  const enableMoreDebug = false;
  const { socket } = useWebSocket();
  const [sensorTestValues, setSensorTestValues] = useState<
    SensorTestValue[] | null
  >();
  const [showModal, setshowModal] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (enableDebug) console.log(displayName, "useEffect");
    /* BEGINNING websocket connection */
    if (!showModal) {
      return;
    }
    if (!socket) return;
    /* END websocket connection */
    /* BEGINNING listenner setup */
    socket.on("answer", (answer: any) => {
      if (enableDebug) console.log(displayName, "Answer:", answer);
    });
    socket.on("sensor_tests_desc", (the_sensors_test_desc_str: string) => {
      const the_sensors_test_desc = JSON.parse(the_sensors_test_desc_str);
      if (enableMoreDebug)
        console.log(
          displayName,
          "the_sensors_test_desc:",
          the_sensors_test_desc
        );
      setSensorTestValues(the_sensors_test_desc);
    });
    socket.on("logged_out", (logged_out_desc_str: string) => {
      console.log(displayName, "logged_out:", logged_out_desc_str);
      setSensorTestValues(null);
    });
    /* END listenner setup */
    /* START websocket requests */
    if (showModal || reload) {
      if (enableDebug) console.log(displayName, "Request all sensors_desc");
      setSensorTestValues(null);
      const the_request = {
        type: "sensor_tests_desc",
        number: 0,
        liste: [0],
        token: authHeader(),
      };
      socket.emit("request", the_request);
      setReload(false);
    }
    /* END websocket requests */
    /* START websocket cleanup handler */
    return function cleanup() {
      if (enableDebug) console.log(displayName, "useEffect.cleanup");
    };
    /* END websocket cleanup handler */
  }, [socket, showModal, reload]);

  function the_modal() {
    const TextValueColumns = [
      {
        header: "Sensors",
        footer: (props: any) => props.column.id,
        columns: [
          {
            header: "ID",
            accessorKey: "id",
            maxSize: 50,
          },
          {
            header: "WL",
            accessorKey: "c_id",
            maxSize: 50,
          },
          {
            header: "Test date",
            accessorKey: "created",
            minSize: 200,
            accessorFn: (row: any) =>
              convert_date_to_UTC(new Date(row.created)),
            cell: (info: any) => format_date(info.getValue()),
          },
          {
            header: "MAC",
            accessorKey: "mac",
            accessorFn: (row: any) =>
              generic_uuid_nicely_displayed(
                row.c_uuid_1,
                row.c_uuid_2,
                row.c_uuid_3
              ),
          },
          {
            header: "NbCon",
            accessorKey: "c_nb_con",
            maxSize: 30,
          },
          {
            header: "HW",
            accessorKey: "hw_version",
            accessorFn: (row: any) =>
              sensor_hw_nicely_displayed(row.c_hw_version),
          },
          {
            header: "Resulat",
            accessorKey: "c_test_result",
            accessorFn: (row: any) => sensor_result_string(row.c_test_result),
            maxSize: 50,
          },
          {
            header: "Commentaires",
            accessorKey: "comments",
            maxSize: 300,
          },
          {
            header: "FW",
            accessorFn: (row: any) => fw_nicely_displayed(row.c_fw_version),
            maxSize: 50,
          },
          {
            header: "Measures",
            accessorKey: "measures",
            maxSize: 30,
            columns: [
              {
                header: "Pression",
                accessorKey: "c_pression_1",
                maxSize: 50,
              },
              {
                header: "Pression Température",
                accessorKey: "c_pression_temperature_1",
                maxSize: 50,
              },
              {
                header: "Température",
                accessorKey: "c_temperature_1",
                maxSize: 50,
              },
              {
                header: "Next Température",
                accessorKey: "c_next_temperature",
                maxSize: 50,
              },
              {
                header: "Capacitif 1",
                accessorKey: "c_capacitif_1",
                maxSize: 50,
              },
              {
                header: "Capacitif 2",
                accessorKey: "c_capacitif_2",
                maxSize: 50,
              },
            ],
          },
        ],
      },
      {
        header: "Transmetteur testeur",
        footer: (props: any) => props.column.id,
        columns: [
          {
            header: "ID",
            accessorKey: "t_id",
            maxSize: 30,
          },
          {
            header: "FW",
            accessorKey: "t_fw_version",
            maxSize: 30,
            accessorFn: (row: any) => fw_nicely_displayed(row.t_fw_version),
          },
          {
            header: "HW",
            accessorKey: "t_hw",
            accessorFn: (row: any) =>
              transmitter_hw_number_to_str(row.t_hw_version),
          },
          {
            header: "Battery",
            accessorKey: "battery",
            maxSize: 30,
            columns: [
              {
                header: "(%)",
                accessorKey: "t_battery",
                maxSize: 30,
              },
              {
                header: "(mv)",
                accessorKey: "t_battery_mv",
                maxSize: 30,
              },
            ],
          },
          {
            header: "Charging (mv)",
            accessorKey: "t_charging_mv",
            maxSize: 30,
          },
          /*
          {
            header: "Opérateur",
            accessorKey: "t_operator",
            maxSize:30,
          },
          {
            header: "Connection",
            accessorKey: "t_conn_type",
            maxSize:30,
          },
          */
        ],
      },
    ];
    return (
      <div className="modal-main">
        <div className="modal-container">
          {/* Start Header */}
          <div className="modal-header">Arbre des tests capteurs</div>
          {/* End Header */}
          {/* Start Body */}
          <div className="modal-body m-w-500">
            <div className="row">
              <div className="col align-self-center">
                Now(UTC):{format_date(convert_date_to_UTC(new Date()))}
              </div>
              <div className="col align-self-center">
                Now:{format_date(new Date())}
              </div>
            </div>
            {sensorTestValues?.length && (
              <TextTable
                orderBy="created"
                orderByDesc={true}
                allowPagination={true}
                numberOfElementDisplayed={30}
                searchedText={["id", "mac"]}
                allValues={sensorTestValues}
                valuesColumns={TextValueColumns}
              />
            )}
          </div>
          {/* End Body */}
          {/* Start Footer */}
          <div className="modal-footer">
            <button
              type="button"
              className="squama-btn-navbar squama-btn-gestion-modal"
              onClick={() => setReload(true)}
            >
              Reload
            </button>
            <button
              type="button"
              className="squama-btn-navbar squama-btn-gestion-modal"
              onClick={() => setshowModal(false)}
            >
              Fermer
            </button>
          </div>
          {/* End Footer */}
        </div>
      </div>
    );
  }
  return (
    <Fragment>
      {showModal && the_modal()}
      <button
        type="button"
        className="squama-btn-navbar squama-btn-gestion"
        onClick={() => setshowModal(true)}
      >
        Visualiser les tests capteurs
      </button>
    </Fragment>
  );
};

export default ModalShowSensorTestTextTable;
