import React, { createContext, useContext, useEffect, useState } from "react";
import { io ,Socket } from "socket.io-client";
import SiteService from "./site-service";

// Type pour le contexte WebSocket
interface WebSocketContextType {
  socket: Socket | null;
}

// Création du contexte WebSocket
const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

// Hook pour utiliser le contexte WebSocket
export const useWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket doit être utilisé à l'intérieur de WebSocketProvider");
  }
  return context;
};

// Fournisseur du contexte WebSocket
export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const newSocket = io(SiteService.getWebsocketUrl(), SiteService.getSocketIOParams());
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{ socket }}>
      {children}
    </WebSocketContext.Provider>
  );
};
