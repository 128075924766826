import NumericInput from "react-numeric-input2";

function myNumericInputFormat(value: string | null) {
  if (!value) {
    return "Aucun";
  }
  if (value === "0") {
    return "Direct";
  }
  if (value === "-1") {
    return "Aucun";
  }
  return value;
}

function myNumericInputParse(stringValue: string) {
  if (stringValue === "Direct") {
    return 0;
  }
  if (stringValue === "Aucun") {
    return -1;
  }
  return parseInt(stringValue);
}

export function uiNumericInput(
  sensorType: string,
  the_id: string,
  the_value: number,
  changeHandler: any|undefined,
  disabled: boolean,
  disable_direct_input: boolean = true
) {
  const displayName = "uiNumericInput";
  let min = 0;
  let max = 30;
  let step = 0.1;
  let precision = 1;
  let formatHandler = undefined;
  let parseHandler = undefined;
  if (sensorType === "temperature") {
    min = -10;
    max = 40;
  } else if (sensorType === "oxymeter") {
    min = 0;
    max = 50;
  } else if (sensorType === "turbidity") {
    min = 0;
    max = 255;
    step = 1;
  } else if (sensorType === "days") {
    min = 1;
    step = 1;
    precision = 0;
  } else if (sensorType === "days_warning_alarm") {
    min = -1;
    step = 1;
    max = 365;
    precision = 0;
    formatHandler = myNumericInputFormat;
    parseHandler = myNumericInputParse;
  } else if (sensorType === "liquid_level") {
    min = 0;
    step = 1;
    max = 100000;
    precision = 0;
  } else {
    console.log(displayName, "Unkown sensor type:" + sensorType);
  }
  return (
    <NumericInput
      id={the_id}
      mobile={false}
      min={min}
      max={max}
      step={step}
      precision={precision}
      className={
        disable_direct_input
          ? "sensor-numeric-input disable-direct-value-numeric-input"
          : "sensor-numeric-input"
      }
      disabled={disabled}
      format={formatHandler}
      parse={parseHandler}
      snap
      strict
      value={the_value}
      onChange={(
        valueAsNumber: number,
        valueAsString: string,
        input: HTMLInputElement
      ) => changeHandler&&changeHandler(input, valueAsNumber)}
    />
  );
}
