export function are_those_tables_equals(one_table:string[], second_table:string[]):boolean{
  if(JSON.stringify(one_table) === JSON.stringify(second_table)){
    return true;
  }
  return false;
}

export function replace_spaces_with_underscore(entry: string):string{
  return entry.replace(/ /g,"_")
}

export function replace_tild_with_underscore(entry: string):string{
  return entry.replace(/'/g,"_")
}

export function remove_quotes(entry: string):string{
  return entry.replace(/"/g,"")
}

export function replace_specific_char(entry: string):string{
  return remove_quotes(replace_spaces_with_underscore(replace_tild_with_underscore(entry)));
}

export function get_date_as_string():string {
  var rightNow = new Date();
  return rightNow.toISOString().slice(0,10).replace(/-/g,"");
}

export function debugFunctionIdValue(componentName:string, functionName:string,id:any, value:any){
  console.log(componentName, functionName, "=>id:\"",id,"\" = \"",value,"\"");
}

export function numberForcedOneDecimal(the_input:number):string{
  return Number(the_input).toFixed(1)
}

export function convertIntoType(theValue: any, theValueType: string = ""){
  if(!theValue || theValue.toString()===""){
    return theValue;
  }
  if(theValueType === "string"){
    return theValue.toString();
  }
  if(theValueType === "number"){
    return Number(theValue);
  }
  return theValue;
}

export function compare_value_for_ordering(valueA:number|null, valueB:number|null):number{
  //console.log("compare_value_for_ordering(=>",valueA,",=>",valueB,"id:");
  if(!valueA){
    return -1;
  }
  if(!valueB){
    return 1;
  }
  if(valueA < valueB){
    return -1;
  }
  if(valueA > valueB){
    return 1;
  }
  return 0;
}

export function return_text_true_if_a_equal_b_else_text_false(a:string, b:string, text_true:string, text_false:string):string{
  if (a === b) {
    return text_true;
  }
  return text_false;
}

export function isBtnSelected(current: string | undefined, trigger: string) {
  if(!current){return "";}
  return return_text_true_if_a_equal_b_else_text_false(current, trigger, "btn-selected", "")
}
