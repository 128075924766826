import { FunctionComponent, useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { return_logo_from_type } from "../helpers/station-helper";
import AuthenticationService from "../services/authentication-service";

type NavbarProps = {
  thepath: string;
};

const SquamaNavBar: FunctionComponent<NavbarProps> = ({ thepath }) => {
  const displayName = "SquamaNavBar:";
  const enableDebug = false;
  const [displayRender, setDisplayRender] = useState("");
  const base_page_path = thepath.replace("/map", "").replace("/list", "");

  useEffect(() => {
    if (thepath.includes("/map")) {
      setDisplayRender("map");
    } else if (thepath.includes("/list")) {
      setDisplayRender("list");
    } else if (thepath.includes("/companies/")) {
      setDisplayRender("companies");
    } else {
      setDisplayRender("none");
    }
  }, [thepath]);

  function not_connected_dashboard() {
    return (
      <div className="row-navbar">
        <div className="squama-item-navbar">
          <Link to="/accueil">
            <button type="button" className="squama-btn-navbar">
              Accueil
            </button>
          </Link>
        </div>
        <div className="squama-item-navbar">
          <Link to="/solutions">
            <button type="button" className="squama-btn-navbar">
              Solutions
            </button>
          </Link>
        </div>
        <div className="squama-item-navbar">
          <Link to="/apropos">
            <button type="button" className="squama-btn-navbar">
              À propos
            </button>
          </Link>
        </div>
        <div className="squama-item-navbar">
          <Link to="/contact">
            <button type="button" className="squama-btn-navbar">
              Contact
            </button>
          </Link>
        </div>
        <div className="squama-item-navbar">
          <Link to="/login">
            <button type="button" className="squama-btn-navbar">
              Accès/Démo
            </button>
          </Link>
        </div>
      </div>
    );
  }

  function displayConditionnalButton(
    displayRenderValue: string,
    logoName: string,
    linkSuffix: string
  ) {
    if (displayRender === "none") {
      return (
        <div>
          {return_logo_from_type(
            logoName,
            "logo-sensor-2-3-size-png logo-opacity"
          )}
        </div>
      );
    }
    if (displayRender !== displayRenderValue) {
      return (
        <Link to={base_page_path + linkSuffix}>
          {return_logo_from_type(
            logoName,
            "logo-sensor-2-3-size-png logo-opacity"
          )}
        </Link>
      );
    }
    return (
      <div>{return_logo_from_type(logoName, "logo-sensor-2-3-size-png")}</div>
    );
  }

  function connected_dashboard() {
    return (
      <div className="row-navbar">
        <div className="row-navbar">
          {current_user_is_god && displayRender !== "" && (
            <Fragment>
              {displayConditionnalButton("companies", "dashboard", "")}
              {displayConditionnalButton("list", "list", "/list")}
              {displayConditionnalButton("map", "map", "/map")}
            </Fragment>
          )}
        </div>
        <div className="row-navbar">
          <div className="squama-item-navbar">
            <Link to="/dashboard">
              <button type="button" className="squama-btn-navbar">
                Tableau de bord
              </button>
            </Link>
          </div>
          <div className="squama-item-navbar">
            <Link to="/parameters">
              <button type="button" className="squama-btn-navbar">
                Paramètres
              </button>
            </Link>
          </div>
          <div className="squama-item-navbar">
            <Link to="/contactconnected">
              <button type="button" className="squama-btn-navbar">
                Contact
              </button>
            </Link>
          </div>
          {current_user_is_god && (
            <div className="squama-item-navbar">
              <Link to="/gestion">
                <button type="button" className="squama-btn-navbar">
                  Gestion
                </button>
              </Link>
            </div>
          )}
        </div>
        <div className="row-navbar">
          <Link to="/logout">
            {return_logo_from_type("logout-oblon", "logo-oblon-navbar-png")}
          </Link>
        </div>
      </div>
    );
  }
  let current_user_is_god = AuthenticationService.getUserIsGod();
  enableDebug &&
    console.log(displayName, "current_user_is_god:" + current_user_is_god);
  enableDebug && console.log(displayName, "thepath=" + thepath);

  if (
    thepath === "/dashboard" ||
    thepath === "/parameters" ||
    thepath === "/contactconnected" ||
    thepath === "/gestion" ||
    thepath === "/map" ||
    thepath.includes("/stations/") ||
    thepath.includes("/companies/")
  ) {
    return connected_dashboard();
  } else {
    return not_connected_dashboard();
  }
};

export default SquamaNavBar;
