import AnalogSensor from "../models/analog-sensor";
import Station from "../models/station";
import FaultTrigger from "../models/fault-trigger";
import TextSensor from "../models/text-sensor";
import WaterLevelSensor from "../models/water-level-sensor";

import MyWaterLevelSensorLogo from "../img/water_level_sensor_logo-700x700.png";
import MyWaterLevelSensorDirtyLogo from "../img/water_level_sensor_dirty_logo-700x700.png";
import MyWaterLevelSensorSelectedLogo from "../img/water_level_sensor_selected_logo-700x700.png";
import MyWaterLevelSensorOverWaterLogo from "../img/waterlevel_over_water-700x350.png";
import MyWaterLevelSensorUnderWaterLogo from "../img/waterlevel_under_water-700x350.png";
import MyMigrationSelectInProgressLogo from "../img/migration_in_progress-700x350.png";
import MyMigrationSelectNotInProgressLogo from "../img/migration_not_in_progress-700x350.png";
import MyTemperatureSensorLogo from "../img/temperature_sensor_logo-700x700.png";
import MyTemperatureSensorSelectedLogo from "../img/temperature_sensor_selected_logo-700x700.png";
import MyOxymeterSensorLogo from "../img/oxymeter_sensor_logo-700x700.png";
import MyOxymeterSensorSelectedLogo from "../img/oxymeter_sensor_selected_logo-700x700.png";
import MyTurbiditySensorLogo from "../img/turbidity_sensor_logo-700x700.png";
import MyTurbiditySensorSelectedLogo from "../img/turbidity_sensor_selected_logo-700x700.png";
import MyMigrationLogo from "../img/migration-700x700.png";
import MyEmbacleLogo from "../img/embacle-700x700.png";
import MyEyeLogo from "../img/eye-700x700.png";
import MyContactLogo from "../img/adresse_logo-700x700.png";
import MyAlimentationLogo from "../img/alimentation_logo-700x700.png";
import MyBattery10Logo from "../img/batterie_10_100-60x60.png";
import MyBattery25Logo from "../img/batterie_25_100-60x60.png";
import MyBattery50Logo from "../img/batterie_50_100-60x60.png";
import MyBattery75Logo from "../img/batterie_75_100-60x60.png";
import MyBattery100Logo from "../img/batterie_100_100-60x60.png";
import MyAllAnalogLogo from "../img/all_analog_sensors-700x700.png";
import MySetupLogo from "../img/setup_logo-700x700.png";
import MyBellLogo from "../img/bell_logo-700x700.png";
import MyChartLogo from "../img/chart_logo-700x700.png";
import MyDownloadLogo from "../img/download_logo-700x700.png";
import MyNoSignalLogo from "../img/nosignal-700x700.png";
import MyTransmitterLogo from "../img/sonde_connected_logo-614x614.png";
import MyTransmitterSelectedLogo from "../img/sonde_connected_selected_logo-614x614.png";
import MyBatteryLogo from "../img/batterie_pourcent_logo-700x700.png";
import MyBatterySelectedLogo from "../img/batterie_pourcent-selected_logo-700x700.png";
import MyLQIExcellentLogo from "../img/lqi_excellent-30x30.png";
import MyLQIGoodLogo from "../img/lqi_good-30x30.png";
import MyLQIAverageLogo from "../img/lqi_medium-30x30.png";
import MyLQILimitLogo from "../img/lqi_few-30x30.png";
import MyLQINaLogo from "../img/lqi_no-30x30.png";
import MyTargetLogo from "../img/target-720x720.png";
import MyMaintenanceLogo from "../img/maintenance-700x700.png";
import MyModulableLogo from "../img/modulable-700x700.png";
import MyEchangesLogo from "../img/echanges-700x700.png";
import MyPapLogo from "../img/pap_logo-700x700.png";
import MyPapSelectedLogo from "../img/pap-selected_logo-702x702.png";
import MyVigeelLogo from "../img/papang_logo-700x700.png";
import MyVigeelSelectedLogo from "../img/papang-selected_logo-702x702.png";
import MyWifiLogo from "../img/wifi-600x356.png";
import Transmitter from "../models/transmitter";
import FileLogo from "../img/file-700x700.png";
import MyChargerLogo from "../img/charger_logo-700x700.png";
import MyChargerSelectedLogo from "../img/charger-selected_logo-700x700.png";
import MyCapaLogo from "../img/capa_logo-700x700.png";
import MyCapaSelectedLogo from "../img/capa-selected_logo-700x700.png";
import MyPressionLogo from "../img/pression_logo-700x700.png";
import MyPressionSelectedLogo from "../img/pression-selected_logo-700x700.png";
import MyBatteryMvLogo from "../img/batterie_logo-700x700.png";
import MyBatteryMvSelectedLogo from "../img/batterie-selected_logo-700x700.png";
import MyDataLogo from "../img/data_logo-700x700.png";
import MyDataSelectedLogo from "../img/data-selected_logo-700x700.png";
import MyTextLogo from "../img/text_logo-700x700.png";
import MyTextSelectedLogo from "../img/text-selected_logo-700x700.png";
import MyVirginLogo from "../img/virgin_logo-700x700.png";
import MyVirginSelectedLogo from "../img/virgin-selected_logo-700x700.png";
import UnselectedCheckboxGreenRound from "../img/unselected_checkbox_green_round_logo-700x700.png";
import SelectedCheckboxGreenRound from "../img/selected_checkbox_green_round_logo-702x702.png";
import UnselectedCheckboxOrangeRound from "../img/unselected_checkbox_orange_round_logo-700x700.png";
import SelectedCheckboxOrangeRound from "../img/selected_checkbox_orange_round_logo-702x702.png";
import UnselectedCheckboxRedRound from "../img/unselected_checkbox_red_round_logo-700x700.png";
import SelectedCheckboxRedRound from "../img/selected_checkbox_red_round_logo-702x702.png";
import UnselectedCheckboxGreyRound from "../img/unselected_checkbox_grey_round_logo-700x700.png";
import SelectedCheckboxGreyRound from "../img/selected_checkbox_grey_round_logo-702x702.png";
import MyDashboardLogo from "../img/dashboard_logo-702x702.png";
import MyLogoutLogo from "../img/logout_logo-702x702.png";
import MyMapLogo from "../img/map_logo-702x702.png";
import MyLogoutOblonLogo from "../img/logout-oblon-logo-700x350.png";
import DashboardSelectedMapGreyed from "../img/dashboard-selected_map-greyed-700x350.png";
import DashboardGreyedMapSelected from "../img/dashboard-greyed_map-selected-700x350.png";
import LoaderLogo from "../img/loader-661x661.gif";
import MyStationInfoLogo from "../img/station-info_logo-700x700.png";
import MyArrowUpLogo from "../img/arrow-up_logo-700x700.png";
import MyArrowDownLogo from "../img/arrow-down_logo-700x700.png";
import MyNoEmissionCapteurLogo from "../img/no-emission-capteur_logo-700x700.png";
import MyNoTemperatureCapteurLogo from "../img/no-temperature-capteur_logo-700x700.png";
import My2GLogo from "../img/2g-600x356.png";
import MyPKDLogo from "../img/pkd-700x700.png";
import PKDAlarmLogo from "../img/pkd-alarm-700x700.png";
import BpOblon from "../img/bp_oblon_logo-700x350.png";
import BpOblonSelected from "../img/bp_oblon-selected_logo-700x350.png";
import MyListLogo from "../img/list_logo-702x702.png";
import MyLiquidLevelLogo from "../img/liquid_level_logo-700x700.png";
import MyLiquidLevelSelectedLogo from "../img/liquid_level-selected_logo-700x700.png";
import MyMonitoringLogo from "../img/monitoring_logo-700x700.png";
import MyTriggerHighLogo from "../img/bp_oblon_trigger_high_logo-700x350.png";
import MyTriggerLowLogo from "../img/bp_oblon_trigger_low_logo-700x350.png";
import MyhelpOnLogo from "../img/bp_oblon_help_on_logo-700x350.png";
import MyhelpOffLogo from "../img/bp_oblon_help_off_logo-700x350.png";
import { LatLngExpression } from "leaflet";
import Fault from "../models/fault";
import { DEFAUT_TRANSMITTER_TRIGGER_TYPE_MISSING_CAPTEUR } from "../models/defaut";

export function get_all_typed_analog_sensors_from_station(
  station: Station,
  theType: string
): Array<AnalogSensor> | undefined {
  return (
    station.transmitters?.flatMap(
      (transmitter) =>
        transmitter.analog_sensors?.filter(
          (sensor) => sensor.type === theType
        ) || []
    ) || undefined
  );
}

export function get_all_analog_sensors_from_station(
  station: Station
): Array<AnalogSensor> | undefined {
  return (
    station.transmitters?.flatMap(
      (transmitter) => transmitter.analog_sensors || []
    ) || undefined
  );
}

export function get_all_water_level_sensors_from_station(
  station: Station
): Array<WaterLevelSensor> | undefined {
  return (
    station.transmitters?.flatMap(
      (transmitter) => transmitter.water_level_sensors || []
    ) || undefined
  );
}

export function get_all_text_sensors_from_station(
  station: Station
): Array<TextSensor> | undefined {
  return (
    station.transmitters?.flatMap(
      (transmitter) => transmitter.text_sensors || []
    ) || undefined
  );
}

export function get_all_typed_water_level_sensors_from_station(
  station: Station,
  theType: string
): Array<WaterLevelSensor> | undefined {
  return station.transmitters?.flatMap(
    (transmitter) =>
      transmitter.water_level_sensors?.filter(
        (sensor) => sensor.type === theType
      ) || undefined
  );
}

export function get_number_of_sensors_for_a_transmitter(
  transmitter: Transmitter
): number {
  let count: number = 0;
  if (transmitter.analog_sensors !== undefined) {
    count += transmitter.analog_sensors.length;
  }
  if (transmitter.water_level_sensors !== undefined) {
    count += transmitter.water_level_sensors.length;
  }
  return count;
}

export function get_number_of_sensors_for_a_station(station: Station): number {
  let count: number = 0;
  let analog_sensors = get_all_analog_sensors_from_station(station);
  let water_level_sensors = get_all_water_level_sensors_from_station(station);
  if (analog_sensors !== undefined) {
    count += analog_sensors.length;
  }
  if (water_level_sensors !== undefined) {
    count += water_level_sensors.length;
  }
  return count;
}

export function find_water_level_sensor_from_sensor_id(
  station: Station,
  sensorId: number
): WaterLevelSensor | undefined {
  const waterLevelSensors = get_all_water_level_sensors_from_station(station);
  return waterLevelSensors?.find((sensor) => sensor.id === sensorId);
}

export function find_analog_sensor_from_sensor_id(
  station: Station,
  sensorId: number
): AnalogSensor | undefined {
  const analogSensors = get_all_analog_sensors_from_station(station);
  return analogSensors?.find((sensor) => sensor.id === sensorId);
}

export function is_transmitter_type_module_gprs_or_wifi(
  transmitter_type: string
):boolean {
  const module_gprs_type = ["LTE-M", "NB-IOT", "2G", "GSM", "GPRS"];
  const module_wifi_type = ["WIFI"];
  if (transmitter_type) {
    if (
      module_gprs_type.includes(transmitter_type) ||
      module_wifi_type.includes(transmitter_type)
    ) {
      return true;
    }
  }
  return false;
}

export function is_transmitter_module_gprs_or_wifi(transmitter: Transmitter):boolean {
  if (transmitter) {
    return is_transmitter_type_module_gprs_or_wifi(transmitter.type);
  }
  return false;
}

export function is_station_type_string_as_assec(station_type: string): boolean {
  if (
    station_type === "thermeau" ||
    station_type === "flow" ||
    station_type === "assec"
  ) {
    return true;
  }
  return false;
}

export function is_station_assec(station: Station) {
  if (station && is_station_type_string_as_assec(station.type)) {
    return true;
  }
  return false;
}

export function is_station_thermeau(station: Station) {
  return is_station_assec(station);
}

export function is_station_pap(station: Station) {
  if (station.type === "pap") {
    return true;
  }
  return false;
}

export function is_station_papang(station: Station) {
  if (station.type === "papang") {
    return true;
  }
  return false;
}

export function is_station_flow(station: Station) {
  if (station.type === "flow") {
    return true;
  }
  return false;
}

export function is_station_flhauteur(station: Station) {
  if (station.type === "flhauteur") {
    return true;
  }
  return false;
}

export function return_battery_logo_from_level(
  level: number,
  classused: string
) {
  if (level <= 10) {
    return <img className={classused} src={MyBattery10Logo} alt="" />;
  } else if (level <= 25) {
    return <img className={classused} src={MyBattery25Logo} alt="" />;
  } else if (level <= 50) {
    return <img className={classused} src={MyBattery50Logo} alt="" />;
  } else if (level <= 75) {
    return <img className={classused} src={MyBattery75Logo} alt="" />;
  } else {
    return <img className={classused} src={MyBattery100Logo} alt="" />;
  }
}

export function return_type_test(type: string) {
  if (type === "oxymeter") {
    return "Oxygène";
  } else if (type === "turbidity") {
    return "Turbidité";
  } else if (type === "temperature") {
    return "Température";
  } else if (type === "contact") {
    return "Contacts";
  } else if (type === "embacle") {
    return "Embâcles";
  } else if (type === "alimentation") {
    return "Alimentation";
  } else if (type === "water_level") {
    return "Niveau d'eau";
  } else if (type === "liquid_level") {
    return "Hauteur d'eau";
  } else if (type === "charger") {
    return "Chargeur";
  } else if (type === "charging") {
    return "En Charge";
  } else if (type === "pression") {
    return "Pression";
  } else if (type === "capa") {
    return "Capacitif";
  } else if (type === "uptime") {
    return "Temps avant émission";
  }
  return "";
}

export function return_lqi_logo_from_transmitter(
  transmitter: Transmitter,
  classused: string
) {
  let level = "Na";
  if (transmitter && transmitter.lqi) {
    if (transmitter.type.toUpperCase() === "SIGFOX") {
      return return_logo_from_type(
        transmitter.lqi ? "lqi-" + transmitter.lqi : "lqi-Na",
        classused
      );
    } else if (transmitter.type.toUpperCase() === "WIFI") {
      if (transmitter.lqi) {
        if (Number(transmitter.lqi) < -90) {
          level = "Limit";
        } else if (Number(transmitter.lqi) < -67) {
          level = "Average";
        } else if (Number(transmitter.lqi) < -55) {
          level = "Good";
        } else if (Number(transmitter.lqi) < -1) {
          level = "Excellent";
        }
      }
    } else {
      if (transmitter.lqi) {
        if (Number(transmitter.lqi) < 3) {
          level = "Limit";
        } else if (Number(transmitter.lqi) < 7) {
          level = "Average";
        } else if (Number(transmitter.lqi) < 15) {
          level = "Good";
        } else if (Number(transmitter.lqi) < 32) {
          level = "Excellent";
        }
      }
    }
  }
  return return_logo_from_type("lqi-" + level, classused);
}
export function return_logo_from_type(type: string, classused: string) {
  const text_logo_array = [
    "operator",
    "conn_type",
    "presence_message",
    "fw_version",
    "send_sample_update",
    "temp_mask_update",
    "modem_type_update",
    "psm",
    "error",
    "info",
    "sent_data",
    "wifi_pass_1_update",
    "wifi_ssid_1_update",
    "wifi_pass_2_update",
    "wifi_ssid_2_update",
    "gprs_big_data_update",
    "force_gsm_update",
    "allow_nbiot_update",
    "rs_squama_on_update",
    "rs_modbus_on_update",
    "my_operator_update",
    "always_attach_update",
    "allow_2g_ota_update",
  ];
  const capa_logo_array = ["capa_wl1", "capa_wl2", "capa_wlref"];
  if (type === "water_level" || type === "general" || type === "flow") {
    return <img className={classused} src={MyWaterLevelSensorLogo} alt="" />;
  } else if (type === "water_level_dirty") {
    return (
      <img className={classused} src={MyWaterLevelSensorDirtyLogo} alt="" />
    );
  } else if (type === "water_level-selected" || type === "flow-selected") {
    return (
      <img className={classused} src={MyWaterLevelSensorSelectedLogo} alt="" />
    );
  } else if (type === "migration_select_in_progress") {
    return (
      <img className={classused} src={MyMigrationSelectInProgressLogo} alt="" />
    );
  } else if (type === "migration_select_not_in_progress") {
    return (
      <img
        className={classused}
        src={MyMigrationSelectNotInProgressLogo}
        alt=""
      />
    );
  } else if (type === "water_level-over_water") {
    return (
      <img className={classused} src={MyWaterLevelSensorOverWaterLogo} alt="" />
    );
  } else if (type === "water_level-under_water") {
    return (
      <img
        className={classused}
        src={MyWaterLevelSensorUnderWaterLogo}
        alt=""
      />
    );
  } else if (
    type === "temperature" ||
    type === "modem_temperature" ||
    type === "thermeau" ||
    type === "bis_temperature"
  ) {
    return <img className={classused} src={MyTemperatureSensorLogo} alt="" />;
  } else if (
    type === "temperature-selected" ||
    type === "modem_temperature-selected" ||
    type === "thermeau-selected" ||
    type === "bis_temperature-selected"
  ) {
    return (
      <img className={classused} src={MyTemperatureSensorSelectedLogo} alt="" />
    );
  } else if (type === "liquid_level" || type === "flhauteur") {
    return <img className={classused} src={MyLiquidLevelLogo} alt="" />;
  } else if (
    type === "liquid_level-selected" ||
    type === "flhauteur-selected"
  ) {
    return <img className={classused} src={MyLiquidLevelSelectedLogo} alt="" />;
  } else if (type === "oxymeter") {
    return <img className={classused} src={MyOxymeterSensorLogo} alt="" />;
  } else if (type === "oxymeter-selected") {
    return (
      <img className={classused} src={MyOxymeterSensorSelectedLogo} alt="" />
    );
  } else if (type === "turbidity") {
    return <img className={classused} src={MyTurbiditySensorLogo} alt="" />;
  } else if (type === "turbidity-selected") {
    return (
      <img className={classused} src={MyTurbiditySensorSelectedLogo} alt="" />
    );
  } else if (type === "contact") {
    return <img className={classused} src={MyContactLogo} alt="" />;
  } else if (type === "migration") {
    return <img className={classused} src={MyMigrationLogo} alt="" />;
  } else if (type === "embacle" || type === "downstream") {
    return <img className={classused} src={MyEmbacleLogo} alt="" />;
  } else if (type === "eye") {
    return <img className={classused} src={MyEyeLogo} alt="" />;
  } else if (type === "alimentation" || type === "upstream") {
    return <img className={classused} src={MyAlimentationLogo} alt="" />;
  } else if (type === "all_analog") {
    return <img className={classused} src={MyAllAnalogLogo} alt="" />;
  } else if (type === "setup") {
    return <img className={classused} src={MySetupLogo} alt="" />;
  } else if (type === "alarm") {
    return <img className={classused} src={MyBellLogo} alt="" />;
  } else if (type === "display") {
    return <img className={classused} src={MyChartLogo} alt="" />;
  } else if (type === "download") {
    return <img className={classused} src={MyDownloadLogo} alt="" />;
  } else if (type === "nosignal") {
    return <img className={classused} src={MyNoSignalLogo} alt="" />;
  } else if (
    type === "transmitter" ||
    type === "uptime" ||
    type === "cells" ||
    type === "operators_around" ||
    type === "all_operators_around" ||
    type === "all_wifi_around"
  ) {
    return <img className={classused} src={MyTransmitterLogo} alt="" />;
  } else if (
    type === "transmitter-selected" ||
    type === "uptime-selected" ||
    type === "cells-selected" ||
    type === "operators_around-selected" ||
    type === "all_operators_around-selected" ||
    type === "all_wifi_around-selected"
  ) {
    return <img className={classused} src={MyTransmitterSelectedLogo} alt="" />;
  } else if (type === "battery") {
    return <img className={classused} src={MyBatteryLogo} alt="" />;
  } else if (type === "battery-selected") {
    return <img className={classused} src={MyBatterySelectedLogo} alt="" />;
  } else if (type === "lqi-Excellent") {
    return <img className={classused} src={MyLQIExcellentLogo} alt="" />;
  } else if (type === "lqi-Good") {
    return <img className={classused} src={MyLQIGoodLogo} alt="" />;
  } else if (type === "lqi-Average") {
    return <img className={classused} src={MyLQIAverageLogo} alt="" />;
  } else if (type === "lqi-Limit") {
    return <img className={classused} src={MyLQILimitLogo} alt="" />;
  } else if (type === "lqi-Na") {
    return <img className={classused} src={MyLQINaLogo} alt="" />;
  } else if (type === "target") {
    return <img className={classused} src={MyTargetLogo} alt="" />;
  } else if (type === "maintenance") {
    return <img className={classused} src={MyMaintenanceLogo} alt="" />;
  } else if (type === "modulable") {
    return <img className={classused} src={MyModulableLogo} alt="" />;
  } else if (type === "echanges") {
    return <img className={classused} src={MyEchangesLogo} alt="" />;
  } else if (type === "pap") {
    return <img className={classused} src={MyPapLogo} alt="" />;
  } else if (type === "pap-selected") {
    return <img className={classused} src={MyPapSelectedLogo} alt="" />;
  } else if (type === "vigeel") {
    return <img className={classused} src={MyVigeelLogo} alt="" />;
  } else if (type === "vigeel-selected") {
    return <img className={classused} src={MyVigeelSelectedLogo} alt="" />;
  } else if (type === "wifi") {
    return <img className={classused} src={MyWifiLogo} alt="" />;
  } else if (type === "file") {
    return <img className={classused} src={FileLogo} alt="" />;
  } else if (type === "charger" || type === "charging") {
    return <img className={classused} src={MyChargerLogo} alt="" />;
  } else if (type === "charger-selected" || type === "charging-selected") {
    return <img className={classused} src={MyChargerSelectedLogo} alt="" />;
  } else if (type === "pression") {
    return <img className={classused} src={MyPressionLogo} alt="" />;
  } else if (type === "pression-selected") {
    return <img className={classused} src={MyPressionSelectedLogo} alt="" />;
  } else if (capa_logo_array.includes(type)) {
    return <img className={classused} src={MyCapaLogo} alt="" />;
  } else if (capa_logo_array.includes(type.replace("-selected", ""))) {
    return <img className={classused} src={MyCapaSelectedLogo} alt="" />;
  } else if (type === "battery_mv" || type === "capteur_vin_mv") {
    return <img className={classused} src={MyBatteryMvLogo} alt="" />;
  } else if (
    type === "battery_mv-selected" ||
    type === "capteur_vin_mv-selected"
  ) {
    return <img className={classused} src={MyBatteryMvSelectedLogo} alt="" />;
  } else if (type === "data") {
    return <img className={classused} src={MyDataLogo} alt="" />;
  } else if (type === "data-selected") {
    return <img className={classused} src={MyDataSelectedLogo} alt="" />;
  } else if (type === "virgin") {
    return <img className={classused} src={MyVirginLogo} alt="" />;
  } else if (type === "virgin-selected") {
    return <img className={classused} src={MyVirginSelectedLogo} alt="" />;
  } else if (text_logo_array.includes(type)) {
    return <img className={classused} src={MyTextLogo} alt="" />;
  } else if (text_logo_array.includes(type.replace("-selected", ""))) {
    return <img className={classused} src={MyTextSelectedLogo} alt="" />;
  } else if (type === "round_ok") {
    return (
      <img className={classused} src={UnselectedCheckboxGreenRound} alt="" />
    );
  } else if (type === "round_ok-selected") {
    return (
      <img className={classused} src={SelectedCheckboxGreenRound} alt="" />
    );
  } else if (type === "round_ko") {
    return (
      <img className={classused} src={UnselectedCheckboxGreyRound} alt="" />
    );
  } else if (type === "round_ko-selected") {
    return <img className={classused} src={SelectedCheckboxGreyRound} alt="" />;
  } else if (type === "round_warning") {
    return (
      <img className={classused} src={UnselectedCheckboxOrangeRound} alt="" />
    );
  } else if (type === "round_warning-selected") {
    return (
      <img className={classused} src={SelectedCheckboxOrangeRound} alt="" />
    );
  } else if (type === "round_alarm") {
    return (
      <img className={classused} src={UnselectedCheckboxRedRound} alt="" />
    );
  } else if (type === "round_alarm-selected") {
    return <img className={classused} src={SelectedCheckboxRedRound} alt="" />;
  } else if (type === "dashboard") {
    return <img className={classused} src={MyDashboardLogo} alt="" />;
  } else if (type === "logout") {
    return <img className={classused} src={MyLogoutLogo} alt="" />;
  } else if (type === "logout-oblon") {
    return <img className={classused} src={MyLogoutOblonLogo} alt="" />;
  } else if (type === "map") {
    return <img className={classused} src={MyMapLogo} alt="" />;
  } else if (type === "dashboard-selected_map-greyed") {
    return (
      <img className={classused} src={DashboardSelectedMapGreyed} alt="" />
    );
  } else if (type === "dashboard-greyed_map-selected") {
    return (
      <img className={classused} src={DashboardGreyedMapSelected} alt="" />
    );
  } else if (type === "loader") {
    return <img className={classused} src={LoaderLogo} alt="" />;
  } else if (type === "station_info") {
    return <img className={classused} src={MyStationInfoLogo} alt="" />;
  } else if (type === "arrow_up") {
    return <img className={classused} src={MyArrowUpLogo} alt="" />;
  } else if (type === "arrow_down") {
    return <img className={classused} src={MyArrowDownLogo} alt="" />;
  } else if (type === "no_emission_capteur") {
    return <img className={classused} src={MyNoEmissionCapteurLogo} alt="" />;
  } else if (type === "no_temperature_capteur") {
    return (
      <img className={classused} src={MyNoTemperatureCapteurLogo} alt="" />
    );
  } else if (type === "2g") {
    return <img className={classused} src={My2GLogo} alt="" />;
  } else if (type === "pkd") {
    return <img className={classused} src={MyPKDLogo} alt="" />;
  } else if (type === "pkd_alarm") {
    return <img className={classused} src={PKDAlarmLogo} alt="" />;
  } else if (type === "bp-oblon") {
    return <img className={classused} src={BpOblon} alt="" />;
  } else if (type === "bp-oblon-selected") {
    return <img className={classused} src={BpOblonSelected} alt="" />;
  } else if (type === "list") {
    return <img className={classused} src={MyListLogo} alt="" />;
  } else if (type === "monitoring") {
    return <img className={classused} src={MyMonitoringLogo} alt="" />;
  } else if (type === "trigger_high") {
    return <img className={classused} src={MyTriggerHighLogo} alt="" />;
  } else if (type === "trigger_low") {
    return <img className={classused} src={MyTriggerLowLogo} alt="" />;
  } else if (type === "help_on") {
    return <img className={classused} src={MyhelpOnLogo} alt="" />;
  } else if (type === "help_off") {
    return <img className={classused} src={MyhelpOffLogo} alt="" />;
  } else {
    console.log("unknown type:" + type);
    return <img className={classused} src={MyTurbiditySensorLogo} alt="" />;
  }
}

export function translate_water_level_type(type: string) {
  if (type === "upstream") {
    return "Amont";
  } else if (type === "downstream") {
    return "Aval";
  } else {
    return "Général";
  }
}

export function return_color_for_water_level_from_bool(one: boolean) {
  if (one) {
    return "blue";
  } else {
    return "grey";
  }
}

export function return_color_from_bool(one: boolean) {
  if (one) {
    return "green";
  } else {
    return "red";
  }
}

export function return_unity_from_type(type: string) {
  if (
    type === "temperature" ||
    type === "modem_temperature" ||
    type === "bis_temperature"
  ) {
    return "°C";
  } else if (type === "battery") {
    return "%";
  } else if (type === "sentaday") {
    return "envoi/j";
  } else if (type === "number") {
    return "nb";
  } else if (type === "oxymeter") {
    return "mg/l";
  } else if (type === "none") {
    return "";
  } else if (
    type === "charger" ||
    type === "battery_mv" ||
    type === "capteur_vin_mv"
  ) {
    return "mv";
  } else if (type === "pression") {
    return "mbar";
  } else if (type === "capa") {
    return "F";
  } else if (type === "liquid_level") {
    return "cm";
  } else if (type === "charging") {
    return "on/off";
  } else {
    return "NTU";
  }
}

export function is_there_sensor_in_array(
  sensors: Array<AnalogSensor> | undefined | Array<WaterLevelSensor> | undefined
) {
  if (!sensors) return false;
  if (sensors.length <= 0) return false;
  return true;
}

export function limit_element_name(element: string) {
  return element.slice(0, 17);
}

export function oldfw_show_all() {
  return "\
1.0.0 : <br/>\
2.0.0 : <br/>\
";
}

export function fw_capteur_show_all() {
  return "\
3.3.8 : <br/>\
  - Lecture de 8 capteurs en 2.5sec au lieu de 10.5sec=>Moins de debug sur port série + optimisations<br/>\
3.3.9 :<br/>\
  - N'attends qu'1sec au lieu de 2sec en fin queue des capteurs<br/>\
  - Peut ajouter sa version au message si master_presence positionne le bit REQUEST_MESSAGE_ADD_VERSIONS<br/>\
3.3.10 :<br/>\
  - Consommation divisée par 2 (14->7mA)<br/>\
3.3.11 :<br/>\
  - Envoi de l'UUID/version<br/>\
3.3.9 :<br/>\
  - Will only wait 1sec instead of 2sec at capteur tail<br/>\
  - Can add version into message if master_presence have bit REQUEST_MESSAGE_ADD_VERSIONS<br/>\
3.3.10 :<br/>\
  - Divide by two consumption lowing frequency and adjusting I2C timings<br/>\
3.3.11 :<br/>\
  - Support M_V_2<br/>\
  - Send UUID/Version of each capteur depending on temp12 val<br/>\
3.4.0 :<br/>\
  - Support new 4.0(STM32G030) ID0 -> 0<br/>\
  - Add support for tmp117<br/>\
  - Add support for MS5837_30BA pressure sensor<br/>\
  - Add support for IDs<br/>\
  - Check capteur is fully programmed<br/>\
3.4.1 :<br/>\
  - Do CRC checking on TSYS01<br/>\
  - Add VIN read on A0 for new 4.0(STM32G030) when REQUEST_MESSAGE_ADD_VIN_MV in request<br/>\
3.4.2 :<br/>\
  - Support Pressure sensor HW version(even STM32G071)<br/>\
  - Update HW version for sensors<br/>\
  - Remove verification of fully programmed capteur since we finish by the beginning of Flash<br/>\
  - If shortcut RS do infinite loop to not answer anything<br/>\
3.4.3 :<br/>\
  - RS485 message number in RS485 increased from 20 to 50<br/>\
  - Handle pressure_temperature from pressure sensor<br/>\
  - Handle bis_temperature_{1/12}<br/>\
  - Send pressure value only with correct values (MIN_PRESSURE_ALLOWED;MAX_PRESSURE_ALLOWED)<br/>\
";
}

export function fw_transmitteur_show_all() {
  return "\
4.0.19 :<br/>\
 - Migrate to IDF using arduino as a component to espressif@6.5.0<br/>\
 - Fix troubles when flashing with espressif@6.5.0 (was working with espressif@6.1.0)<br/>\
 - Fix a lot of warnings and potential errors (Thanks to IDF migration)<br/>\
 - sdkconfig.defaults : Set boot frequency to 40MHz; Update brownout level from 7 to 3 (2.44v to 2.98v)(level 7->2 (2.44v to 3.19)was getting too much boot brownout failure)<br/>\
4.0.20 :<br/>\
 - Update Battery/send/sample management<br/>\
 - Add force_attach (default 0) to ask modem to do a force conenction procedure<br/>\
 - Add always_attach (default 0) to ask modem to always force conenction procedure<br/>\
 - Add allow_nbiot (default 0 (modified to 1 in 4.0.22)) to allow modem to connect in NBIOT<br/>\
 - Do force attach when wake up by ILS<br/>\
 - Stop when NTP fail to connect to network<br/>\
 - Start sleeping from end time instead of starting time when a connection issue occured<br/>\
4.0.21 :<br/>\
 - Force NOT ALLOW_PROGRAMMATEUR && NOT ALLOW_INDUSTRIAL_PROGRAMMATEUR (BP too close to case and pressed)<br/>\
4.0.22 :<br/>\
 - Modify allow_nbiot (default value to 1) => Because 2G/GSM seems to need more time to attach<br/>\
 - ILS = 35 => Industrial Programmateur mode (was 30 in previous software)<br/>\
 - ILS = 30 =>Force cycle in WiFi<br/>\
 - Improve GSM connectivity + NTP sync<br/>\
 - Fix sleep_n_min > to number of minutes already spent in loop<br/>\
 - Add watchdog : During loop, and in parallel of alarm (+15min, or +1h)<br/>\
 - Improve GPS downloading XTRA files for GPS+Galileo<br/>\
 - Add serial color<br/>\
4.0.23 :<br/>\
  - Add keep_sample (default 0) to ask to keep sample frequency even if battery is low<br/>\
4.0.24 :<br/>\
  - Add testeur mode ILS=21<br/>\
  - Refactore power_off to be used before any restart<br/>\
  - Improve interrupt handler in RAM<br/>\
  - WiFi connection handle when fail to connect (doing the same as GPRS)<br/>\
  - no_con_nb is used to count failing attempt to connect :<br/>\
  * Failing connect occured 1 time=>Send max 1/Hour<br/>\
  * Failing connect occured 2 times=>Send max 4 times a day<br/>\
  * Failing connect occured 3-4 times=>Send max 2 times a day<br/>\
  * Failing connect occured 4-8 times=>Send max 1 time a day<br/>\
  * Failing connect occured >8 times=>Send max 1 time a day in GSM<br/>\
  BUG with watchdog = 1H when (nb_minutes_to_sleep + 15)>=255 minutes (WHEN sample is asked over 2H delta)<br/>\
4.0.25 :<br/>\
  * FIX watchdog (WHEN sample is asked over 2H delta)<br/>\
  * watchdog reboot not consider as just_have_reset=> do not format data<br/>\
  * catch core_dump with guru meditation<br/>\
4.0.26 :<br/>\
  * Wake Up and send GPS when 5<=ILS<=9<br/>\
  * Ask to wake up at specific UTC time with {\"utc_wake_up_hour\":\"10\",\"utc_wake_up_minute\":\"15\"}<br/>\
  * Fix message sent when get_variables is asked<br/>\
4.0.27 :<br/>\
  * When OTA is finished say it ASAP<br/>\
  * Identify each request with v and device header, and User-Agent will be \"MAC\"/\"SOFTWARE_VERSION\"<br/>\
  * Fix troubles with bad content-size : Use file size instead of DATA_CONTENTS_SIZE_FILE<br/>\
4.0.28 :<br/>\
  * Add {'format_data':'1'} => Ask to format data partition(bandage in case of failing at saving data)<br/>\
  * Update double quote into quote in JSON<br/>\
  * Align STM32 protocol.{cpp,h} rs485.{cpp, h} files (add capacitive level sensor)<br/>\
  * Add support for microsensor pressure sensor<br/>\
4.0.29 :<br/>\
  * Fix get variables<br/>\
  * Imlement WDT : Check application ping each WDT_TIMEOUT_SEC period and WDT_TIMEOUT_SECx6 for WiFi and OTA<br/>\
  * Implement uptime_WDT : Check system is not up over MAX_UPTIME_WATCHDOG_IN_SEC sec else program wake up and poweroff<br/>\
  * Add 'charging':'0'<br/>\
  * Make sure on rs interface is active<br/>\
  * Add 'up':'...' time in sec until we start sending<br/>\
  * When asking version also send 'modem_version'<br/>\
4.0.30 :<br/>\
  * Improve serial<br/>\
  * Fix infinite WDT loop on 1st boot when formating<br/>\
  * {'get_cell':'1'} => Ask to get GSM/LTE Cells network<br/>\
  * Reformat json thanks to myj<br/>\
  * Hide credentials<br/>\
4.0.31 :<br/>\
  * Force attach when no_con_nb is not 0<br/>\
  * If unable to send, save some data to send later<br/>\
  * {'get_operators':'1'} => Ask to get operators with connected Access Technology (LTE-M/NB-IOT/GSM)<br/>\
  * {'get_all_operators':'1'} => Ask to get all operators with ALL Access Technology (LTE-M & NB-IOT & GSM)<br/>\
  * Fix ESP.restart() using in combination WDT<br/>\
  * Improve forcing BG95 WWAN priority<br/>\
  * Update get_cell to get_cells<br/>\
4.0.32 :<br/>\
  * {'get_wifi_around':'1'} => Ask to get all wifi around<br/>\
  * {'allow_2g_ota':'1'} => Allow OTA upgrade in 2G<br/>\
";
}

export function fw_nicely_displayed(version: number) {
  /*
   * if in DB we have 654321, it mean version 65.43.21
   */
  if (!version) {
    return "0.0.0";
  }
  let reference_table = version.toString().split("").reverse();
  let version_fix = Number(reference_table[0]);
  version_fix += Number(reference_table[1]) * 10;
  let version_minor = Number(reference_table[2]);
  version_minor += Number(reference_table[3]) * 10;
  let version_major = Number(reference_table[4]);
  if (reference_table.length > 5) {
    version_major += Number(reference_table[5]) * 10;
  }
  if (reference_table.length > 6) {
    version_major += Number(reference_table[6]) * 100;
  }
  return version_major + "." + version_minor + "." + version_fix;
  //return version.toString();
}

export function hw_capteur_show_all() {
  return "\
Consommation:<br/>\
  -STM32G071:14mA<br/>\
  -STM32G030:7mA<br/>\
Version:<br/>\
3 : STM32G030v2.91<br/>\
   Temperature sensor only in 1 connecteur sensor: TSYS01<br/>\
   ID0:1<br/>\
4 : STM32G030v4.0/4.1/4.2<br/>\
   Temperature sensor : TMP117<br/>\
   A0 Analog read of VIN<br/>\
   ID0:0<br/>\
5 : STM32G071v3.0.A/B<br/>\
   Temperature sensor : TSYS01<br/>\
   ID0:1<br/>\
   ID3:0<br/>\
6 : STM32G071v3.1<br/>\
   Temperature sensor : TSYS01<br/>\
   ID0:1<br/>\
   ID3:1<br/>\
7 : STM32G071v4.2<br/>\
   Temperature sensor : TMP117<br/>\
   A0 Analog read of VIN<br/>\
   ID0:0<br/>\
8 : STM32G030-PRESSUREv4.1/4.2<br/>\
   Temperature sensor : TMP117<br/>\
   A0 Analog read of VIN<br/>\
   Pressure Sensor MS5837<br/>\
   ID0:0<br/>\
9 : STM32G071-PRESSUREv4.2<br/>\
   Temperature sensor : TMP117<br/>\
   A0 Analog read of VIN<br/>\
   Pressure Sensor MS5837<br/>\
   ID0:0<br/>\
";
}
export function sensor_result_string(result_value: number | undefined): string {
  if (result_value === 1) {
    return "OK";
  }
  if (result_value === 0) {
    return "KO";
  }
  return "UNKNOWN";
}

export function sensor_hw_nicely_displayed(
  hw_value: number | undefined | null
): string {
  if (hw_value === undefined || hw_value === null) {
    return "";
  }
  if (hw_value === 3) {
    return "STM32G030-hw2.91-TSYS01(1con)";
  }
  if (hw_value === 4) {
    return "STM32G030-hw4.0/4.1/4.2-TMP117-VIN";
  }
  if (hw_value === 5) {
    return "STM32G071-hw3.0.A/B-TSYS01(all)";
  }
  if (hw_value === 6) {
    return "STM32G071-hw3.1-TSYS01(all)";
  }
  if (hw_value === 7) {
    return "STM32G071-hw4.2-TMP117-VIN";
  }
  if (hw_value === 8) {
    return "STM32G030-hw4.1/4.2-TMP117-VIN-MS5837_PRESSURE";
  }
  if (hw_value === 9) {
    return "STM32G071-hw4.2-TMP117-VIN-PRESSURE";
  }
  if (hw_value === 10) {
    return "STM32G030-hw4.0/4.1/4.2-TMP117-VIN-ILPS28QSW_PRESSURE";
  }
  if (hw_value === 11) {
    return "STM32G071-hw4.0/4.1/4.2-TMP117-VIN-ILPS28QSW_PRESSURE";
  }
  if (hw_value === 12) {
    return "STM32G030-hw4.0/4.1/4.2-TMP117-VIN-MS5837_02BA_PRESSURE";
  }
  if (hw_value === 13) {
    return "STM32G071-hw4.0/4.1/4.2-TMP117-VIN-MS5837_02BA_PRESSURE";
  }
  return "WLvUNKNOWN" + hw_value.toString();
}

export function transmitter_hw_str(transmitter: Transmitter): string {
  /* 2023/09/15 WE WANT TO IDENTIFY EXACTLY WHAT 4.0 WE HAVE */
  const ESP32_first_hw_version = "ESP32-S3-v4.0.1"; //First 5 proto version 4.0
  //const ESP32_first_with_psm_hw_version = "ESP32-S3-v4.0.1+PSM";//First 5 proto version 4.0 with PSM
  const ESP32_second_hw_version = "ESP32-S3-v4.0.2"; //Next 4.0 proto Without PSM
  //const ESP32_second_with_psm_hw_version = "ESP32-S3-v4.0.2+PSM";//Next 4.0 proto With PSM
  const ESP32_third_hw_version = "ESP32-S3-v4.0.3";
  //const ESP32_fourth_hw_version = "ESP32-S3-v4.0.4";
  const known_hw: { [key: string]: string } = {
    /* First 5 protos in 4.0.1 */
    F412FA5153F4: ESP32_first_hw_version,
    F412FA515514: ESP32_first_hw_version,
    F412FA5154EC: ESP32_first_hw_version,
    F412FA515440: ESP32_first_hw_version,
    F412FA515424: ESP32_first_hw_version,
    /* Next 30 protos in 4.0.2 */
    "3485188243D4": ESP32_second_hw_version,
    "34851882440C": ESP32_second_hw_version,
    "3485188243B4": ESP32_second_hw_version,
    "348518824458": ESP32_second_hw_version,
    "34851882443C": ESP32_second_hw_version,
    "3485188244A8": ESP32_second_hw_version,
    "348518824448": ESP32_second_hw_version,
    "3485188244D8": ESP32_second_hw_version,
    "3485188243D0": ESP32_second_hw_version,
    "3485188244F0": ESP32_second_hw_version,
    "348518824480": ESP32_second_hw_version,
    "348518824418": ESP32_second_hw_version,
    "34851882448C": ESP32_second_hw_version,
    "3485188244AC": ESP32_second_hw_version,
    "3485188243C8": ESP32_second_hw_version,
    "348518824494": ESP32_second_hw_version,
    "3485188244A4": ESP32_second_hw_version,
    "34851882442C": ESP32_second_hw_version, //23
    "34851882447C": ESP32_second_hw_version, //24
    "348518824488": ESP32_second_hw_version, //25
    "348518824498": ESP32_second_hw_version, //26
    "3485188244C8": ESP32_second_hw_version, //27
    "348518824454": ESP32_second_hw_version, //28
    "3485188244FC": ESP32_second_hw_version, //29
    "348518824450": ESP32_second_hw_version, //30
    "3485188244E0": ESP32_second_hw_version, //31
    "34851882446C": ESP32_second_hw_version, //32
    "348518824478": ESP32_second_hw_version,
    "3485188243A4": ESP32_second_hw_version,
    "34B7DA68604C": ESP32_second_hw_version,
    C45BBE6D0A46: ESP32_second_hw_version, //NOT SURE about the version
    /* Next 75 in 4.0.3 2024-06-24 */
    "24587CC01588": ESP32_third_hw_version,
    "34B7DA686060": ESP32_third_hw_version,
    "34B7DA686078": ESP32_third_hw_version,
    ECDA3B5EA860: ESP32_third_hw_version,
    "34B7DA720268": ESP32_third_hw_version,
    "348518824468": ESP32_third_hw_version,
    ECDA3B5EA864: ESP32_third_hw_version,
    "34B7DA686094": ESP32_third_hw_version,
    "24587CC01570": ESP32_third_hw_version,
    ECDA3B5EA858: ESP32_third_hw_version,
    "34B7DA686020": ESP32_third_hw_version,
    "34B7DA68603C": ESP32_third_hw_version,
    ECDA3B5EA850: ESP32_third_hw_version,
    "34B7DA6B3094": ESP32_third_hw_version,
    "34B7DA68606C": ESP32_third_hw_version,
    "34B7DA71FD48": ESP32_third_hw_version,
    "34B7DA686098": ESP32_third_hw_version,
    "34B7DA6B3364": ESP32_third_hw_version,
    "34B7DA6C41DC": ESP32_third_hw_version,
    "24587CC0159C": ESP32_third_hw_version,
    "34B7DA6C4314": ESP32_third_hw_version,
    "34B7DA68609C": ESP32_third_hw_version,
    "34B7DA686014": ESP32_third_hw_version,
    "24587CC015B4": ESP32_third_hw_version,
    "34B7DA686068": ESP32_third_hw_version,
    "34B7DA686050": ESP32_third_hw_version,
    "34B7DA6860A0": ESP32_third_hw_version,
    ECDA3B5EA84C: ESP32_third_hw_version,
    "24587CC0074C": ESP32_third_hw_version,
    ECDA3B5EA83C: ESP32_third_hw_version,
    "34B7DA7058B4": ESP32_third_hw_version,
    "34B7DA686064": ESP32_third_hw_version,
    "34B7DA686070": ESP32_third_hw_version,
    "24587CC0156C": ESP32_third_hw_version,
    "34B7DA6B324C": ESP32_third_hw_version,
    "24587CC015B8": ESP32_third_hw_version,
    "24587CC015AC": ESP32_third_hw_version,
    "34B7DA738FD8": ESP32_third_hw_version,
    "24587CC014AC": ESP32_third_hw_version,
    "24587CC01F00": ESP32_third_hw_version,
    "24587CC015D0": ESP32_third_hw_version,
    "34B7DA6EBA34": ESP32_third_hw_version,
    "24587CC015BC": ESP32_third_hw_version,
    "34B7DA6B6550": ESP32_third_hw_version,
    "34B7DA6B3568": ESP32_third_hw_version,
    "24587CC015A0": ESP32_third_hw_version,
    "34B7DA7192E0": ESP32_third_hw_version,
    "34B7DA6B442C": ESP32_third_hw_version,
    "34B7DA686058": ESP32_third_hw_version,
    "24587CC015F4": ESP32_third_hw_version,
    "34B7DA6C3F68": ESP32_third_hw_version,
    "24587CC015C4": ESP32_third_hw_version,
    "34B7DA686088": ESP32_third_hw_version,
    "24587CC01600": ESP32_third_hw_version,
    "34B7DA6AF0A0": ESP32_third_hw_version,
    "24587CC015D4": ESP32_third_hw_version,
    "24587CC015F8": ESP32_third_hw_version,
    "24587CC01590": ESP32_third_hw_version,
    "34B7DA68605C": ESP32_third_hw_version,
    "34B7DA6B31A8": ESP32_third_hw_version,
    "34B7DA6C4190": ESP32_third_hw_version,
    "34B7DA6EE03C": ESP32_third_hw_version,
    "34B7DA727A1C": ESP32_third_hw_version,
    "34B7DA686048": ESP32_third_hw_version,
    "34B7DA686034": ESP32_third_hw_version,
    "24587CC015A4": ESP32_third_hw_version,
    "34B7DA686074": ESP32_third_hw_version,
    "34B7DA686040": ESP32_third_hw_version,
    "34B7DA6B4284": ESP32_third_hw_version, //69
    BCFF4D824E06: ESP32_third_hw_version, //NOT SURE about the version
    "24587CC015CC": ESP32_third_hw_version, //NOT SURE about the version
    "24587CC015C0": ESP32_third_hw_version, //NOT SURE about the version
    "34B7DA686024": ESP32_third_hw_version, //NOT SURE about the version
    "34B7DA6C4200": ESP32_third_hw_version, //NOT SURE about the version
    "24587CC02A84": ESP32_third_hw_version, //NOT SURE about the version
    /* Next 100 in 4.0.4 202409 */
    //"3030F92CC7DC":ESP32_fourth_hw_version,
    //"3030F92BCABC":ESP32_fourth_hw_version
  };
  if (!transmitter || !transmitter.hw_version) {
    return "";
  }
  if (transmitter.mac && transmitter.mac in known_hw) {
    return known_hw[transmitter.mac];
  }
  return transmitter.hw_version + ".4";
}

export function transmitter_hw_str_to_number(transmitter: Transmitter): number {
  if (!transmitter || !transmitter.hw_version) {
    return 0;
  }
  if (transmitter.hw_version === "ATMEGA328-v1") {
    return 1;
  }
  if (transmitter.hw_version === "ATMEGA328-v2") {
    return 2;
  }
  if (transmitter.hw_version === "STM32G030C8T6-v2.91") {
    return 3;
  }
  if (transmitter.hw_version === "STM32G030C8T6-v2.91-4G-GPS") {
    return 4;
  }
  if (transmitter.hw_version === "STM32G071CBT6-v3.0") {
    return 5;
  }
  if (transmitter.hw_version === "STM32G071CBT6-v3.X") {
    return 6;
  }
  if (transmitter.hw_version === "ESP32-S3-v4.0") {
    return 7;
  }
  if (transmitter.hw_version === "ESP32-S3-v4.1") {
    return 8;
  }
  return 9;
}

export function transmitter_hw_number_to_str(hw_version: number): string {
  if (hw_version === 1) {
    return "ATMEGA328-v1";
  }
  if (hw_version === 2) {
    return "ATMEGA328-v2";
  }
  if (hw_version === 3) {
    return "STM32G030C8T6-v2.91";
  }
  if (hw_version === 4) {
    return "STM32G030C8T6-v2.91-4G-GPS";
  }
  if (hw_version === 5) {
    return "STM32G071CBT6-v3.0";
  }
  if (hw_version === 6) {
    return "STM32G071CBT6-v3.X";
  }
  if (hw_version === 7) {
    return "ESP32-S3-v4.0.3"; //production May 2024 (Previous one were all identified)
  }
  if (hw_version === 8) {
    return "ESP32-S3-v4.1";
  }
  return "TUnknown";
}

export function transmitter_hw_is_at_least_esp32(
  transmitter: Transmitter
): boolean {
  if (transmitter_hw_str_to_number(transmitter) > 6) {
    return true;
  }
  return false;
}

export function is_one_waterlevel_is_dirty(
  waterlevels: Array<WaterLevelSensor> | undefined
) {
  if (waterlevels) {
    for (let one_wl of waterlevels) {
      if (
        one_wl.wl_analog_threshold_on_samples_quarter_number > 50 &&
        one_wl.wl_analog_threshold_on < 2500
      ) {
        return true;
      }
    }
  }
  return false;
}

export function is_station_have_no_signal_from_temperature(station: Station) {
  if (station && station.transmitters && station.transmitters.length) {
    for (let one_transmitter of station.transmitters) {
      if (
        one_transmitter.last_message_received &&
        !one_transmitter.last_message_received.includes("random") &&
        !one_transmitter.last_message_received.includes("temperature_1") &&
        !one_transmitter.last_message_received.includes("uuid") &&
        !one_transmitter.last_message_received.includes("versions") &&
        !one_transmitter.last_message_received.includes("gps") &&
        !one_transmitter.last_message_received.includes("cells") &&
        !one_transmitter.last_message_received.includes("operators_around") &&
        !one_transmitter.last_message_received.includes(
          "all_operators_around"
        ) &&
        !one_transmitter.last_message_received.includes("all_wifi_around")
      ) {
        return true;
      }
    }
  }
  return false;
}

export function is_station_have_no_signal_from_capteur(station: Station) {
  const one = station?.transmitters[0]?.defauts?.find(
    (one_defaut) =>
      one_defaut.trigger_type ===
      DEFAUT_TRANSMITTER_TRIGGER_TYPE_MISSING_CAPTEUR
  );
  if (one) {
    return true;
  }
  return false;
}

export function generic_uuid_nicely_displayed(
  uuid_1: number | undefined,
  uuid_2: number | undefined,
  uuid_3: number | undefined
): string {
  if (
    uuid_1 === undefined ||
    uuid_2 === undefined ||
    uuid_3 === undefined ||
    uuid_1 === null ||
    uuid_2 === null ||
    uuid_3 === null
  ) {
    return "";
  }
  return "0x" + uuid_1.toString(16) + uuid_2.toString(16) + uuid_3.toString(16);
}

export function waterlevel_uuid_nicely_displayed(wl: WaterLevelSensor) {
  if (!wl) return "";
  return generic_uuid_nicely_displayed(wl.uuid_1, wl.uuid_2, wl.uuid_3);
}

export function transmitter_uuid_nicely_displayed(transmitter: Transmitter) {
  if (!transmitter) return "";
  return generic_uuid_nicely_displayed(
    transmitter.uuid_1,
    transmitter.uuid_2,
    transmitter.uuid_3
  );
}

export function station_type_nicely_displayed(station: Station): string {
  if (!station) {
    return "Inconnue";
  }
  if (is_station_flow(station)) {
    return "F'LOW";
  }
  if (is_station_thermeau(station)) {
    return "THERM'EAU";
  }
  if (is_station_pap(station)) {
    return "PAP'EYE";
  }
  if (is_station_papang(station)) {
    return "VIG'EEL";
  }
  return "Non Connue";
}

export function transmitter_has_gps_lat_long(transmitter: Transmitter) {
  if (!transmitter) {
    return false;
  }
  if (!transmitter.gps_lat) {
    return false;
  }
  if (!transmitter.gps_lon) {
    return false;
  }
  return true;
}

export function transmitter_get_gps_lat_long(
  transmitter: Transmitter
): LatLngExpression | null {
  if (!transmitter_has_gps_lat_long(transmitter)) {
    return null;
  }
  return [transmitter.gps_lat, transmitter.gps_lon];
}

export function station_get_gps_lat_long(
  station: Station
): LatLngExpression | null {
  if (!station.transmitters) {
    return null;
  }
  const returned_transmitter = station.transmitters.find((one_transmitter) =>
    transmitter_has_gps_lat_long(one_transmitter)
  );
  if (returned_transmitter) {
    return [returned_transmitter.gps_lat, returned_transmitter.gps_lon];
  }
  return null;
}

export function station_get_gps_date(station: Station): Date | null {
  if (!station.transmitters) {
    return null;
  }
  const returned_transmitter = station.transmitters.find((one_transmitter) =>
    transmitter_has_gps_lat_long(one_transmitter)
  );
  if (returned_transmitter) {
    return returned_transmitter.gps_date;
  }
  return null;
}

export function station_get_gps_latitude(station: Station): number | null {
  if (!station.transmitters) {
    return null;
  }
  const returned_transmitter = station.transmitters.find((one_transmitter) =>
    transmitter_has_gps_lat_long(one_transmitter)
  );
  if (returned_transmitter) {
    return returned_transmitter.gps_lat;
  }
  return null;
}

export function station_get_gps_longitude(station: Station): number | null {
  if (!station.transmitters) {
    return null;
  }
  const returned_transmitter = station.transmitters.find((one_transmitter) =>
    transmitter_has_gps_lat_long(one_transmitter)
  );
  if (returned_transmitter) {
    return returned_transmitter.gps_lon;
  }
  return null;
}

export function station_get_min_temperature(station: Station): number | null {
  const temperature_sensors = get_all_typed_analog_sensors_from_station(
    station,
    "temperature"
  );
  if (temperature_sensors?.[0]?.min) {
    return temperature_sensors[0].min;
  }
  return null;
}

export function station_get_max_temperature(station: Station): number | null {
  const temperature_sensors = get_all_typed_analog_sensors_from_station(
    station,
    "temperature"
  );
  if (temperature_sensors?.[0]?.max) {
    return temperature_sensors[0].max;
  }
  return null;
}

export function station_get_mean_temperature(station: Station): number | null {
  const temperature_sensors = get_all_typed_analog_sensors_from_station(
    station,
    "temperature"
  );
  if (temperature_sensors?.[0]) {
    return temperature_sensors[0].mean;
  }
  return null;
}

export function station_get_current_temperature(
  station: Station
): number | null {
  const temperature_sensors = get_all_typed_analog_sensors_from_station(
    station,
    "temperature"
  );
  if (temperature_sensors?.[0]) {
    return temperature_sensors[0].current_value;
  }
  return null;
}

export function station_get_general_status(station: Station): string {
  if (station && station.least_days_since_last_trame_received > 1) {
    return "grey";
  }
  if (station) {
    return station.general_status;
  }
  return "grey";
}

export function station_get_temperature_status(station: Station): string {
  const temperature_sensors = get_all_typed_analog_sensors_from_station(
    station,
    "temperature"
  );
  if (temperature_sensors?.[0]) {
    return temperature_sensors[0].general_status;
  }
  return "grey";
}

export function station_get_waterlevel_status(station: Station): string {
  let waterlevel_sensors = get_all_water_level_sensors_from_station(station);
  if (waterlevel_sensors?.[0]) {
    return waterlevel_sensors[0].general_status;
  }
  return "grey";
}

export function generate_map_link(latitude: number, longitude: number): string {
  return (
    "https://www.google.fr/maps/search/" +
    latitude.toString() +
    "," +
    longitude.toString()
  );
}

export function generate_map_link_for_station(station: Station): string {
  const theLatitude = station_get_gps_latitude(station);
  const theLongitude = station_get_gps_longitude(station);
  if (theLatitude && theLongitude) {
    return (
      "https://www.google.fr/maps/search/" +
      theLatitude.toString() +
      "," +
      theLongitude.toString()
    );
  }
  return "";
}

export function transmitter_get_number_of_quarter_before_next_trame(
  transmitter: Transmitter
) {
  if (!transmitter) {
    return 0;
  }
  /*
   * From Firmware :
   * Battery/send management
   */
  //Only after ESP32
  if (transmitter_hw_str_to_number(transmitter) < 7) {
    return transmitter.current_send_each_n_quarter;
  }
  //Only fw is over 4.0.11
  if (transmitter.fw_version < 40011) {
    return transmitter.current_send_each_n_quarter;
  }
  if (transmitter.fw_version >= 40013) {
    /* Version >= 4.0.13
     * 100%/75% => > send_n_q
     * 74%/60% => modulo send_n_q (send_n_q is minimum 4)
     * 59%/55% => modulo send_n_q (send_n_q is minimum 8)
     * 54%/50% => modulo send_n_q (send_n_q is minimum 16)
     * 49%/40% => twice a day
     * < 40% => once a day
     */
    if (transmitter.current_battery_value >= 75) {
      return transmitter.current_send_each_n_quarter;
    }
    if (transmitter.current_battery_value >= 60) {
      return Math.max(4, transmitter.current_send_each_n_quarter);
    }
    if (transmitter.current_battery_value >= 55) {
      return Math.max(8, transmitter.current_send_each_n_quarter);
    }
    if (transmitter.current_battery_value >= 50) {
      return Math.max(16, transmitter.current_send_each_n_quarter);
    }
    if (transmitter.current_battery_value >= 40) {
      return Math.max(48, transmitter.current_send_each_n_quarter);
    }
    return Math.max(96, transmitter.current_send_each_n_quarter);
  }
  if (transmitter.fw_version >= 40011) {
    /* Version >= 4.0.11
     * 100%/75% => > send_n_q
     * 74%/60% => modulo send_n_q (send_n_q is minimum 4)
     * 59%/40% => twice a day
     * < 40% => once a day
     */
    //modulo is the same actually
    if (transmitter.current_battery_value >= 75) {
      return transmitter.current_send_each_n_quarter;
    }
    if (transmitter.current_battery_value >= 60) {
      return Math.max(4, transmitter.current_send_each_n_quarter);
    }
    if (transmitter.current_battery_value >= 40) {
      return Math.max(48, transmitter.current_send_each_n_quarter);
    }
    return Math.max(96, transmitter.current_send_each_n_quarter);
  }
  return transmitter.current_send_each_n_quarter;
}

export function transmitter_get_battery_strategy_explanation(
  transmitter: Transmitter
): string {
  if (!transmitter) {
    return "";
  }
  /*
   * From Firmware :
   * Battery/send management
   */
  //Only after ESP32
  if (transmitter_hw_str_to_number(transmitter) < 7) {
    return "";
  }
  //Only fw is over 4.0.11
  if (transmitter.fw_version < 40011) {
    return "";
  }
  if (transmitter.fw_version >= 40024) {
    /* Version >= 4.0.24
     * 100%>Battery>75%=>Send whenever/Sample whenever
     * 74%>Battery>60%=>Send 24 times a day max/Sample 24 times a day max
     * 60%>Battery>55%=>Send 12 times a day max/Sample 24 times a day max
     * 55%>Battery>50%=>Send 8 times a day max/Sample 12 times a day max
     * 50%>Battery>45%=>Send 4 times a day max/Sample 12 times a day max
     * 45%>Battery>40%=>Send 2 times a day max/Sample 12 times a day max
     * 40%>Battery>34%=>Send 1 time a day max/Sample 4 times a day max
     * 34%>Battery<20%=>No send(but if ILS)(and try to charge)/Sample 2 times a day max
     * Battery<20%=>No send(and try to charge)/Sample 2 times a day max
     */
    if (transmitter.current_battery_value >= 75) {
      return "";
    }
    if (transmitter.current_battery_value >= 60) {
      return "[74-60%(send/sample 24x/j max)]";
    }
    if (transmitter.current_battery_value >= 55) {
      return "[59-55%(send:12x/j sample:24x/j)]";
    }
    if (transmitter.current_battery_value >= 50) {
      return "[54-50%(send:8x/j sample:12x/j)]";
    }
    if (transmitter.current_battery_value >= 45) {
      return "[49-45%(send:4x/j sample:12x/j)]";
    }
    if (transmitter.current_battery_value >= 40) {
      return "[44-40%(send:2x/j sample:12x/j)]";
    }
    if (transmitter.current_battery_value >= 34) {
      return "[39-34%(send:1x/j sample:12x/j)]";
    }
    if (transmitter.current_battery_value >= 20) {
      return "[33-20%(send:NO sample:2x/j)]";
    }
    return "[<20%(send:NO sample:NO)]";
  }
  if (transmitter.fw_version >= 40013) {
    /* Version >= 4.0.13
     * 100%/75% => > send_n_q
     * 74%/60% => modulo send_n_q (send_n_q is minimum 4)
     * 59%/55% => modulo send_n_q (send_n_q is minimum 8)
     * 54%/50% => modulo send_n_q (send_n_q is minimum 16)
     * 49%/40% => twice a day
     * < 40% => once a day
     */
    if (transmitter.current_battery_value >= 75) {
      return "";
    }
    if (transmitter.current_battery_value >= 60) {
      return "[74-60%(modulo 4)]";
    }
    if (transmitter.current_battery_value >= 55) {
      return "[59-55%(modulo 8)]";
    }
    if (transmitter.current_battery_value >= 50) {
      return "[54-50%(modulo 16)]";
    }
    if (transmitter.current_battery_value >= 40) {
      return "[<50%(2x/j)]";
    }
    return "[<40%(1x/j)]";
  }
  if (transmitter.fw_version >= 40011) {
    /* Version >= 4.0.11
     * 100%/75% => > send_n_q
     * 74%/60% => modulo send_n_q (send_n_q is minimum 4)
     * 59%/40% => twice a day
     * < 40% => once a day
     */
    //modulo is the same actually
    if (transmitter.current_battery_value >= 75) {
      return "";
    }
    if (transmitter.current_battery_value >= 60) {
      return "[74-60%(modulo 4)]";
    }
    if (transmitter.current_battery_value >= 40) {
      return "[59-40%(2x/j)]";
    }
    return "[<40%(1x/j)]";
  }
  return "";
}

export function limit_char(limit: number, text: string): string {
  //TODO limit number depending on width screen
  //console.log(window.innerWidth);
  return text.substring(0, limit);
}

export function get_array_of_stations_id(stations: Station[]): number[] {
  let returned: number[] = [];
  if (stations && stations.length) {
    stations.forEach((one_station) => returned.push(one_station.id));
  }
  return returned;
}

export function get_array_of_transmitters_id(station: Station): number[] {
  let returned: number[] = [];
  if (station && station.transmitters && station.transmitters.length) {
    station.transmitters.forEach((one_transmitter) =>
      returned.push(one_transmitter.id)
    );
  }
  return returned;
}

export function is_stations_number_correct_and_filter_all_the_same_and_which_one(
  stations: Station[]
): string[] {
  const displayName =
    "is_stations_number_correct_and_filter_all_the_same_and_which_one:";
  const enableDebug = false;
  //if stations.length > 0, we got the first time all stations in one_time mode
  let returned: string[] = [];
  if (enableDebug)
    console.log(displayName, "stations.length:", stations.length);
  if (enableDebug) console.log(displayName, "stations:", stations);
  if (stations && stations.length) {
    stations.every((one_station) => {
      if (one_station.filter === undefined || !one_station.filter.length) {
        if (enableDebug)
          console.log(displayName, "one station does not have filter");
        returned = [];
        return;
      }
      if (one_station.filter) {
        if (!returned.length) {
          returned = one_station.filter;
        } else {
          if (JSON.stringify(returned) !== JSON.stringify(one_station.filter)) {
            if (enableDebug)
              console.log(
                displayName,
                "station id:",
                one_station.id,
                " have a different filter"
              );
            returned = [];
            return;
          }
        }
      }
      return;
    });
  }
  return returned;
}

export function replace_light_stations_array_by_full_stations_array(
  light_part_stations: Station[],
  full_part_stations: Station[]
): Station[] {
  const displayName = "replace_light_stations_array_by_full_stations_array:";
  const enableDebug = false;
  if (enableDebug) {
    console.log(
      displayName,
      "light_part_stations.length:",
      light_part_stations.length
    );
    console.log(displayName, "light_part_stations:", light_part_stations);
    console.log(
      displayName,
      "full_part_stations.length:",
      full_part_stations.length
    );
    console.log(displayName, "full_part_stations:", full_part_stations);
  }
  if (light_part_stations.length) {
    light_part_stations.forEach((one_light_station) => {
      const one_full_station = full_part_stations.find(
        (station) => station.id === one_light_station.id
      );
      if (enableDebug) console.log("one_full_station:", one_full_station);
      if (one_full_station === undefined) {
        if (enableDebug)
          console.log(
            displayName,
            "Adding from light stations id:",
            one_light_station.id
          );
        full_part_stations.push(one_light_station);
      }
    });
  }
  if (enableDebug) {
    console.log(
      displayName,
      "OUT full_part_stations.length:",
      full_part_stations.length
    );
    console.log(displayName, "OUT full_part_stations:", full_part_stations);
  }
  return full_part_stations;
}

export function replace_or_add_station_in_stations_array(
  stations: Station[],
  new_or_updated_station: Station
): Station[] {
  const displayName = "replace_station_in_stations_array:";
  const enableDebug = false;
  if (enableDebug) console.log(displayName, "stations:", stations);
  if (enableDebug)
    console.log(displayName, "new_or_updated_station:", new_or_updated_station);
  const existing_station_index = stations.findIndex(
    (station) => station.id === new_or_updated_station.id
  );
  if (enableDebug)
    console.log(displayName, "existing_station_index:", existing_station_index);
  if (existing_station_index < 0) {
    if (enableDebug) console.log(displayName, "Adding the station");
    stations.push(new_or_updated_station);
  } else {
    if (enableDebug) console.log(displayName, "Updating the station");
    stations[existing_station_index] = new_or_updated_station;
  }
  return stations;
}

export function is_station_fully_loaded(station: Station): boolean {
  //when there is no filter value, the station is fully loaded
  if (station && station.filter) {
    return false;
  }
  return true;
}

export function is_station_can_enable_pkd(station: Station): boolean {
  if (station && station.espere_repere && station.espere_repere === "TRF") {
    return true;
  }
  return false;
}

export function station_get_pkd_fault_trigger(
  station: Station
): FaultTrigger | undefined {
  if (station && station.faulttriggers) {
    //return station.faulttriggers.find(one => one.type === "pkd" && one.alarm_days);
    return station.faulttriggers.find((one) => one.type === "pkd");
  }
  return undefined;
}

export function station_get_pkd_fault(station: Station): Fault | undefined {
  if (station && station.faults) {
    //return station.faulttriggers.find(one => one.type === "pkd" && one.alarm_days);
    return station.faults.find((one) => one.type === "pkd");
  }
  return undefined;
}

export function is_station_is_in_pkd_defaut(station: Station): boolean {
  if (station_get_pkd_fault(station) !== undefined) {
    return true;
  }
  return false;
}

export function is_station_have_pkd_enabled(station: Station): boolean {
  return station_get_pkd_fault_trigger(station) !== undefined;
}
