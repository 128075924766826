import { FunctionComponent, useState, useEffect, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import StationCard from "../components/station-card";
import SquamaNavbar from "../components/squama-navbar";
import LoaderLogo from "../img/loader-661x661.gif";

import Station from "../models/station";
import Company from "../models/company";
import { authHeader } from "../helpers/auth-headers";
import {
  get_array_of_stations_id,
  is_stations_number_correct_and_filter_all_the_same_and_which_one,
  replace_light_stations_array_by_full_stations_array,
  return_logo_from_type,
} from "../helpers/station-helper";
import { useWebSocket } from "../services/websocket-service";
import WebsocketSquamaRequest, {
  WebsocketEmitRequest,
} from "../models/websocket";
import { are_those_tables_equals } from "../helpers/tools";
import AuthenticationService from "../services/authentication-service";
import Client from "../models/client";
import ModalCompanySettings from "../components/modals/modal-company-settings";

/*
 * First parameters we get :
 * id : company_id The company id to display
 * finalpath : Final path to reach in company display
 */

const SquamaCompanyDashBoard: FunctionComponent = () => {
  const { socket } = useWebSocket();
  //TODO : handle god / not god user
  const ALL_FILTERS = [
    //["name"],
    [
      "name",
      "analog_sensors",
      "water_level_sensors",
      "type_temperature",
      "type_liquid_level",
      "last_update",
    ],
    //["name", "general_status", "analog_sensors", "water_level_sensors", "type_temperature"],
    [],
  ];
  const displayName = "SquamaCompanyDashBoard:";
  const enableDebug = false;
  const enableMoreDebug = false;
  const enableDebugWebsocket = false;
  const enableMoreDebugWebsocket = false;
  const enableDebugStationsReceived = false;
  const enableMoreDebugStationsReceived = false;
  const current_user_is_god = AuthenticationService.getUserIsGod();
  const current_user_is_nanogis = AuthenticationService.getUserIsAdminNanogis();
  const params = useParams<{ id: string; finalpath: string }>();
  const [updating, setUpdating] = useState<number>(0);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [stationsReceived, setStationsReceived] = useState<Station[]>([]);
  const [stationsDisplayed, setStationsDisplayed] = useState<Station[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0);
  const [clients, setClients] = useState<Client[]>([]);
  const [selectedClientId, setSelectedClientId] = useState<number>(0);
  const [canShowLogout, setCanShowLogout] = useState(false);
  const [finalPath, setFinalPath] = useState<string>("");
  const [websocketEmit, setWebsocketEmit] = useState<
    WebsocketEmitRequest | undefined
  >(undefined);
  const [reload, setReload] = useState(false);

  const history = useNavigate();
  useEffect(() => {
    if (current_user_is_nanogis) {
      history("/map");
    }
  }, [current_user_is_nanogis]);
  useEffect(() => {
    if (params.finalpath) {
      enableDebug && console.log(displayName, "finalpath:", params.finalpath);
      setFinalPath(params.finalpath);
    }
  }, [params.finalpath]);
  useEffect(() => {
    enableMoreDebugWebsocket &&
      console.log(displayName, "useEffect with websocket");
    if (!socket) return;
    socket.on("answer", (answer: any) => {
      enableDebugWebsocket &&
        console.log(displayName, "<---------- Answer:", answer.type);
      if (answer.type === "station_id") {
        //station_id answer list the station_id that the user is allowed to access for the company previously requested
        //we will ask stations_desc by station_id
        current_user_is_god && setUpdating(answer.liste.length);
        const the_request: WebsocketSquamaRequest = {
          type: "stations_desc",
          number: answer.listeNumber,
          liste: answer.liste,
          from: "station_id",
          filter: ALL_FILTERS[0],
          answer_method: ["one_time"],
          token: authHeader(),
        };
        setWebsocketEmit({ channel: "request", request: the_request });
      } else if (answer.type === "error") {
        console.log(displayName, "Websocket error:", answer);
      }
    });
    socket.on("done", (values_desc_str: string) => {
      enableDebugWebsocket &&
        console.log(displayName, "got done:", values_desc_str);
      setReload(true);
    });
    socket.on("companies_id", (the_str_received: string) => {
      enableDebugWebsocket &&
        console.log(displayName, "<---------- companies_id");
      const result = JSON.parse(the_str_received);
      enableMoreDebug &&
        console.log(displayName, "companies_id:Just received:");
      if (result && result[0]) {
        console.log("First company is ", result[0]);
        //setSelectedCompanyId(Number(result[0]));
        //or history to /companies/Number(result[0])
        history("/companies/" + result[0]);
      }
    });
    socket.on("clients_id", (the_str_received: string) => {
      enableDebugWebsocket &&
        console.log(displayName, "<---------- clients_id");
      const result = JSON.parse(the_str_received);
      enableMoreDebug && console.log(displayName, "clients_id:Just received:");
      if (result && result[0]) {
        setSelectedClientId(result[0]);
      }
    });
    socket.on("clients_desc", (the_str_received: string) => {
      enableDebugWebsocket &&
        console.log(displayName, "<---------- clients_desc");
      enableMoreDebug &&
        console.log(
          displayName,
          "clients:Just received answer Json.length:",
          JSON.parse(the_str_received).length
        );
      setClients(JSON.parse(the_str_received));
    });
    socket.on("companies_desc", (the_str_received: string) => {
      enableDebugWebsocket &&
        console.log(displayName, "<---------- companies_desc");
      const the_companies = JSON.parse(the_str_received);
      enableDebug && console.log(displayName, "Companies:", the_companies);
      setCompanies(the_companies);
    });
    socket.on("stations_desc", (the_str_received: string) => {
      enableDebugWebsocket &&
        console.log(displayName, "<---------- stations_desc");
      if (enableMoreDebug)
        console.log(
          displayName,
          "stations_desc:Just received answer Json.length:",
          JSON.parse(the_str_received).length
        );
      setStationsReceived(JSON.parse(the_str_received));
    });
    socket.on("logged_out", (logged_out_desc_str: string) => {
      enableDebugWebsocket &&
        console.log(displayName, "<---------- logged_out");
      console.log(displayName, "logged_out:", logged_out_desc_str);
      setCanShowLogout(true);
      setStationsReceived([]);
      setStationsDisplayed([]);
    });
    //TODO
    //socket.on("disconnect", () => {
    //  enableDebugWebsocket && console.log(displayName, "WS disconnected");
    //  //https://socket.io/how-to/use-with-react
    //});
    if (websocketEmit) {
      enableDebugWebsocket &&
        console.log(
          displayName,
          "----------> websocket :",
          websocketEmit.request.type
        );
      socket.emit(websocketEmit.channel, websocketEmit.request);
    }
    return function cleanup() {
      if (enableMoreDebugWebsocket) {
        console.log(displayName, "!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
        console.log(displayName, "! useEffect websocket.cleanup !");
        console.log(displayName, "!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      }
    };
  }, [socket, params.id, websocketEmit]);

  useEffect(() => {
    if (enableDebug) console.log(displayName, "useEffect main");
    const timer = setTimeout(() => setCanShowLogout(true), 5000);
    //0. no params.id Guess first companies for this client and move to /companies/selectedCompanyId
    if (!params || !Number(params.id)) {
      if (enableDebug)
        console.log(
          displayName,
          "No params, probably in dashboard, =>Request companies_id associated to user"
        );
      const the_request = new WebsocketSquamaRequest(
        "companies_id",
        authHeader()
      );
      setWebsocketEmit({ channel: "request", request: the_request });
    }
    //1.setSelectedCompanyId from params.id (very beginning)
    else if (params.id && !selectedCompanyId) {
      if (enableDebug)
        console.log(
          displayName,
          "Start setSelectedCompanyId to",
          Number(params.id)
        );
      setSelectedCompanyId(Number(params.id));
    }
    //2.We need to know what is the client_id associated with selectedCompanyId and setSelectedClientId
    if (!selectedClientId && selectedCompanyId) {
      if (enableDebug)
        console.log(
          displayName,
          "Request clients_id associated to company_id (params.id)"
        );
      const the_request = new WebsocketSquamaRequest(
        "clients_id",
        authHeader()
      );
      the_request.from = "company_id";
      the_request.liste = [selectedCompanyId];
      the_request.number = 1;
      setWebsocketEmit({ channel: "request", request: the_request });
    }
    //3.Request clients details based on auth (only for god)
    else if (selectedClientId && !clients.length) {
      if (enableDebug) console.log(displayName, "Request all clients_desc");
      const the_request = new WebsocketSquamaRequest(
        "clients_desc",
        authHeader()
      );
      if (current_user_is_god) {
        the_request.filter = "add_first_company_id";
      }
      setWebsocketEmit({ channel: "request", request: the_request });
    }
    //4.(for god we already have clients) Requests all companies associated to the selectedClientId
    else if (clients.length && !companies.length) {
      if (enableDebug) console.log(displayName, "Request all companies_desc");
      const the_request = new WebsocketSquamaRequest(
        "companies_desc",
        authHeader()
      );
      if (current_user_is_god) {
        the_request.from = "client_id";
        the_request.number = 1;
        the_request.liste = [selectedClientId];
      }
      setWebsocketEmit({ channel: "request", request: the_request });
    }
    //5.Request stations_id of selectedCompanyId
    else if (selectedCompanyId && companies.length) {
      if (enableDebug) console.log(displayName, "Request all stations_id");
      const the_request = new WebsocketSquamaRequest(
        "station_id",
        authHeader()
      );
      the_request.filter = "stations_ordered";
      the_request.number = 1;
      the_request.liste = [selectedCompanyId];
      the_request.from = "company_id";
      setStationsReceived([]);
      setStationsDisplayed([]);
      setWebsocketEmit({ channel: "request", request: the_request });
    }
    if (reload) {
      setReload(false);
      setCompanies([]);
      setStationsReceived([]);
      setStationsDisplayed([]);
    }
    return function cleanup() {
      if (enableDebug) console.log(displayName, "useEffect main.cleanup");
      clearTimeout(timer);
    };
  }, [
    params.id,
    companies.length,
    clients.length,
    selectedClientId,
    selectedCompanyId,
    current_user_is_god,
    reload,
  ]);

  function get_next_filter(the_step: string[]): string[] {
    for (let index = 0; index < ALL_FILTERS.length - 1; index++) {
      if (are_those_tables_equals(the_step, ALL_FILTERS[index])) {
        return ALL_FILTERS[index + 1];
      }
    }
    return ALL_FILTERS[ALL_FILTERS.length - 1];
  }

  useEffect(() => {
    if (enableDebugStationsReceived)
      console.log(displayName, "useEffect triggered by stationsReceived");
    if (enableMoreDebugStationsReceived) {
      console.log(displayName, "begin stationsReceived:", stationsReceived);
      console.log(
        displayName,
        "begin stationsReceived.length:",
        stationsReceived.length
      );
      console.log(displayName, "begin stationsDisplayed:", stationsDisplayed);
      console.log(
        displayName,
        "begin stationsDisplayed.length:",
        stationsDisplayed.length
      );
    }
    if (stationsReceived.length) {
      const newStationsDisplayed =
        replace_light_stations_array_by_full_stations_array(
          stationsDisplayed,
          stationsReceived
        );
      setStationsDisplayed(newStationsDisplayed);
      const same_filter =
        is_stations_number_correct_and_filter_all_the_same_and_which_one(
          newStationsDisplayed
        );
      if (same_filter.length) {
        if (enableDebug)
          console.log(
            displayName,
            "*******************************************All same filter:",
            same_filter
          );
        //We can ask next filter steps
        const next_filter = get_next_filter(same_filter);
        if (!are_those_tables_equals(next_filter, same_filter)) {
          if (enableDebug)
            console.log(
              displayName,
              "new request with new filter:",
              next_filter
            );
          const the_request = new WebsocketSquamaRequest();
          the_request.type = "stations_desc";
          the_request.number = newStationsDisplayed.length;
          the_request.liste = get_array_of_stations_id(newStationsDisplayed);
          the_request.from = "station_id";
          the_request.filter = next_filter;
          the_request.token = authHeader();
          setWebsocketEmit({ channel: "request", request: the_request });
        }
      }
    }
    if (enableDebugStationsReceived) {
      console.log(displayName, "end stationsReceived:", stationsReceived);
      console.log(displayName, "end stationsDisplayed:", stationsDisplayed);
    }
  }, [stationsReceived]);

  const clientSelectHandleChange = (event: any) => {
    enableDebug &&
      console.log(displayName, "Selected Client id:", event.target.value);
    const the_client = clients?.find(
      (one) => one.id === Number(event.target.value)
    );
    if (the_client) {
      enableDebug && console.log(displayName, "Selected Client:", the_client);
      history("/companies/" + the_client.first_company_id);
    }
  };

  return (
    <div className="in-page-flex">
      {/* barre*/}
      <SquamaNavbar thepath={window.location.pathname} />
      {current_user_is_god && clients?.length > 1 && (
        <Fragment>
          <hr />
          <div className="row-navbar">
            <select
              key={"select-clients"}
              className="squama-select"
              value={selectedClientId}
              onChange={(e) => clientSelectHandleChange(e)}
            >
              {clients.map((client) => {
                return (
                  <option key={"select-" + client.id} value={client.id}>
                    {client.nickname ? client.nickname : client.name}
                  </option>
                );
              })}
            </select>
          </div>
        </Fragment>
      )}
      {companies?.length > 1 || clients?.length > 1 ? <hr /> : undefined}
      {/* Loader part */}
      <div className="row-navbar">
        {companies ? undefined : (
          <div>
            <img className="loader-logo" src={LoaderLogo} alt="" />
          </div>
        )}
        {(companies?.length > 1 || clients?.length > 1) &&
          companies.map((company) => (
            <div
              key={"div-" + company.id}
              className="squama-item-navbar squama-item-navbar-companies"
            >
              {company.id === selectedCompanyId ? (
                current_user_is_god ? (
                  <div
                    key={"button-" + company.id}
                    className="squama-btn-navbar-companies btn-selected flex-nowrap-justify-between px-2"
                  >
                    <div/>
                    <div>{company.name}</div>
                    <ModalCompanySettings
                      company={company}
                      stations={stationsDisplayed}
                      setWebsocketEmit={setWebsocketEmit}
                    />
                  </div>
                ) : (
                  <button
                    type="button"
                    key={"button-" + company.id}
                    className="squama-btn-navbar-companies btn-selected"
                    disabled={selectedCompanyId === 0}
                  >
                    {company.name}
                  </button>
                )
              ) : (
                <div>
                  <Link to={"/companies/" + company.id}>
                    <button
                      type="button"
                      key={"button-" + company.id}
                      className={
                        company.id === selectedCompanyId
                          ? "squama-btn-navbar-companies btn-selected"
                          : "squama-btn-navbar-companies"
                      }
                      disabled={selectedCompanyId === 0}
                    >
                      {company.name}
                    </button>
                  </Link>
                </div>
              )}
            </div>
          ))}
      </div>
      <hr />
      <div className="main-center-div flex-station-cards the-background">
        {updating || (stationsDisplayed && stationsDisplayed.length) ? (
          stationsDisplayed &&
          stationsDisplayed.map((station) => (
            <StationCard
              key={station.id}
              station={station}
              fullscreen={false}
              finalpath={finalPath}
              mapMode={false}
            />
          ))
        ) : canShowLogout ? (
          <div className="main-center-column-div">
            <div className="flex-center white-font h1-font">
              Merci pour votre visite
            </div>
            <Link to="/accueil">
              <button type="button" className="squama-btn-navbar">
                Accueil
              </button>
            </Link>
          </div>
        ) : undefined}
        {updating && updating > stationsDisplayed.length
          ? [...Array(updating - stationsDisplayed.length)].map(
              (element, index) => (
                <div
                  key={"loader-" + index}
                  className="panel-station-default panel-station-default-not-fullscreen panel-station-body panel-station-simple-row-justify-center"
                >
                  {return_logo_from_type("loader", "icon-sensor-150")}
                </div>
              )
            )
          : undefined}
      </div>
    </div>
  );
};
export default SquamaCompanyDashBoard;
