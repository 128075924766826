import Transmitter from './transmitter';
import Contact from './contact';
import Access from './access';
import FaultTrigger from './fault-trigger';
import Fault from './fault';

export default class Station {
    // 1. Typage des propiétés d'une station.
    id: number;
    name: string;
    type: string;
    company_id: number;
    company_name: string;
    battery: number;
    general_status: string;
    installation_date: Date;
    last_update_date: Date;
    least_days_since_last_trame_received: number;
    transmitters: Array<Transmitter>;
    contacts: Array<Contact>;
    migration_date_start: string;
    migration_date_end: string;
    in_migration_forced: boolean;
    the_blob: string;
    migration_in_progress: boolean;
    accesses: Array<Access>;
    altitude : string;
    surface_bv : string;
    distance_source : string;
    pente_troncon : string;
    duretee_eau : string;
    sm : string;
    largeur_lit : string;
    ntt : string;
    espere_repere : string;
    comments : string;
    filter : Array<string>;
    faulttriggers: Array<FaultTrigger>;
    faults: Array<Fault>;
    latitude: number;
    longitude: number;
    acces_latitude: number;
    acces_longitude: number;
    client_id: number;
    constructor(
        id: number,
        name: string = "",
        type: string = "assec",
        company_id: number = 0,
        company_name: string = "",
        battery: number = 0,
        general_status: string = "green",
        installation_date: Date = new Date(),
        last_update_date: Date = new Date(),
        least_days_since_last_trame_received: number = 0,
        transmitters = [],
        contacts = [],
        migration_date_start: string = "01-01",
        migration_date_end: string = "12-31",
        in_migration_forced: boolean = false,
        the_blob: string = "",
        migration_in_progress: boolean = false,
        accesses = [],
        altitude: string = "",
        surface_bv: string = "",
        distance_source: string = "",
        pente_troncon: string = "",
        duretee_eau: string = "",
        sm: string = "",
        largeur_lit: string = "",
        ntt: string = "",
        espere_repere: string = "",
        comments: string = "",
        filter = [],
        faulttriggers = [],
        faults = [],
        latitude: number = 0,
        longitude: number = 0,
        acces_latitude: number = 0,
        acces_longitude: number = 0,
        client_id: number = 0
    ) {
        // 3. Initialisation des propriétés d'une sonde de niveau d'eau.
        this.id = id;
        this.name = name;
        this.type = type;
        this.company_id = company_id;
        this.company_name = company_name;
        this.battery = battery;
        this.general_status = general_status;
        this.installation_date = installation_date;
        this.last_update_date = last_update_date;
        this.least_days_since_last_trame_received = least_days_since_last_trame_received;
        this.transmitters = transmitters;
        this.contacts = contacts;
        this.migration_date_start = migration_date_start;
        this.migration_date_end = migration_date_end;
        this.in_migration_forced = in_migration_forced;
        this.the_blob = the_blob;
        this.migration_in_progress = migration_in_progress;
        this.accesses = accesses;
        this.altitude = altitude;
        this.surface_bv = surface_bv;
        this.distance_source = distance_source;
        this.pente_troncon = pente_troncon;
        this.duretee_eau = duretee_eau;
        this.sm = sm;
        this.largeur_lit = largeur_lit;
        this.ntt = ntt;
        this.espere_repere = espere_repere;
        this.comments = comments;
        this.filter = filter;
        this.faulttriggers = faulttriggers;
        this.faults = faults;
        this.latitude = latitude;
        this.longitude = longitude;
        this.acces_latitude = acces_latitude;
        this.acces_longitude = acces_longitude;
        this.client_id = client_id;
       }
}
