export default class Defaut {
    // 1. Typage des propiétés d'une sonde de niveau d'eau.
    id: number;
    type: number;
    device_id: number;
    trigger_type: number;
    created: Date;
    date_started: Date;
    // 2. Définition des valeurs par défaut des propriétés d'une sonde de niveau d'eau.
    constructor(
     id: number,
     type: number,
     device_id: number,
     trigger_type: number,
     created: Date,
     date_started: Date,
    ) {
     // 3. Initialisation des propriétés d'une sonde de niveau d'eau.
     this.id = id;
     this.type = type;
     this.device_id = device_id;
     this.trigger_type = trigger_type;
     this.created = created;
     this.date_started = date_started;
    }
}

export const DEFAUT_TRANSMITTER_TRIGGER_TYPE_CAPTEUR_BACK = 7
export const DEFAUT_TRANSMITTER_TRIGGER_TYPE_MISSING_CAPTEUR = 6
export const DEFAUT_TRANSMITTER_TRIGGER_TYPE_BATTERIE_IS_BACK = 5
export const DEFAUT_TRANSMITTER_TRIGGER_TYPE_SEND_IS_BACK = 4
export const DEFAUT_TRANSMITTER_TRIGGER_TYPE_NO_SEND_SINCE_MORE_THAN_ONE_DAY = 3
export const DEFAUT_TRANSMITTER_TRIGGER_TYPE_BATTERIE_FAIBLE_URGENCE = 2
export const DEFAUT_TRANSMITTER_TRIGGER_TYPE_BATTERIE_FAIBLE_VIGILANCE = 1
