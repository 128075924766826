import { Fragment, FunctionComponent, useState } from "react";
import { convert_date_to_UTC, format_date } from "../../helpers/format-date";
import TextTable from "../TextTable";
import Login from "../../models/login";
import AuthenticationService from "../../services/authentication-service";

const ModalShowLoginsTextTable: FunctionComponent = () => {
  const displayName = "ModalShowLoginsTextTable:";
  const enableDebug = false;
  const enableMoreDebug = false;

  const [logins, setLogins] = useState<Login[] | null>([]);
  const [showModal, setshowModal] = useState(false);

  const handleCloseModal = () => setshowModal(false);
  const handleShowModal = () => {
    setLogins(null);
    AuthenticationService.getLogins().then((logins) => {
      setLogins(logins);
    });
    setshowModal(true);
  };

  function the_modal() {
    const TextValueColumns = [
      {
        header: "Logins",
        footer: (props: any) => props.column.id,
        columns: [
          {
            header: "Email",
            accessorKey: "email",
            maxSize: 50,
          },
          {
            header: "Nom",
            accessorKey: "name",
            maxSize: 250,
          },
          {
            header: "user_id",
            accessorKey: "user_id",
            maxSize: 20,
          },
          {
            header: "Date du dernier login",
            accessorKey: "date",
            maxSize: 200,
            accessorFn: (row: any) => convert_date_to_UTC(new Date(row.date)),
            cell: (info: any) => format_date(info.getValue()),
          },
          {
            header: "Où ?",
            accessorKey: "entry",
            maxSize: 50,
          },
          {
            header: "Nombre connexions",
            accessorKey: "count_login",
            maxSize: 50,
          },
        ],
      },
    ];
    return (
      <div className="modal-main">
        <div className="modal-container">
          {/* Start Header */}
          <div className="modal-header">Derniers logins par utilisateur</div>
          {/* End Header */}
          {/* Start Body */}
          <div className="modal-body m-w-500">
            <div className="row">
              <div className="col align-self-center">
                Now(UTC):{format_date(convert_date_to_UTC(new Date()))}
              </div>
              <div className="col align-self-center">
                Now:{format_date(new Date())}
              </div>
            </div>
            {(logins?.length)?(
              <TextTable
                orderBy="date"
                orderByDesc={true}
                allowPagination={true}
                numberOfElementDisplayed={30}
                allValues={logins}
                valuesColumns={TextValueColumns}
              />
            ):undefined}
          </div>
          {/* End Body */}
          {/* Start Footer */}
          <div className="modal-footer">
            <button
              type="button"
              className="squama-btn-navbar squama-btn-gestion-modal"
              onClick={handleShowModal}
            >
              Reload
            </button>
            <button
              type="button"
              className="squama-btn-navbar squama-btn-gestion-modal"
              onClick={handleCloseModal}
            >
              Fermer
            </button>
          </div>
          {/* End Footer */}
        </div>
      </div>
    );
  }
  return (
    <Fragment>
      {showModal && the_modal()}
      <button
        type="button"
        className="squama-btn-navbar squama-btn-gestion"
        onClick={handleShowModal}
      >
        Visualiser les logins
      </button>
    </Fragment>
  );
};

export default ModalShowLoginsTextTable;
