import { FunctionComponent, useState, useEffect } from "react";
import AuthenticationService from "../services/authentication-service";
import Contact from "../models/contact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit, faCheck, faBan } from "@fortawesome/free-solid-svg-icons";
import { UseFormRegisterReturn, useForm, SubmitHandler } from "react-hook-form";
import Bcrypt from "react-native-bcrypt";
import { isBtnSelected } from "../helpers/tools";

type FormItems = {
  name: string;
  phone: string;
  email: string;
  oldPassword: string;
  newPassword: string;
  reNewPassword: string;
};

type ParametersInfoCompteProps = {
  cannotModifyPassword: boolean;
};
const ParametersInfosCompte: FunctionComponent<ParametersInfoCompteProps> = ({
  cannotModifyPassword,
}) => {
  const displayName = "ParametersInfosCompte:";
  const local_debug = false;
  const local_very_debug = false;

  const myUserID = AuthenticationService.getUserID();
  local_very_debug && console.log(displayName, "myUserID:", myUserID);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormItems>();

  const [theContact, setTheContact] = useState<Contact>();
  const [toDisplay, setToDisplay] = useState<string>("notEditable");
  const [passwordError, setPasswordError] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [successPasswordMessage, setSuccessPasswordMessage] =
    useState<string>("");
  useEffect(() => {
    AuthenticationService.getUser(Number(myUserID)).then((tmpTheContact) => {
      if (tmpTheContact) {
        local_debug && console.log(displayName, "contact:", tmpTheContact);
        setTheContact(tmpTheContact);
      }
    });
  }, [myUserID]);
  const onHookFormSubmitErrors = (errors: any) => console.error(errors);
  const onHookFormSubmit = (data: FormItems): void => {
    /*
     * order :
     * X. update user email(NEVER)
     * 1. update user password
     * 2. update datas(telephone, name, etc.)
     */
    local_debug && console.log(displayName, "onHookFormSubmit");
    resetMessages();
    let somethingModified = false;
    if (data.newPassword && data.reNewPassword) {
      //console.log("Asked to update the password");
      if (data.newPassword !== data.reNewPassword) {
        //console.log("New password are not similar");
        setPasswordError("Les nouveaux mots de passe ne sont pas identiques");
      } else {
        //Will use data.email once row will be enabled
        //AuthenticationService.updateUserPassword(data.email, data.oldPassword, Bcrypt.hashSync(data.newPassword))
        somethingModified = true;
        AuthenticationService.updateUserPassword(
          theContact ? theContact.email : "",
          data.oldPassword,
          Bcrypt.hashSync(data.newPassword)
        ).then((wasASuccess) => {
          if (wasASuccess) {
            setSuccessPasswordMessage("Mot de passe modifié.");
            window.setTimeout(() => {
              setToDisplay("notEditable");
            }, 2000);
          } else {
            setPasswordError("Mauvais ancien mot de passe !");
          }
        });
      }
    }
    if (theContact) {
      let updateData = false;
      if (theContact.phone !== data.phone) {
        theContact.phone = data.phone;
        updateData = true;
      }
      if (theContact.name !== data.name) {
        theContact.name = data.name;
        updateData = true;
      }
      if (updateData) {
        //console.log("Ask to update data")
        somethingModified = true;
        AuthenticationService.updateUser(theContact).then((wasASuccess) => {
          if (wasASuccess) {
            setSuccessMessage("Données modifiées.");
            window.setTimeout(() => {
              setToDisplay("notEditable");
            }, 2000);
          }
        });
      }
      if (!somethingModified) {
        setPasswordError("Aucune modification demandée !");
      }
    }
    //console.log(data);
  };

  function resetMessages() {
    setSuccessMessage("");
    setPasswordError("");
    setSuccessPasswordMessage("");
  }

  function createRow(
    item: string,
    item_variable: string,
    item_type: string,
    defaultValue: string | undefined,
    ref_val: UseFormRegisterReturn | undefined,
    is_disabled: boolean,
    errorString: string | undefined
  ) {
    local_very_debug &&
      console.log(
        displayName,
        "createRow item:",
        item,
        " defaultValue:",
        defaultValue
      );
    return (
      <div>
        <div className="panel-parameters-body-row">
          <div className="panel-parameters-body-row-item">{item}</div>
          <div className="panel-parameters-body-row-content">
            <input
              type={item_type}
              className="stay-in-div"
              defaultValue={defaultValue}
              disabled={is_disabled}
              {...ref_val}
            />
          </div>
        </div>
        {errorString && (
          <div className="panel-parameters-body-row text-small-warning-font">
            {errorString}
          </div>
        )}
      </div>
    );
  }

  function showEditableInfosCompte() {
    local_debug && console.log(displayName, "showEditableInfosCompte");
    return (
      <div className="panel-station-body flex-col-center">
        {createRow(
          "Nom :",
          "name",
          "text",
          theContact?.name,
          register("name", { required: true, minLength: 3, maxLength: 20 }),
          false,
          errors.name
            ? "Le nom doit être compris entre 3 et 20 caractères"
            : undefined
        )}
        {createRow(
          "Téléphone :",
          "phone",
          "tel",
          theContact?.phone,
          register("phone", { required: true, minLength: 10, maxLength: 10 }),
          false,
          errors.phone ? "Mauvais format de téléphone" : undefined
        )}
        {createRow(
          "Identifiant :",
          "email",
          "text",
          theContact?.email,
          register("email", {
            required: false,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Mauvais format d'email",
            },
          }),
          true,
          errors.email?.message
        )}
        {createRow(
          "Ancien mot de passe :",
          "oldPassword",
          "password",
          "",
          register("oldPassword", {
            required: true,
            minLength: 5,
            maxLength: 20,
          }),
          false,
          errors.oldPassword
            ? "Mot de passe entre 5 et 20 caractères"
            : undefined
        )}
        {createRow(
          "Nouveau mot de passe :",
          "newPassword",
          "password",
          "",
          register("newPassword", {
            required: true,
            minLength: 8,
            maxLength: 20,
          }),
          false,
          errors.newPassword
            ? "Mot de passe entre 8 et 20 caractères"
            : undefined
        )}
        {createRow(
          "Retaper nouveau Mot de passe :",
          "reNewPassword",
          "password",
          "",
          register("reNewPassword", { required: true }),
          false,
          passwordError
        )}
        {successMessage && (
          <div className="panel-parameters-body-row text-bold-font">
            {successMessage}
          </div>
        )}
        {successPasswordMessage && (
          <div className="panel-parameters-body-row text-bold-font">
            {successPasswordMessage}
          </div>
        )}
        <div className="panel-parameters-body-row">
          <button
            type="submit"
            className={"btn-station-card"}
            onClick={handleSubmit(onHookFormSubmit, onHookFormSubmitErrors)}
          >
            <FontAwesomeIcon icon={faCheck} size="1x" />
            &nbsp;&nbsp;Valider
          </button>
          <button
            type="button"
            className={"btn-station-card"}
            onClick={() => setToDisplay("notEditable")}
          >
            <FontAwesomeIcon icon={faBan} size="1x" />
            &nbsp;&nbsp;Annuler
          </button>
        </div>
      </div>
    );
  }

  function showNotEditableInfosCompte() {
    return (
      <div className="panel-station-body flex-col-center">
        {createRow(
          "Nom :",
          "name",
          "text",
          theContact?.name,
          undefined,
          true,
          undefined
        )}
        {createRow(
          "Téléphone :",
          "phone",
          "tel",
          theContact?.phone,
          undefined,
          true,
          undefined
        )}
        {createRow(
          "Identifiant :",
          "email",
          "text",
          theContact?.email,
          undefined,
          true,
          undefined
        )}
        {createRow(
          "Mot de passe :",
          "oldPassword",
          "password",
          "********",
          undefined,
          true,
          undefined
        )}
      </div>
    );
  }

  function displayCorrectCard() {
    if (toDisplay === "notEditable") {
      return showNotEditableInfosCompte();
    } else {
      return showEditableInfosCompte();
    }
  }

  const showEditable = () => {
    resetMessages();
    if (toDisplay !== "editable") {
      setToDisplay("editable");
    } else {
      setToDisplay("notEditable");
    }
  };

  return (
    <div className="panel-station-default panel-station-default-not-fullscreen">
      <div className="panel-station-heading panel-station-parameters-heading">
        Informations compte
      </div>
      {displayCorrectCard()}
      <div className="panel-station-footer panel-parameters-footer">
        {!cannotModifyPassword ? (
          <button
            type="button"
            className={
              "btn-station-card " + isBtnSelected(toDisplay, "editable")
            }
            onClick={showEditable}
          >
            <FontAwesomeIcon icon={faUserEdit} size="1x" />
            &nbsp;&nbsp;Modifier
          </button>
        ) : undefined}
      </div>
    </div>
  );
};

export default ParametersInfosCompte;
